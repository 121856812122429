import { styled } from '@mui/material'

export const GoogleCalendarStateWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minWidth: '270px',
  gap: '64px',
  div: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    img: {
      width: '24px',
      height: '24px',
    },
    h4: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: '500',
      fontSize: '16px',
      margin: 0,
      [theme.breakpoints.down('md')]: {
        fontSize: '12px',
      },
    },
  },
  [theme.breakpoints.down('md')]: {
    gap: '12px',
    button: {
      fontSize: '12px',
    },
  },
}))
