import { ChatWith } from '../ChatBox'
import {
  ChatHeaderLeft,
  ChatHeaderWrapper,
  ChatPartnerInfo,
} from './chatHeader.style'
import { Avatar, IconButton, useMediaQuery, useTheme } from '@mui/material'
import { stringToColor } from '../../../../lib/utils/stringToColor'
import { ArrowBack, Group } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

export function ChatHeader({
  data: { data, subTitle, type },
  onGroup,
}: {
  data: ChatWith
  onGroup: () => void
}) {
  const navigate = useNavigate()
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <ChatHeaderWrapper>
      <ChatHeaderLeft>
        {isMd && (
          <IconButton onClick={() => navigate('/group-chat')} size="small">
            <ArrowBack />
          </IconButton>
        )}
        {data.avatar ? (
          <Avatar
            src={data.avatar}
            alt={data.title}
            sx={{ width: '50px', height: '50px' }}
          />
        ) : (
          <Avatar
            sx={{
              background: stringToColor(data.title),
              width: '50px',
              height: '50px',
            }}
          >
            {data.title
              .split(' ')
              .map((part) => part[0]?.toUpperCase())
              .slice(0, 2)
              .join('')}
          </Avatar>
        )}
        <ChatPartnerInfo>
          <h4>{data.title}</h4>
          <p>
            <span className="onlineLabel">{subTitle}</span>
          </p>
        </ChatPartnerInfo>
      </ChatHeaderLeft>
      {type === 'group' && (
        <IconButton onClick={onGroup}>
          <Group />
        </IconButton>
      )}
    </ChatHeaderWrapper>
  )
}
