import { Flex, Text } from '@chakra-ui/react'
import { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { useTheme } from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { itemSelect } from '../../../app/store/slices/assessmentSlice'
import { useAppSelector } from '../../../app/store/store.config'
import useToast from '../../../hooks/useToast'
import Toast from '../../../lib/shared/Toast'
import { AssessmentProgress } from '../assessment-progress/AssessmentProgress'
import { AssessmentQuestion } from '../assessment-question/AssessmentQuestion'
import { AssessmentItemWrapper } from '../assessment.style'

const SelectOption: FC<any> = ({ rating, selected, onItemSelected }) => {
  const isItemSelected = selected?.scale === rating
  const theme: any = useTheme()

  return (
    <Flex
      key={rating}
      padding={7}
      flex={1}
      bg={isItemSelected ? theme.palette.primary['400'] : '#eee'}
      cursor="pointer"
      onClick={() => onItemSelected(rating)}
      alignItems="center"
      justifyContent="center"
      w="50px"
      h="50px"
      borderRadius="12px"
    >
      <Text
        color={isItemSelected ? '#fff' : '#000'}
        textAlign="center"
        fontWeight="600"
        fontSize={'xl'}
      >
        {rating}
      </Text>
    </Flex>
  )
}

const NumberRatingQuestion: FC<any> = ({
  handleBack,
  isLoading,
  progress,
  question,
  onSubmit,
}) => {
  const [selected, setSelected] = useState<any>(null)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { toast, showToast, hideToast } = useToast()
  const user = useAppSelector((state) => state.assessment.user)

  const onItemSelected = (rating: any) => {
    setSelected({ scale: rating })
    dispatch(
      itemSelect({
        questionId: question.friendlyID,
        answers: rating,
      }),
    )
  }

  const onLocalSubmit = () => {
    if (!selected) {
      showToast(t('select_option'), 'error')
      return
    }
    onSubmit()
    setSelected(null)
  }

  useEffect(() => {
    setSelected(null)
  }, [])

  return (
    <AssessmentItemWrapper>
      <Toast
        open={toast.open}
        message={toast.message}
        severity={toast.severity}
        onClose={hideToast}
      />
      <AssessmentProgress onBack={handleBack} percent={progress} />
      <AssessmentQuestion
        progress={progress}
        onButtonClick={onLocalSubmit}
        title={question.title.replaceAll('{{username}}', user)}
        isLoading={isLoading}
        buttonText={t('continue')}
      >
        <AnimatePresence key={question.friendlyID}>
          <motion.div
            initial={{ opacity: 0, y: -200 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <Flex gap={5} alignItems="center" justifyItems="center" minH="20vh">
              {Array.from(
                { length: question.maxSelectionLimit },
                (_, i) => i + 1,
              ).map((rating) => (
                <SelectOption
                  key={'rating#' + rating}
                  rating={rating}
                  selected={selected}
                  onItemSelected={onItemSelected}
                />
              ))}
            </Flex>
            <Flex justifyContent="space-between">
              <Text>
                Strongly <br /> Disagree
              </Text>
              <Text>
                Strongly <br /> Agree
              </Text>
            </Flex>
          </motion.div>
        </AnimatePresence>
      </AssessmentQuestion>
    </AssessmentItemWrapper>
  )
}

export default NumberRatingQuestion
