import { FC } from 'react'

import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../app/store/store.config'
import { AssessmentProgress } from '../assessment-progress/AssessmentProgress'
import { AssessmentQuestion } from '../assessment-question/AssessmentQuestion'
import { AssessmentItemWrapper } from '../assessment.style'

const InfoQuestion: FC<any> = ({
  handleBack,
  isLoading,
  progress,
  question,
  onSubmit,
  isLast,
}) => {
  const user = useAppSelector((state) => state.assessment.user)
  const { t } = useTranslation()
  return (
    <AssessmentItemWrapper>
      <AssessmentProgress onBack={handleBack} percent={progress} />
      <AssessmentQuestion
        progress={progress}
        onButtonClick={onSubmit}
        title={question.title.replaceAll('{{username}}', user)}
        subTitle={question.description?.replaceAll('{{username}}', user)}
        isLoading={isLoading}
        isSubtitleQuestion={question.type === 'value-score'}
        buttonText={isLast ? t('finish') : t('continue')}
      >
        <AnimatePresence key={question.friendlyID}>
          <motion.div
            initial={{ opacity: 0, y: -200 }}
            animate={{ opacity: 1, y: 0 }}
          ></motion.div>
        </AnimatePresence>
      </AssessmentQuestion>
    </AssessmentItemWrapper>
  )
}

export default InfoQuestion
