import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { GoogleAuthResult } from '../../../interfaces/entities/auth.entities'

export interface IAuthState {
  user?: any
}

const initialState: IAuthState = {
  user: undefined,
}

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setUser: (store, action: PayloadAction<GoogleAuthResult>) => {
      store.user = action.payload
    },
    resetStore: (store) => {
      Object.assign(store, initialState)
    },
  },
})

export const { resetStore, setUser } = authSlice.actions

export default authSlice
