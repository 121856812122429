import BarChartIcon from '@mui/icons-material/BarChart'
import TableChartIcon from '@mui/icons-material/TableChart'
import {
  Box,
  CircularProgress,
  Divider,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import { useEffect, useMemo, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import {
  useGetLeadershipValueScoresForHRQuery,
  useGetLeadershipValueScoresQuery,
} from '../../app/store/services/assessment.service'
import { useGetAssessmentStatusQuery } from '../../app/store/services/assessment.service'
import { useAuth } from '../../hooks/useAuth'
import { PageTitle } from '../../lib/shared/shared.styles'
import { GroupMemberAssessmentStatus } from '../../lib/widgets/group-members/GroupMembers'
import { SessionStats } from '../dashboard/session-stats/SessionStats'
import { DiagnosticsRequired } from './DiagnosticsRequired'
import { LeadershipValueChart } from './LeadershipValueChart'
import { OpenEndedAnswers } from './OpenEndedAnswers'
import { ScrollDownIndicator } from './ScrollDownIndicator'
import { SelfAnswers } from './SelfAnswers'
import { TeamProgressResults } from './TeamProgressResults'
import { ProgressWrapper } from './progress.style'
import { DashboardCardTitle } from '../../lib/shared/dashboard-card/dashboard-card.style'

export function ProgressPage() {
  const { t } = useTranslation()
  const { user } = useAuth()
  const [view, setView] = useState<'chart' | 'table'>('chart')
  const [showScrollDown, setShowScrollDown] = useState(false)
  const [selectedMember, setSelectedMember] =
    useState<GroupMemberAssessmentStatus | null>(null)
  const isHR = user?.user.isHR
  const resultsRef = useRef<HTMLDivElement>(null)

  const { data: assessmentStatusData, isLoading: isStatusLoading } =
    useGetAssessmentStatusQuery()
  const { data: leadershipValueScores, isLoading: isScoresLoading } =
    useGetLeadershipValueScoresQuery({}, { skip: isHR })
  const { data: hrLeadershipValueScores, isLoading: isHRScoresLoading } =
    useGetLeadershipValueScoresForHRQuery({}, { skip: !isHR })

  const isLoading = isStatusLoading || isScoresLoading || isHRScoresLoading

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY
      const pageHeight =
        document.documentElement.scrollHeight - window.innerHeight
      const scrollPercentage = (scrollPosition / pageHeight) * 100

      if (scrollPercentage >= 20) {
        setShowScrollDown(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (selectedMember && resultsRef.current) {
      resultsRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [selectedMember])

  const assessmentStatus = useMemo(
    () => ({
      selfAssessmentCompleted: assessmentStatusData?.status === 'COMPLETED',
      canShowTeamResults: assessmentStatusData?.canShowTeamResults,
      completedCount: assessmentStatusData?.completedCount ?? 0,
      totalCount: assessmentStatusData?.totalCount ?? 0,
      minRequiredCount: assessmentStatusData?.minRequiredCount ?? 0,
    }),
    [assessmentStatusData],
  )

  const handleViewChange = (
    event: React.MouseEvent<HTMLElement>,
    newView: 'chart' | 'table',
  ) => {
    if (newView !== null) {
      setView(newView)
    }
  }

  const handleViewResults = (member: GroupMemberAssessmentStatus) => {
    setSelectedMember(member)
  }

  const selectedUserData = useMemo(() => {
    console.log(
      'selectedUserData',
      leadershipValueScores,
      hrLeadershipValueScores,
    )
    console.log('selectedMember', selectedMember)
    if (
      !isHR ||
      !selectedMember ||
      !hrLeadershipValueScores ||
      hrLeadershipValueScores.length === 0
    ) {
      return leadershipValueScores
    }
    return hrLeadershipValueScores?.find(
      (item: any) => item?.userEmail === selectedMember.email,
    )?.data
  }, [isHR, selectedMember, leadershipValueScores, hrLeadershipValueScores])

  const renderResults = () => (
    <Stack
      ref={resultsRef}
      sx={{
        filter: 'var(--card-shadow)',
        padding: '22px',
        background: '#ffffff',
        borderRadius: '12px',
        marginBottom: 2,
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={({ breakpoints }) => ({
          [breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 2,
          },
        })}
        mb={3}
      >
        <DashboardCardTitle>
          {t('leadership_diagnostics_results')}
          {isHR && selectedMember && (
            <span
              style={{ color: '#666', marginLeft: '8px', fontSize: '0.9em' }}
            >
              - {selectedMember.name}
            </span>
          )}
        </DashboardCardTitle>
        <ToggleButtonGroup
          value={view}
          exclusive
          onChange={handleViewChange}
          aria-label="view selector"
        >
          <ToggleButton value="chart" aria-label="chart view">
            <BarChartIcon />
            <Typography ml={1}>{t('chart_view')}</Typography>
          </ToggleButton>
          <ToggleButton value="table" aria-label="table view">
            <TableChartIcon />
            <Typography ml={1}>{t('table_view')}</Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {isHR && !selectedMember ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="200px"
        >
          <Typography variant="h5" color="text.secondary">
            {t('please_select_user_first')}
          </Typography>
        </Box>
      ) : (
        <>
          <Box display="flex" justifyContent="center">
            <LeadershipValueChart
              showTable={view === 'table'}
              leadershipValueScores={selectedUserData}
            />
          </Box>
          <Box style={{ fontSize: '14px' }} pt={2} mb={2}>
            <p style={{ marginBottom: '6px' }}>{t('score-breakdown')}</p>
            <ul>
              {[1, 2, 3, 4, 5, 6].map((score) => (
                <li key={score}>
                  {score} -{' '}
                  <i>
                    {leadershipValueScores?.valuesType === 'statements'
                      ? t(`agree-disagree-score-${score}-description`).replace(
                          `${score} -`,
                          '',
                        )
                      : t(`self-score-${score}-description`).replace(
                          `${score} -`,
                          '',
                        )}
                  </i>
                </li>
              ))}
            </ul>
          </Box>
          <Divider />
          <SelfAnswers leadershipValueScores={selectedUserData} />
          <Divider />
          <OpenEndedAnswers leadershipValueScores={selectedUserData} />
        </>
      )}
    </Stack>
  )

  return (
    <ProgressWrapper>
      <PageTitle>{t('progress')}</PageTitle>
      <Box py={2} gap={2} display={'grid'}>
        <SessionStats />
        {showScrollDown && <ScrollDownIndicator />}
      </Box>
      {!isHR && !isLoading && !assessmentStatus.selfAssessmentCompleted && (
        <Stack pb={2}>
          <DiagnosticsRequired />
        </Stack>
      )}
      {isHR && (
        <Stack
          sx={{
            filter: 'var(--card-shadow)',
            padding: '22px',
            background: '#ffffff',
            borderRadius: '12px',
            marginBottom: 2,
          }}
        >
          <TeamProgressResults onViewResults={handleViewResults} />
        </Stack>
      )}

      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="200px"
        >
          <CircularProgress />
        </Box>
      ) : (selectedUserData || (isHR && hrLeadershipValueScores)) &&
        (assessmentStatus.selfAssessmentCompleted || isHR) ? (
        renderResults()
      ) : null}
    </ProgressWrapper>
  )
}
