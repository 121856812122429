/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  ChatsResult,
  useGetChatQuery,
  useGetChatsQuery,
} from '../../store/services/manager.service'
import { ChatUtilsContext } from './ChatUtilsContext'
import { SendMessageData, useEmit } from '../../../hooks/useEmit'
import { useAuth } from '../../../hooks/useAuth'
import { NewChatMessage, useEvent } from '../../../hooks/useEvent'
import { playSound } from '../../../lib/utils/playSound'
import { ChatMessages } from '../../../interfaces/main-interfaces'
import { IMarkAsRead } from '../../../features/chat/chat-box/chat-bubble/ChatBubble'

export function useChatUtilsStore() {
  const { user } = useAuth()
  const [joinedRooms, setJoinedRooms] = useState<string[]>([])
  const [chatsData, setChatsData] = useState<ChatsResult | undefined>(undefined)

  const { id: chatId } = useParams()
  const chatIdRef = useRef(chatId)

  useEffect(() => {
    chatIdRef.current = chatId
  }, [chatId])

  const [currentChat, setCurrentChat] = useState<ChatMessages | undefined>(
    undefined,
  )
  const { data: messages, isFetching: messagesLoading } = useGetChatQuery(
    chatId!,
    {
      refetchOnMountOrArgChange: true,
      skip: !chatId,
    },
  )

  const {
    data: chats,
    isFetching: chatsLoading,
    refetch: refetchChats,
  } = useGetChatsQuery(null, {
    refetchOnMountOrArgChange: true,
  })

  useEffect(() => {
    setChatsData(chats)
  }, [chats])

  useEffect(() => {
    if (messages) {
      setCurrentChat(messages)
    } else {
      setCurrentChat(undefined)
    }
  }, [messages])

  const onNewMessage = useCallback((data: NewChatMessage) => {
    refetchChats()
    playSound()
    if (chatIdRef.current === data.chatId) {
      setCurrentChat((prev) => {
        if (prev) {
          return {
            ...prev,
            data: [...prev.data, data],
          }
        }
      })
    }
  }, [])

  useEvent('new_message', onNewMessage)

  useEvent('seen', (data) => {
    const { totalUnreadMessagesCount, unreadMessagesCount, chatId } = data
    setChatsData((prev) => {
      if (prev) {
        return {
          data: prev.data.map((item) => {
            if (item.chat._id === chatId) {
              return {
                ...item,
                unreadMessagesCount,
              }
            }
            return item
          }),
          unreadMessagesCount: totalUnreadMessagesCount,
        }
      }
    })
  })

  const joinRooms = useEmit('join_chats')
  const sendMessage = useEmit('send_message')
  const sendFile = useEmit('send_file')
  const markAsRead = useEmit('read')

  const handleNewMessage = (data: SendMessageData & { file?: File }) => {
    if (data.file) {
      sendFile({
        ...data,
        file: {
          name: data.file.name,
          type: data.file.type,
          size: data.file.size,
          data: data.file,
        },
      })
    } else if (data.text.length > 0) {
      sendMessage(data)
    }
  }

  useEffect(() => {
    if (chats?.data && chats.data.length > 0) {
      const roomIds = chats.data.map((item) => item.chat._id)
      if (joinedRooms.length !== roomIds.length) {
        joinRooms({
          chats: roomIds,
          userId: user!.user._id,
        })
        setJoinedRooms(roomIds)
      } else {
        const newRooms = roomIds.filter(
          (room) => joinedRooms.findIndex((item) => item !== room) === -1,
        )
        if (newRooms.length > 0) {
          joinRooms({
            chats: newRooms,
            userId: user!.user._id,
          })
          setJoinedRooms([...joinedRooms, ...newRooms])
        }
      }
    }
  }, [chats])

  const handleRead = useCallback(
    (readData: IMarkAsRead) => {
      const { data, isMe, read } = readData
      if (!isMe) {
        const isRead = read.includes(user?.user._id as string)
        if (!isRead) {
          console.log('Sending data')
          markAsRead(data)
        }
      }
    },
    [user],
  )

  return {
    chats: chatsData,
    chatsLoading,
    handleNewMessage,
    markAsRead,
    messagesLoading,
    currentChat,
    handleRead,
  }
}

export type ChatUtilsType = ReturnType<typeof useChatUtilsStore>

export const useChatUtils = (): ChatUtilsType => {
  const chatUtils = useContext(ChatUtilsContext)
  return chatUtils as ChatUtilsType
}
