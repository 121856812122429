import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const OpenEndedAnswers = ({ leadershipValueScores }: any) => {
  const { t } = useTranslation()

  return (
    <Box my={4}>
      <Typography fontWeight="700" fontSize="18px" mb={2}>
        {t('respondent_answers')}
      </Typography>
      {leadershipValueScores && leadershipValueScores.answers.length > 0 ? (
        leadershipValueScores.answers.map((answer: any, index: number) => (
          <Box key={index} mb={6} mt={index === 0 ? 4 : 0}>
            <Typography fontSize="16px" fontWeight={600} fontStyle={'italic'}>
              {answer.question}
            </Typography>
            {answer.descriptionItems?.length > 0 && (
              <Box>
                <ul style={{ paddingLeft: '20px' }}>
                  {answer.descriptionItems.map(
                    (response: any, index: number) => (
                      <li
                        key={'descriptionItem' + index}
                        style={{ fontStyle: 'italic', display: 'list-item' }}
                      >
                        - {response}
                      </li>
                    ),
                  )}
                </ul>
              </Box>
            )}
            {answer.responses.managers &&
              answer.responses.managers.length > 0 && (
                <Box mt={2}>
                  <Typography fontSize="14px" fontWeight="600">
                    {t('manager')}
                  </Typography>
                  <ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
                    {answer.responses.managers.map(
                      (response: any, index: number) => (
                        <li key={index}>{response}</li>
                      ),
                    )}
                  </ul>
                </Box>
              )}

            {answer.responses.peers && answer.responses.peers.length > 0 && (
              <Box mt={2}>
                <Typography fontSize="14px" fontWeight="600">
                  {t('peers')}
                </Typography>
                <ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
                  {answer.responses.peers.map(
                    (response: any, index: number) => (
                      <li key={index}>{response}</li>
                    ),
                  )}
                </ul>
              </Box>
            )}

            {answer.responses.reportees &&
              answer.responses.reportees.length > 0 && (
                <Box mt={2}>
                  <Typography fontSize="14px" fontWeight="600">
                    {t('reportees')}
                  </Typography>
                  <ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
                    {answer.responses.reportees.map(
                      (response: any, index: number) => (
                        <li key={index}>{response}</li>
                      ),
                    )}
                  </ul>
                </Box>
              )}
          </Box>
        ))
      ) : (
        <Typography>{t('no_responses_yet')}</Typography>
      )}
    </Box>
  )
}
