import { styled } from '@mui/material'
import { Link } from 'react-router-dom'

export const HeaderWrapper = styled('header')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '70px',
  background: theme.palette.background.paper,
  zIndex: 2,
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  padding: '24px',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  [theme.breakpoints.down('lg')]: {
    padding: '12px',
    height: '60px',
  },
}))

export const HeaderLeft = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  button: {
    display: 'none',
  },
  [theme.breakpoints.down('lg')]: {
    button: {
      display: 'block',
    },
  },
}))

export const HeaderLogo = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  img: {
    height: '35px',
    [theme.breakpoints.down('md')]: {
      height: '30px',
    },
  },
}))

export const MainLayoutPage = styled('main')(({ theme }) => ({
  padding: '82px 12px 12px 312px',
  [theme.breakpoints.down('lg')]: {
    padding: '82px 12px 12px 12px',
  },
  [theme.breakpoints.down('md')]: {
    padding: '72px 12px 12px 12px',
    '&.chatPage': {
      background: theme.palette.background.paper,
    },
  },
}))
