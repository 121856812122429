import { Box, Stack, Tab, Tabs } from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { HupoHighlights } from './HupoHighlights'

export function NotesPage() {
  const [params, setParams] = useSearchParams(
    new URLSearchParams({
      activeTab: 'hupo-highlights',
    }),
  )

  const activeTab = params.get('activeTab')

  const { t } = useTranslation()
  return (
    <Stack gap="12px">
      {/* <Stack
        sx={(theme) => ({
          borderBottom: `1px solid ${theme.palette.divider}`,
        })}
      >
        <Tabs
          value={activeTab}
          onChange={(_event, value) =>
            setParams(
              new URLSearchParams({
                activeTab: value,
              })
            )
          }
        >
          <Tab value="hupo-highlights" label={t("hupoHighlights")} />
        </Tabs>
      </Stack> */}
      <Box>
        <AnimatePresence key={activeTab}>
          <motion.div
            initial={{
              y: -50,
              opacity: 0,
            }}
            animate={{
              y: 0,
              opacity: 1,
            }}
          >
            <HupoHighlights />
          </motion.div>
        </AnimatePresence>
      </Box>
    </Stack>
  )
}
