import {
  Avatar,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import TimezoneSelect from 'react-timezone-select'
import { toast } from 'sonner'
import { useUpdateProfileMutation } from '../../app/store/services/manager.service'
import { useAuth } from '../../hooks/useAuth'
import { UserProfile } from '../../interfaces/entities/auth.entities'
import { FormField } from '../../lib/shared/FormField'
import { generateTwoLetterName } from '../../lib/utils/generateTwoLetterName'
import { stringToColor } from '../../lib/utils/stringToColor'
import {
  ProfileFormLeft,
  ProfileFormRight,
  ProfileFormWrapper,
} from './profile.style'

export function ProfileTab() {
  const { t } = useTranslation()
  const { user, checkUser } = useAuth()
  const theme = useTheme()
  const [loading, setLoading] = useState(false)
  const [updateProfile] = useUpdateProfileMutation()
  const { control, reset, watch, setValue, handleSubmit } = useForm()

  useEffect(() => {
    if (user) {
      reset({
        email: user.user?.email,
        name: user.user?.profile?.name,
        timezone: user?.user?.timezone,
        title: user?.user?.title,
      })
    }
  }, [reset, user])

  const submitProfile = useCallback(
    async (values: UserProfile) => {
      setLoading(true)
      try {
        const response = await updateProfile(values).unwrap()
        checkUser().then(() => {
          toast.success(t('profileUpdated'))
          setLoading(false)
        })
      } catch (error) {
        setLoading(false)
        toast.error(t('profileUpdateError'))
      }
    },
    [checkUser, t, updateProfile],
  )

  const onSubmit = handleSubmit((values: any) => {
    submitProfile(values)
  })

  return (
    <ProfileFormWrapper>
      <ProfileFormLeft>
        <h4> {t('personalInformation')} </h4>
        <p>{t('personalInformationSubtitle')}</p>
      </ProfileFormLeft>
      <ProfileFormRight onSubmit={onSubmit}>
        <Stack
          sx={(theme) => ({
            background: theme.palette.background.paper,
            borderRadius: '12px',
            [theme.breakpoints.down('sm')]: {
              padding: '12px',
              '.MuiListItem-root': {
                gap: '12px',
              },
              '.MuiAvatar-root,.MuiListItemAvatar-root': {
                width: '40px',
                height: '40px',
                minWidth: 0,
              },
              '.MuiListItemText-primary ': {
                font: '500 12px Poppins, sans-serif',
              },
              '.MuiListItemText-secondary ': {
                font: '400 12px Poppins, sans-serif',
              },
            },
          })}
          gap="24px"
          alignItems="flex-end"
        >
          <ListItem disableGutters disablePadding>
            <ListItemAvatar>
              <Avatar
                sx={{
                  width: '60px',
                  height: '60px',
                  background: stringToColor(user?.user.profile.name || ''),
                }}
                src={user?.user.profile.picture}
              >
                {generateTwoLetterName(user?.user.profile.name || '')}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={user?.user.profile.name}
              secondary={user?.user.email}
            />
          </ListItem>
          <Button disabled variant="soft">
            {t('changeAvatar')}
          </Button>
        </Stack>
        <FormField
          variant="filled"
          name="name"
          control={control}
          label={t('name')}
        />
        <FormField
          variant="filled"
          name="title"
          control={control}
          label={t('title')}
        />
        <Stack gap="3px">
          <Typography fontFamily="Poppins, sans-serif" fontSize="12px">
            {' '}
            {t('timezone')}{' '}
          </Typography>
          <TimezoneSelect
            value={watch('timezone') || ''}
            styles={{
              control: (base, { isFocused }) => ({
                ...base,
                borderRadius: '12px',
                height: '40px',
                outline: 'none !important',
                boxShadow: 'none !important',
                minWidth: 0,
                border: isFocused
                  ? `1px solid ${theme.palette.grey[500]}`
                  : `1px solid ${theme.palette.grey[300]}`,
                ':hover': {
                  border: `1px solid ${theme.palette.grey[500]}`,
                },
              }),
              option: (base, { isSelected, isFocused }) => ({
                ...base,
                font: '400 14px Poppins, sans-serif',
                minWidth: 0,
                background: isSelected
                  ? theme.palette.primary.main
                  : isFocused
                    ? theme.palette.primary.light
                    : 'transparent',
                ':hover': {
                  background: theme.palette.primary.light,
                },
              }),
            }}
            onChange={(timezone: any) => setValue('timezone', timezone.value)}
          />
        </Stack>
        <FormField
          variant="filled"
          name="email"
          control={control}
          label={t('email')}
          disabled
        />
        <Button
          disabled={loading}
          type="submit"
          variant="contained"
          size="large"
        >
          {t('save')}
        </Button>
      </ProfileFormRight>
    </ProfileFormWrapper>
  )
}
