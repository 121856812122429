import { Close } from '@mui/icons-material'
import {
  Badge,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { Search } from 'react-feather'
import { useTranslation } from 'react-i18next'
import Lottie from 'react-lottie'
import { toast } from 'sonner'

import {
  useGetResourceRecommendationsQuery,
  useGetResourcesQuery,
  usePinResourceMutation,
  useUnpinResourceMutation,
} from '../../app/store/services/library.service'
import libraryLoading from '../../assets/lottie/library.json'
import noData from '../../assets/lottie/no-data.json'
import { Resource } from '../../interfaces/main-interfaces'
import { searchFromString } from '../../lib/utils/searchFromString'
import { DashboardWrapper } from '../dashboard/dashboard.style'
import { ResourceCard } from './ResourceCard'
import {
  ResourceLoading,
  ResourcesList,
  SearchWrapper,
  SuggestionsWrapper,
} from './resource.style'
import moment from 'moment'

export function ResourcesLibrary() {
  const { t } = useTranslation()
  const [tabValue, setTabValue] = useState(0)
  const [currentResource, setCurrentResource] = useState<string | null>(null)
  const {
    data: resources,
    refetch: refetchResources,
    isFetching,
  } = useGetResourcesQuery()
  const [resourceList, setResourceList] = useState<Resource[]>([])
  const [pinResource, { isLoading: pinLoading }] = usePinResourceMutation()
  const [unpinResource, { isLoading: unPinLoading }] =
    useUnpinResourceMutation()
  const [search, setSearch] = useState('')

  const onToggle = useCallback(
    async (resourceId: any, isPinned: any) => {
      setCurrentResource(resourceId)
      try {
        if (isPinned) {
          await unpinResource(resourceId).unwrap()
          toast.success(t('resourceUnpinned'))
        } else {
          await pinResource(resourceId).unwrap()
          toast.success(t('resourcePinned'))
        }
        refetchResources()
      } catch (error) {
        console.error('Error toggling resource pin:', error)
      } finally {
        setCurrentResource(null)
      }
    },
    [pinResource, refetchResources, t, unpinResource],
  )

  useEffect(() => {
    setResourceList(() =>
      (resources || []).filter(
        (item) =>
          searchFromString(item.name, search) ||
          searchFromString(item.summary, search) ||
          searchFromString(item.tags.join(' '), search) ||
          searchFromString(item.author, search) ||
          searchFromString(item.type, search),
      ),
    )
  }, [resources, search])

  useEffect(() => {
    if (resources) {
      setResourceList(resources)
    }
  }, [resources])

  const OfflineStatus = ({ offline }: any) => (
    <Typography
      variant="overline"
      sx={{ position: 'absolute', top: 8, right: 8, zIndex: 1 }}
    >
      {offline ? t('included') : t('paid')}
    </Typography>
  )

  if (isFetching && resourceList.length === 0) {
    return (
      <ResourceLoading>
        <Lottie
          options={{
            animationData: libraryLoading,
            loop: true,
            autoplay: true,
          }}
          width={250}
        />
        <h3>{t('loading')}</h3>
      </ResourceLoading>
    )
  }

  function ResourceRecommendations() {
    const {
      data: resources,
      refetch: refetchResources,
      isFetching,
    } = useGetResourceRecommendationsQuery()
    const { t } = useTranslation()

    if (isFetching && !resources) {
      return (
        <ResourceLoading>
          <Lottie
            options={{
              animationData: libraryLoading,
              loop: true,
              autoplay: true,
            }}
            width={250}
          />
          <h3>{t('loading')}</h3>
        </ResourceLoading>
      )
    }

    return (
      <>
        <Typography
          variant="body2"
          sx={{
            textAlign: 'center',
            px: '10px',
            py: '20px',
            backgroundColor: 'background.paper',
            border: '1px solid #e0e0e0',
            borderRadius: '4px',
          }}
        >
          {t('recommendedResourcesDescription')}
        </Typography>
        <SuggestionsWrapper>
          {resources && Object.keys(resources).length >= 0 ? (
            <div className="body">
              {Object.entries(resources).map(
                ([sessionId, recommendation]: any) => {
                  const session = recommendation[0].session
                  const summary = recommendation[0].summary
                  return (
                    <div key={sessionId} className="summaryItem">
                      <div className="summaryHeader">
                        <h3> {session.title} </h3>
                        <p>
                          {moment(session.start).format('D-MMM, h:mm A')} -{' '}
                          {moment(session.end).format('h:mm A')}
                        </p>
                      </div>
                      <div className="summaryBody">
                        {summary &&
                          summary.split('\n').map((part: any, index: any) => (
                            <p
                              key={`${sessionId}_${index}-part`}
                              style={{
                                paddingBottom: '15px',
                                paddingTop: '0px',
                              }}
                            >
                              {part}
                            </p>
                          ))}
                        <ResourcesList>
                          {recommendation.map((item: any) => (
                            <ResourceCard
                              currentResource={currentResource}
                              loading={pinLoading || unPinLoading}
                              data={item.resource}
                              key={item.resource._id}
                              onToggle={onToggle}
                              reason={item.reason}
                              chapters={item.chapters}
                            />
                          ))}
                        </ResourcesList>
                      </div>
                    </div>
                  )
                },
              )}
            </div>
          ) : (
            <Typography
              variant="body2"
              sx={{
                textAlign: 'center',
                px: '10px',
                py: '20px',
                backgroundColor: 'background.paper',
                border: '1px solid #e0e0e0',
                borderRadius: '4px',
              }}
            >
              {t('noRecommendations')}
              <Lottie
                options={{
                  animationData: noData,
                  loop: true,
                  autoplay: true,
                }}
                width={150}
              />
            </Typography>
          )}
        </SuggestionsWrapper>
      </>
    )
  }

  return (
    <DashboardWrapper sx={{ gap: '6px' }}>
      <Stack
        sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.divider}` })}
      >
        <Tabs value={tabValue} onChange={(_event, value) => setTabValue(value)}>
          <Tab label={t('recommended')} />
          <Tab label={t('all_resources')} />
          <Tab
            label={
              <Badge
                badgeContent={resourceList?.filter((r) => r.pinned).length}
                color="primary"
              >
                {t('pinned')}
              </Badge>
            }
          />
        </Tabs>
      </Stack>
      <Stack gap="6px">
        {tabValue === 0 ? (
          <ResourceRecommendations />
        ) : (
          <>
            <SearchWrapper>
              <OutlinedInput
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                placeholder={t('search')}
                startAdornment={
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                }
                endAdornment={
                  search.length > 0 && (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setSearch('')}>
                        <Close />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              />
            </SearchWrapper>
            <ResourcesList>
              {tabValue === 1
                ? structuredClone(resourceList)
                    .sort((a) => (a.pinned ? -1 : 1))
                    .sort((a) => (a.type === 'internal' ? -1 : 1))
                    .map((item) => (
                      <ResourceCard
                        currentResource={currentResource}
                        loading={pinLoading || unPinLoading}
                        data={item}
                        key={item._id}
                        onToggle={onToggle}
                      />
                    ))
                : structuredClone(resourceList)
                    .sort((a) => (a.type === 'internal' ? -1 : 1))
                    ?.filter((r) => r.pinned)
                    .map((item) => (
                      <ResourceCard
                        currentResource={currentResource}
                        loading={pinLoading || unPinLoading}
                        data={item}
                        key={`pinned_${item._id}`}
                        onToggle={onToggle}
                      />
                    ))}
            </ResourcesList>
          </>
        )}
      </Stack>
    </DashboardWrapper>
  )
}
