import { useTranslation } from 'react-i18next'
import { QuestionTitle } from './components/QuestionHeader'
import { Animated } from './components/Animated'
import { ValueScoreList } from './components/question-types.style'
import { QuestionProps } from '../components/PeerAssessmentArea'
import { agreeDisagreeScale } from '../../../utils/ratings'

export function NumberRatingQuestion({
  data,
  user,
  index,
  answers,
  setAnswers,
}: QuestionProps) {
  const { t } = useTranslation()

  const options = agreeDisagreeScale(t)
  return (
    <>
      <QuestionTitle data={data} user={user} />
      <Animated key={data.friendlyID}>
        <ValueScoreList>
          {options.map((item) => (
            <div
              key={item.level}
              className={
                answers.answers[data.friendlyID] === item.scale ? 'active' : ''
              }
              onClick={() =>
                setAnswers({
                  ...answers,
                  answers: {
                    ...answers.answers,
                    [data.friendlyID]: item.scale,
                  },
                })
              }
            >
              {item.level}
            </div>
          ))}
        </ValueScoreList>
      </Animated>
    </>
  )
}
