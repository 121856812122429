import { styled } from '@mui/material'

export const WhatWeDoInfo = styled('div')(({ theme }) => ({
  marginTop: '25px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '15px',
  marginBottom: '60px',
  p: {
    textAlign: 'center',
    fontSize: '18px',
    fontFamily: 'Poppins, sans-serif',
    lineHeight: '32px',
    margin: 0,
    display: 'grid',
    gap: 0,
    fontWeight: '500',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: '400',
    },
  },
}))
