import { Text, Textarea } from '@chakra-ui/react'
import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'

import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { itemSelect } from '../../../app/store/slices/assessmentSlice'
import { useAppSelector } from '../../../app/store/store.config'
import useToast from '../../../hooks/useToast'
import Toast from '../../../lib/shared/Toast'
import { AssessmentProgress } from '../assessment-progress/AssessmentProgress'
import { AssessmentQuestion } from '../assessment-question/AssessmentQuestion'
import { AssessmentItemWrapper } from '../assessment.style'

const FillBlanksQuestion: FC<any> = ({
  handleBack,
  isLoading,
  progress,
  question,
  answer,
  onSubmit,
}) => {
  const dispatch = useDispatch()
  const { toast, showToast, hideToast } = useToast()
  const { t } = useTranslation()
  const [value, setValue] = useState(answer ?? '')
  const user = useAppSelector((state) => state.assessment.user)

  const onLocalSubmit = () => {
    if (value.length > 0) {
      dispatch(
        itemSelect({
          questionId: question.friendlyID,
          answers: value,
        }),
      )
      onSubmit()
    } else {
      showToast(t('please_enter_value_field'), 'error')
    }
  }

  return (
    <AssessmentItemWrapper>
      <Toast
        open={toast.open}
        message={toast.message}
        severity={toast.severity}
        onClose={hideToast}
      />
      <AssessmentProgress onBack={handleBack} percent={progress} />
      <AssessmentQuestion
        progress={progress}
        onButtonClick={onLocalSubmit}
        title={question.title.replaceAll('{{username}}', user)}
        subTitle={question.description?.replaceAll('{{username}}', user)}
        isLoading={isLoading}
        isSubtitleQuestion={question.type === 'value-score'}
        buttonText={t('continue')}
      >
        <AnimatePresence key={question.friendlyID}>
          <motion.div
            initial={{ opacity: 0, y: -200 }}
            animate={{ opacity: 1, y: 0 }}
          >
            {question.prefix ? (
              <Text
                fontWeight="400"
                maxWidth={600}
                lineHeight={1.3}
                fontSize={['md']}
                textAlign="center"
                mb={4}
                mt={4}
              >
                {question.prefix}
              </Text>
            ) : null}
            <Textarea
              value={value}
              onChange={(event) => setValue(event.target.value)}
              placeholder={question.placeholder}
              height="200px"
            />
          </motion.div>
        </AnimatePresence>
      </AssessmentQuestion>
    </AssessmentItemWrapper>
  )
}

export default FillBlanksQuestion
