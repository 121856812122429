import { useEffect } from 'react'
import { Box, Typography, Button, Container } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { useAppSelector } from './app/store/store.config'

export const NotFound: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user?.user)

  useEffect(() => {
    if (import.meta.env.DEV) return

    const email = user?.email
    const date = new Date().toISOString()

    fetch('https://api.hupo.co/v2/error/client', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        uri: window.location.href,
        time: date,
        message: '404 Not found',
        userEmail: email,
      }),
    }).then((res) => res.json())
  }, [user])

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
        }}
      >
        <ErrorOutlineIcon sx={{ fontSize: 100, color: 'error.main', mb: 2 }} />
        <Typography variant="h1" component="h1" gutterBottom>
          404
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Oops! Page not found.
        </Typography>
        <Typography
          variant="body1"
          align="center"
          color="text.secondary"
          paragraph
        >
          The page you're looking for doesn't exist or has been moved.
        </Typography>
        <Button variant="contained" color="primary" href="/" sx={{ mt: 2 }}>
          Go to Homepage
        </Button>
      </Box>
    </Container>
  )
}
