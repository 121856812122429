import moment from 'moment'
import { SuggesstionItemWrapper } from './create-session.style'
import { SuggestedDate } from '../../../../interfaces/main-interfaces'
import { CheckCircle } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

export interface SuggestionItemProps {
  slot: SuggestedDate
  setSelectedSlot: (value: string) => void
  selectedSlot: string
}

export function SuggestionItem({
  slot,
  setSelectedSlot,
  selectedSlot,
}: SuggestionItemProps) {
  const { t } = useTranslation()

  return (
    <SuggesstionItemWrapper
      onClick={() => setSelectedSlot(slot.start)}
      className={`${selectedSlot === slot.start ? 'active' : ''} ${
        slot?.availableMembers ? 'partial' : ''
      }`}
    >
      <p>
        {`${moment(slot.start).format('h:mm a')} - ${moment(slot.end).format(
          'h:mm a',
        )}`}
      </p>
      <span className="hint">
        {slot?.availableMembers
          ? t('availableMembers', {
              available: slot.availableMembers,
              total: slot?.totalMembers,
            })
          : t('everyoneFree')}
      </span>
      {selectedSlot === slot.start && (
        <span className="selected">
          <CheckCircle />
        </span>
      )}
    </SuggesstionItemWrapper>
  )
}
