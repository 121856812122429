import { Delete, Edit } from '@mui/icons-material'
import { Button, DialogContent, IconButton, Tooltip } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import moment from 'moment'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { useDeleteSessionMutation } from '../../../app/store/services/manager.service'
import { CANCELLATION_POLICY_HOURS } from '../../../constants'
import { useAuth } from '../../../hooks/useAuth'
import { Session } from '../../../interfaces/main-interfaces'
import {
  ViewEventContent,
  ViewEventTop,
  ViewEventWrapper,
} from './view-event.style'

export function ViewEvent({
  session,
  onClose,
  onEdit,
}: {
  onClose: () => void
  session: Session
  onEdit: (event: Session) => void
}) {
  const { t } = useTranslation()
  const confirm = useConfirm()
  const { user } = useAuth()
  const [deleteSession, { isLoading }] = useDeleteSessionMutation()
  const navigate = useNavigate()

  const hasBookingCapability = useMemo(() => {
    return user?.user?.isHR || user?.user?.capabilities?.canBook
  }, [user])

  const isLateModifying = useMemo(() => {
    if (!session) return false

    return (
      moment(session.start).diff(moment(), 'hours') < CANCELLATION_POLICY_HOURS
    )
  }, [session])

  const handleCancellation = useCallback(async () => {
    confirm({
      title: t('warning'),
      description: isLateModifying
        ? t('delete_booking_confirmation_late_cancellation')
        : t('bookingCancellationWarning'),
      confirmationText: t('proceed'),
      cancellationText: t('keep'),
    }).then(async () => {
      const response = await deleteSession(session._id).unwrap()

      if (response.ok) {
        toast.success(t('sessionCancelled'))
        onClose()
      }
    })
  }, [confirm, deleteSession, session._id, onClose, t, isLateModifying])

  const handleReschedule = useCallback(() => {
    if (!session) return
    if (isLateModifying) {
      confirm({
        title: t('warning'),
        description: t('reschedule_booking_confirmation_late_cancellation'),
        confirmationText: t('proceed'),
        cancellationText: t('keep'),
      }).then(() => {
        onEdit(session)
        onClose()
      })
    } else {
      onEdit(session)
      onClose()
    }
  }, [onEdit, session, t, confirm, isLateModifying, onClose])

  return (
    <ViewEventWrapper
      open={!!session}
      onClose={() => onClose()}
      PaperProps={{
        sx: (theme) => ({
          minWidth: '500px',
          maxWidth: '550px',
          [theme.breakpoints.down('md')]: {
            minWidth: 300,
          },
        }),
      }}
    >
      <ViewEventTop>
        <div className="eventHeader">
          <h2>{session.title}</h2>
          <p className="eventDate">
            {moment(session.start).format('D MMM, YYYY | h:mm A')} -
            {moment(session.end).format('D MMM, YYYY | h:mm A')}
          </p>
        </div>
        {hasBookingCapability && (
          <div className="actions">
            <Tooltip title={t('reschedule')}>
              <IconButton onClick={handleReschedule}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('cancelSession')}>
              <IconButton disabled={isLoading} onClick={handleCancellation}>
                <Delete />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </ViewEventTop>
      <DialogContent>
        <ViewEventContent>
          <div className="eventContent">
            <div className="eventItem">
              <h4>{t('bookedBy')} </h4>
              <p>
                <span className="itemTitle">
                  {session.bookedBy.profile.name}
                </span>
                <span className="itemSubtitle">{session.bookedBy.email}</span>
              </p>
            </div>
            <div className="eventItem">
              <h4>{t('coach')} </h4>
              <p>
                <span className="itemTitle">{session.coach.profile.name}</span>
                <span className="itemSubtitle">{session.coach.email}</span>
              </p>
            </div>
            <div className="eventItem">
              <h4>{t('attendees')} </h4>
              <div className="attendees">
                {session.participants.map((participant) => (
                  <div key={participant._id}>
                    <h5> {participant.profile.name} </h5>
                    <p>{participant.email}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {session.joinlink && (
            <Button
              size="large"
              onClick={() => window.open(session.joinlink, '_blank')}
              variant="contained"
            >
              {t('join_call')}
            </Button>
          )}
        </ViewEventContent>
      </DialogContent>
    </ViewEventWrapper>
  )
}
