import { styled } from '@mui/material'

export const DashboardCoachesWrapper = styled('div')(() => ({
  display: 'grid',
}))

export const NoCoach = styled('div')(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: '12px',
  filter: 'var(--card-shadow)',
  padding: '24px 12px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '24px',
  h4: {
    font: '400 14px Poppins, sans-serif',
    maxWidth: '210px',
    textAlign: 'center',
  },
}))
