import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Spinner,
  Text,
  Textarea,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Stack,
} from '@chakra-ui/react'
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Logo } from './Logo'
import {
  useGetHupoHighlightsSummaryQuery,
  usePostHupoHighlightsSummaryMutation,
} from '../../app/store/services/coach.service'

const HupoHighlightsEdit: FC<any> = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { id: coachId, sessionId } = useParams<{
    id: string
    sessionId: string
  }>()
  const [formState, setFormState] = useState({
    body: '',
  })
  const {
    isOpen: isPreviewOpen,
    onOpen: onPreviewOpen,
    onClose: onPreviewClose,
  } = useDisclosure()

  const [saving, setSaving] = useState(false)
  const { data, isLoading } = useGetHupoHighlightsSummaryQuery({ sessionId })
  const [postHupoHighlightsSummary] = usePostHupoHighlightsSummaryMutation()

  const handleChange = (e: any) => {
    setFormState({
      body: e.target.value,
    })
  }

  useEffect(() => {
    if (data) {
      setFormState({
        body: data.content,
      })
    }
  }, [data])

  const onSubmit = async () => {
    try {
      setSaving(true)
      await postHupoHighlightsSummary({
        sessionId,
        content: formState.body,
      }).unwrap()

      navigate(`/coach/${coachId}/session-summary/edit-success`)
      setSaving(false)
    } catch (error) {
      console.log('error', error)
    }
  }

  return (
    <Box flexDirection={'column'} textAlign="center" px={6} pb={10}>
      <Flex
        px={{ base: 4, md: 4 }}
        height="20"
        borderBottomWidth="1px"
        alignItems="center"
        justifyContent="center"
      >
        <Logo md={5} />
      </Flex>

      <Flex
        alignItems="center"
        justifyContent="center"
        padding={10}
        direction="column"
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <Heading
              display="inline-block"
              as="h4"
              size="lg"
              textAlign={'center'}
              mb={4}
              px={24}
            >
              {t('hupo-highlights')}
            </Heading>
            <Heading
              display="inline-block"
              as="h4"
              size="md"
              textAlign={'center'}
              mb={4}
              px={24}
            >
              {t('hupo-highlights-edit', { coachee: data?.coacheeName })}
            </Heading>
            <Text textAlign={'center'}>
              {t('hupo-highlights-edit-subtitle')}
            </Text>
            <FormControl mt={5}>
              <Textarea
                value={formState.body}
                placeholder={`Highlights of the session`}
                name="body"
                onChange={handleChange}
                size="md"
                rows={14}
              />
            </FormControl>
            <Button onClick={onPreviewOpen} colorScheme="primary" mt={6}>
              {t('preview')}
            </Button>
          </>
        )}
        <Modal
          isOpen={isPreviewOpen}
          onClose={() => {
            onPreviewClose()
          }}
          size="lg"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{t('preview-summary')}</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Flex
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <TextComponent
                  text={formState.body}
                  highlightedSections={data?.sections}
                />
                <Stack spacing={4} direction="row" mt={6}>
                  <Button
                    onClick={onSubmit}
                    colorScheme="primary"
                    disabled={saving}
                  >
                    {saving
                      ? t('save-and-share-in-progress')
                      : t('save-and-share')}
                  </Button>
                  <Button onClick={onPreviewClose}>
                    {t('continue-editing')}
                  </Button>
                </Stack>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Flex>
    </Box>
  )
}

function TextComponent({ text, highlightedSections }: any) {
  let htmlText = text.replace(/\n/g, '<br />')
  if (highlightedSections) {
    for (const section of highlightedSections) {
      htmlText = htmlText.replace(section, `<strong>${section}</strong>`)
    }
  }
  return <div dangerouslySetInnerHTML={{ __html: htmlText }} />
}

export default HupoHighlightsEdit
