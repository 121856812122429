import { Dialog, styled } from '@mui/material'

export const CoachDialogWrapper = styled(Dialog)(({ theme }) => ({
  '.MuiPaper-root': {
    borderRadius: '12px',
    boxShadow: 'none',
  },
  '.coachDialogContent': {
    width: '500px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      width: 'auto',
    },
    '.coachDialohHeader': {
      height: '60px',
      overflow: 'hidden',
      padding: '12px 16px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: `1px solid ${theme.palette.divider}`,
      [theme.breakpoints.down('md')]: {
        padding: '6px 12px',
        height: '40px',
      },
      h2: {
        font: '700 20px Poppins, sans-serif',
        [theme.breakpoints.down('md')]: {
          font: '500 14px Poppins, sans-serif',
        },
      },
      button: {
        width: '30px',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.palette.primary.light,
        border: 'none',
        borderRadius: '100px',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        [theme.breakpoints.down('md')]: {
          width: '24px',
          height: '24px',
          background: 'transparent',
          color: theme.palette.grey[700],
        },
      },
    },
    '.coachDialogBody': {
      overflowY: 'auto',
      maxHeight: '600px',
      '.coachInfo': {
        padding: '16px',
        display: 'grid',
        gap: '16px',
        alignItems: 'flex-start',
        alignContent: 'flex-start',
      },
      '.infoGroup': {
        display: 'grid',
        gap: '6px',
        h2: {
          font: '600 16px Poppins, sans-serif',
          [theme.breakpoints.down('md')]: {
            font: '600 14px Poppins, sans-serif',
          },
        },
        p: {
          font: '400 14px Poppins, sans-serif',
          whiteSpace: 'pre-wrap',
          pre: {
            whiteSpace: 'pre-wrap',
            font: '400 14px Poppins, sans-serif',
          },
          [theme.breakpoints.down('md')]: {
            font: '400 12px Poppins, sans-serif',
          },
        },
        '.expertises': {
          display: 'flex',
          gap: '12px',
          flexWrap: 'wrap',

          span: {
            display: 'block',
            font: '500 12px Poppins, sans-serif',
            background: theme.palette.primary.light,
            color: theme.palette.primary.main,
            padding: '5px 12px',
            borderRadius: '100px',
            border: '1px solid var(--bold-orange-40)',

            [theme.breakpoints.down('md')]: {
              font: '400 12px Poppins, sans-serif',
              borderRadius: '5px',
            },
          },
        },
      },
    },
    '.coachDialogFooter': {
      padding: '12px 16px',
      display: 'grid',
      borderTop: `1px solid ${theme.palette.grey[300]}`,
      button: {
        borderRadius: '12px',
      },
    },
  },
  '.MuiBackdrop-root': {
    background: 'rgba(0,0,0,.8)',
  },
}))
