import { styled } from '@mui/material'

export const DashboardWrapper = styled('div')(() => ({
  display: 'grid',
  gap: '12px',
}))

export const DashboardContent = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 350px',
  gap: '14px',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr',
  },
}))

export const DashboardLeft = styled('div')(() => ({
  display: 'grid',
  gap: '14px',
  alignContent: 'flex-start',
  alignItems: 'flex-start',
}))

export const DashboardRight = styled('div')(() => ({
  display: 'grid',
  gap: '14px',
  alignContent: 'flex-start',
  alignItems: 'flex-start',
}))
