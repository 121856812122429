import { Stack } from '@chakra-ui/react'
import {
  LeadershipValueItem,
  QuestionHeader,
  QuestionWrapper,
} from '../question-types/components/question-types.style'
import { PeerAssessmentAreaWrapper } from './peer-assessment.style'
import { Link } from '@mui/material'
import { styled } from '@mui/material/styles'
import { PeerAssessment } from '../../../interfaces/main-interfaces'

const LeadershipValuesStack = styled(Stack)(({ theme }) => ({
  width: '600px',
  margin: '16px auto 0',
  gap: '16px',
  '@media (max-width: 600px)': {
    width: '100%',
    padding: '0 24px',
  },
}))

export function LeadershipValues({ data }: { data: PeerAssessment }) {
  return (
    <PeerAssessmentAreaWrapper>
      <QuestionWrapper
        sx={{
          py: '16px',
          maxHeight: '100dvh',
          overflowY: 'auto',
          '@media (max-width: 600px)': {
            maxHeight: 'none',
            overflowY: 'visible',
          },
        }}
      >
        <QuestionHeader>
          <h1>Leadership Values for {data.company.name}</h1>
          {data.leadershipValues.attachmentUrl && (
            <div>
              Click <Link href={data.leadershipValues.attachmentUrl}>here</Link>{' '}
              to see all leadership values.
            </div>
          )}
        </QuestionHeader>
        <LeadershipValuesStack>
          {data.leadershipValues.values.map((item: any) => (
            <LeadershipValueItem key={item._id}>
              <h2
                className={`${
                  item.descriptionHtml ? 'borderFull' : ''
                } lvTitle`}
              >
                {' '}
                {item.label}{' '}
              </h2>
              {item.descriptionHtml && (
                <div
                  className="lvContent"
                  dangerouslySetInnerHTML={{
                    __html: item.descriptionHtml,
                  }}
                />
              )}
            </LeadershipValueItem>
          ))}
        </LeadershipValuesStack>
      </QuestionWrapper>
    </PeerAssessmentAreaWrapper>
  )
}
