/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext } from 'react'
import { ChatContext } from '../app/providers/chat-provider/ChatContext'

export interface SendMessageData {
  chatId: string
  senderId: string
  text: string
}

interface SendFileData extends SendMessageData {
  file: {
    name: string
    size: number
    type: string
    data: File
  }
}

export interface UseEmitMessagesMap {
  join_chats: { chats: string[]; userId: string }
  send_message: SendMessageData
  send_file: SendFileData
  read: { chatId: string; messageId: string; userId: string }
}

export function useEmit<T extends keyof UseEmitMessagesMap>(
  event: T,
): (data: UseEmitMessagesMap[T]) => void {
  const chatStore = useContext(ChatContext)

  const returnCallback = useCallback(
    (data: UseEmitMessagesMap[T]) => {
      if (chatStore?.socket) {
        chatStore.socket.emit(event, data)
      }
    },
    [chatStore?.socket],
  )

  return returnCallback
}
