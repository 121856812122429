import { Avatar, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { generateTwoLetterName } from '../../../../lib/utils/generateTwoLetterName'
import { stringToColor } from '../../../../lib/utils/stringToColor'
import {
  DashboardCoachLeft,
  DashboardCoachWrapper,
} from './dashboard-coach.style'

export interface DashboardCoachProps {
  avatar: string
  name: string
  // type: string;
  isSelected: boolean
  onCoachSelect: () => void
}

export function DashboardCoach({
  avatar,
  name,
  // type,
  isSelected,
  onCoachSelect,
}: DashboardCoachProps) {
  const { t } = useTranslation()
  return (
    <DashboardCoachWrapper>
      <DashboardCoachLeft>
        {avatar ? (
          <Avatar
            sx={{
              width: '80px',
              height: '80px',
            }}
            src={avatar}
            alt={name}
          />
        ) : (
          <Avatar
            sx={{
              width: '80px',
              height: '80px',
              background: stringToColor(name),
            }}
          >
            {generateTwoLetterName(name)}
          </Avatar>
        )}
        <div>
          <h4> {name} </h4>
        </div>
      </DashboardCoachLeft>
      <Button
        variant="outlined"
        disabled={isSelected}
        onClick={() => onCoachSelect()}
        sx={{ whiteSpace: 'nowrap' }}
      >
        {t('viewCoachBio')}
      </Button>
    </DashboardCoachWrapper>
  )
}
