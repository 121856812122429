import { styled } from '@mui/material'

export const OnboardingHeaderContent = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  h1: {
    fontFamily: 'Poppins, sans-serif',
    textAlign: 'center',
    color: 'var(--bold-orange)',
    fontSize: '32px',
    lineHeight: '40px',
    margin: 0,
    fontWeight: '600',
    display: 'grid',
    gap: 0,
  },
  p: {
    fontFamily: 'Poppins, sans-serif',
    textAlign: 'center',
    fontSize: '14px',
    lineHeight: 'normal',
    margin: 0,
    fontWeight: '400',
    display: 'grid',
    gap: 0,
  },
  [theme.breakpoints.down('md')]: {
    h1: {
      fontSize: '22px',
      lineHeight: '30px',
    },
    p: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
}))
