/* eslint-disable no-extra-boolean-cast */
import { Text, useToast } from '@chakra-ui/react'
import { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import { useSaveAssessmentAnswerMutation } from '../../app/store/services/assessment.service'
import {
  clearAssessmentData,
  itemSelect,
  setSkipQuestion,
} from '../../app/store/slices/assessmentSlice'
import { store, useAppSelector } from '../../app/store/store.config'
import { DEFAULT_LANGUAGE } from '../../constants'
import { useAuth } from '../../hooks/useAuth'
import EmailQuestion from './questiontypes/EmailQuestion'
import FeedbackEmailsQuestion from './questiontypes/FeedbackEmailsQuestion'
import FillBlanksQuestion from './questiontypes/FillBlanksQuestion'
import InfoQuestion from './questiontypes/InfoQuestion'
import MultiEmailQuestion from './questiontypes/MultiEmailQuestion'
import NumberRatingQuestion from './questiontypes/NumberRatingQuestion'
import SelectionQuestion from './questiontypes/SelectionQuestion'
import StepQuestion from './questiontypes/StepQuestion'
import TextAreaQuestion from './questiontypes/TextareaQuestion'
import ValueQuestion from './questiontypes/ValueQuestion'

export const PEER_LEADERSHIP_VALUES_TO_FOCUS =
  'peer-leadership-values-user-to-focus'

export const LEADERSHIP_VALUES_IMPROVEMENT_TO_FOCUS_1_QUESTION_ID =
  'improvements-in-leadership-value-1'
export const LEADERSHIP_VALUES_WHAT_WORKS_WELL_1_QUESTION_ID =
  'what-works-well-leadership-value-1'
export const LEADERSHIP_VALUES_IMPROVEMENT_TO_FOCUS_2_QUESTION_ID =
  'improvements-in-leadership-value-2'
export const LEADERSHIP_VALUES_WHAT_WORKS_WELL_2_QUESTION_ID =
  'what-works-well-leadership-value-2'

export const SUGGESTIONS_TO_IMPROVE_LOW_SCORES =
  'suggestions-to-improve-low-scores'

const AssessmentRouter: FC<any> = ({ type }) => {
  const params = useParams() as any
  const index = Number(params.index)
  const navigate = useNavigate()
  const assessmentQuestions = useAppSelector(
    (store) => store.assessment.questions,
  )
  const assessmentAnswers = useAppSelector((store) => store.assessment.answers)
  const valuesType = useAppSelector((store) => store.assessment.valuesType)

  const [saveAssessmentAnswer, { isLoading }] =
    useSaveAssessmentAnswerMutation()
  const question = assessmentQuestions[index]
  const dispatch = useDispatch()
  const toast = useToast()
  const { user } = useAuth()
  const { t } = useTranslation()

  const progress = ((index + 1) / assessmentQuestions.length) * 100
  const currentPath = window.location.pathname
  const lastIndexOfSlash = currentPath.lastIndexOf('/')
  const route = currentPath.slice(1, lastIndexOfSlash)

  useEffect(() => {
    if (question && question.type === 'multiselect-readonly') {
      const initiallySelected = question.options
        .flatMap((o: any) => o.children)
        .map((item: any) => item.value)
      dispatch(
        itemSelect({
          questionId: question.friendlyID,
          answers: initiallySelected,
        }),
      )
    }
  }, [question, dispatch])

  if (!question) {
    return <Text>{t('question_not_found')}</Text>
  }

  const getNextAssessmentQuestionIndex = (index: number, question: any) => {
    let nextIndex = index + 1
    if (question.friendlyID === PEER_LEADERSHIP_VALUES_TO_FOCUS) {
      const values = store.getState().assessment.values
      const leadershipScores = store.getState().assessment.ratings
      const upToDateAssessmentAnswers = store.getState().assessment.answers
      const leadershipValuesToFocus =
        upToDateAssessmentAnswers[PEER_LEADERSHIP_VALUES_TO_FOCUS]
      const orderedValues = values.filter((value: any) =>
        leadershipValuesToFocus.includes(value.value),
      )

      if (orderedValues.length === 1) {
        // skip second value questions
        dispatch(
          setSkipQuestion(LEADERSHIP_VALUES_IMPROVEMENT_TO_FOCUS_2_QUESTION_ID),
        )
        dispatch(
          setSkipQuestion(LEADERSHIP_VALUES_WHAT_WORKS_WELL_2_QUESTION_ID),
        )
        if (leadershipScores[orderedValues[0].value] === 5) {
          dispatch(
            setSkipQuestion(
              LEADERSHIP_VALUES_IMPROVEMENT_TO_FOCUS_1_QUESTION_ID,
            ),
          )
        } else {
          dispatch(
            setSkipQuestion(LEADERSHIP_VALUES_WHAT_WORKS_WELL_1_QUESTION_ID),
          )
        }
      } else if (orderedValues.length === 2) {
        if (leadershipScores[orderedValues[0].value] === 5) {
          dispatch(
            setSkipQuestion(
              LEADERSHIP_VALUES_IMPROVEMENT_TO_FOCUS_1_QUESTION_ID,
            ),
          )
        } else {
          dispatch(
            setSkipQuestion(LEADERSHIP_VALUES_WHAT_WORKS_WELL_1_QUESTION_ID),
          )
        }
        if (leadershipScores[orderedValues[1].value] === 5) {
          dispatch(
            setSkipQuestion(
              LEADERSHIP_VALUES_IMPROVEMENT_TO_FOCUS_2_QUESTION_ID,
            ),
          )
        } else {
          dispatch(
            setSkipQuestion(LEADERSHIP_VALUES_WHAT_WORKS_WELL_2_QUESTION_ID),
          )
        }
      }
    }

    if (question.type === 'value-score') {
      const totalValueScoreQuestions = assessmentQuestions.filter(
        (q) => q.type === 'value-score',
      ).length
      const valuesCount = store.getState().assessment.values.length
      const firstValueScoreQuestionIndex = assessmentQuestions.findIndex(
        (q) => q.type === 'value-score',
      )
      if (nextIndex === valuesCount + firstValueScoreQuestionIndex) {
        for (
          let ratingIdx = nextIndex;
          ratingIdx < nextIndex + totalValueScoreQuestions - valuesCount;
          ratingIdx++
        ) {
          dispatch(setSkipQuestion(assessmentQuestions[ratingIdx].friendlyID))
        }
      }
    }

    while (nextIndex < assessmentQuestions.length - 1) {
      const nextQuestion = assessmentQuestions[nextIndex]
      const skipQuestions = store.getState().assessment.skipQuestions
      if (skipQuestions?.includes(nextQuestion.friendlyID)) {
        nextIndex++
      } else {
        break
      }
    }
    return nextIndex
  }

  const getPreviousAssessmentQuestionIndex = (currentIndex: number) => {
    if (currentIndex === 0) {
      return -1
    }

    let previousIndex = currentIndex - 1
    while (currentIndex > 0) {
      const previousQuestion = assessmentQuestions[previousIndex]
      const skipQuestions = store.getState().assessment.skipQuestions
      if (skipQuestions?.includes(previousQuestion.friendlyID)) {
        previousIndex--
      } else {
        break
      }
    }
    return previousIndex
  }

  const onSubmit = async () => {
    const nextIndex = getNextAssessmentQuestionIndex(index, question)

    if (index < assessmentQuestions.length - 2) {
      try {
        await saveAssessmentAnswer({
          answer: getCurrentAnswer(),
          rating: getCurrentRating(),
          type,
          language: params.language ?? user?.language ?? DEFAULT_LANGUAGE,
          questionIndex: nextIndex,
          isFinal: index === assessmentQuestions.length - 2,
        }).unwrap()
        navigate(`/${route}/${nextIndex}`)
      } catch (err: any) {
        toast({
          status: 'error',
          title: 'Error',
          description: err,
          isClosable: true,
        })
      }
    } else if (index === assessmentQuestions.length - 2) {
      const upToDateAssessmentAnswers = store.getState().assessment.answers
      const ratings = store.getState().assessment.ratings
      console.log(ratings)
      // submit the assessment ...
      try {
        await saveAssessmentAnswer({
          answer: getCurrentAnswer(),
          rating: getCurrentRating(),
          type,
          language: params.language ?? user?.language ?? DEFAULT_LANGUAGE,
          questionIndex: nextIndex,
          isFinal: index === assessmentQuestions.length - 2,
        }).unwrap()

        navigate(`/${route}/${nextIndex}`)
      } catch (err: any) {
        toast({
          status: 'error',
          title: 'Error',
          description: err,
          isClosable: true,
        })
      }
    } else if (index === assessmentQuestions.length - 1) {
      navigate('/')
      dispatch(clearAssessmentData())
    }
  }

  const isLastQuestion = index === assessmentQuestions.length - 1

  const getCurrentAnswer = () => {
    if (question.type !== 'rating' && question.type !== 'value-score') {
      const assessmentAnswers = store.getState().assessment.answers
      return {
        friendlyID: question.friendlyID,
        answer: assessmentAnswers[question.friendlyID],
      }
    }
    return
  }

  const getCurrentRating = () => {
    if (question.type === 'value-score') {
      const assessmentRatings: any = store.getState().assessment.ratings
      const leadershipValue = getCurrentLeadershipValue()?.value
      return {
        focusArea: leadershipValue,
        score: assessmentRatings[leadershipValue],
      }
    }
    return
  }

  const getCurrentLeadershipValue = () => {
    const firstValueScoreQuestionIndex = assessmentQuestions.findIndex(
      (q) => q.type === 'value-score',
    )
    const valueIndex = index - firstValueScoreQuestionIndex
    const leadershipValues = store.getState().assessment.values
    return leadershipValues[valueIndex]
  }

  const handleBack = () => {
    if (index === 0) {
      navigate('/assessment')
    } else {
      const previousIndex = getPreviousAssessmentQuestionIndex(index)
      navigate(`/assessment/${previousIndex}`)
    }
  }

  switch (question.type) {
    case 'number-rating':
      return (
        <NumberRatingQuestion
          handleBack={handleBack}
          isLoading={isLoading}
          progress={progress}
          question={question}
          onSubmit={onSubmit}
        />
      )
    case 'value-score':
      return (
        <ValueQuestion
          handleBack={handleBack}
          isLoading={isLoading}
          progress={progress}
          question={question}
          onSubmit={onSubmit}
          valuesType={valuesType}
          leadershipValue={getCurrentLeadershipValue()}
        />
      )
    case 'multiselect':
    case 'select':
      return (
        <SelectionQuestion
          handleBack={handleBack}
          isLoading={isLoading}
          progress={progress}
          question={question}
          answer={
            !!assessmentAnswers ? assessmentAnswers[question.friendlyID] : []
          }
          onSubmit={onSubmit}
        />
      )
    case 'multiselect-readonly':
      return (
        <SelectionQuestion
          handleBack={handleBack}
          isLoading={isLoading}
          readonly={true}
          progress={progress}
          question={question}
          initiallySelected={question.options
            .flatMap((o: any) => o.children)
            .map((item: any) => item.value)}
          onSubmit={onSubmit}
        />
      )
    case 'list':
      return (
        <SelectionQuestion
          handleBack={handleBack}
          isLoading={isLoading}
          readonly={true}
          progress={progress}
          question={question}
          onSubmit={onSubmit}
          initiallySelected={[]}
          type="list"
        />
      )
    case 'textarea':
      return (
        <TextAreaQuestion
          handleBack={handleBack}
          isLoading={isLoading}
          progress={progress}
          question={question}
          answer={assessmentAnswers[question.friendlyID] || ''}
          isLast={isLastQuestion}
          onSubmit={onSubmit}
        />
      )
    case 'info':
      return (
        <InfoQuestion
          handleBack={handleBack}
          isLoading={isLoading}
          progress={progress}
          question={question}
          isLast={isLastQuestion}
          onSubmit={onSubmit}
        />
      )
    case 'fill-blanks':
      return (
        <FillBlanksQuestion
          handleBack={handleBack}
          isLoading={isLoading}
          progress={progress}
          question={question}
          answer={
            !!assessmentAnswers ? assessmentAnswers[question.friendlyID] : ''
          }
          onSubmit={onSubmit}
        />
      )
    case 'email':
      return (
        <EmailQuestion
          handleBack={handleBack}
          isLoading={isLoading}
          progress={progress}
          question={question}
          answer={
            !!assessmentAnswers ? assessmentAnswers[question.friendlyID] : ''
          }
          onSubmit={onSubmit}
        />
      )
    case 'emails':
      return (
        <MultiEmailQuestion
          handleBack={handleBack}
          isLoading={isLoading}
          progress={progress}
          question={question}
          answer={
            !!assessmentAnswers ? assessmentAnswers[question.friendlyID] : ''
          }
          isLast={isLastQuestion}
          onSubmit={onSubmit}
        />
      )
    case 'feedback-emails':
      return (
        <FeedbackEmailsQuestion
          handleBack={handleBack}
          isLoading={isLoading}
          progress={progress}
          question={question}
          onSubmit={onSubmit}
          // submitting={submitting}
          isLast={isLastQuestion}
          feedbackRequest={user?.company.feedbackRequest}
        />
      )

    case 'step':
      return (
        <StepQuestion
          handleBack={handleBack}
          isLoading={isLoading}
          progress={progress}
          question={question}
          isLast={index === assessmentQuestions.length - 1}
          onSubmit={onSubmit}
        />
      )
    default:
      return <Text>{`Item type: ${question.type} not found`}</Text>
  }
}

export default AssessmentRouter
