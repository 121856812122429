import { CheckCircleOutline } from '@mui/icons-material'
import { Box, LinearProgress, TextField, Typography } from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { itemSelect } from '../../../app/store/slices/assessmentSlice'
import { useAppSelector } from '../../../app/store/store.config'
import useToast from '../../../hooks/useToast'
import Toast from '../../../lib/shared/Toast'
import { getAnswer, hasWords, satisfiesWordCount } from '../../../utils/text'
import {
  LEADERSHIP_VALUES_IMPROVEMENT_TO_FOCUS_1_QUESTION_ID,
  LEADERSHIP_VALUES_IMPROVEMENT_TO_FOCUS_2_QUESTION_ID,
  LEADERSHIP_VALUES_WHAT_WORKS_WELL_1_QUESTION_ID,
  LEADERSHIP_VALUES_WHAT_WORKS_WELL_2_QUESTION_ID,
  PEER_LEADERSHIP_VALUES_TO_FOCUS,
} from '../AssessmentRouter'
import { AssessmentProgress } from '../assessment-progress/AssessmentProgress'
import { AssessmentQuestion } from '../assessment-question/AssessmentQuestion'
import { AssessmentItemWrapper } from '../assessment.style'

const TextAreaQuestion: FC<any> = ({
  handleBack,
  isLoading,
  progress,
  question,
  answer,
  isLast,
  onSubmit,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { toast, showToast, hideToast } = useToast()
  const [value, setValue] = useState(getAnswer(answer) || '')
  const user = useAppSelector((state) => state.assessment.user)
  const upToDateAssessmentAnswers = useAppSelector(
    (state) => state.assessment.answers,
  )
  const values = useAppSelector((state) => state.assessment.values)

  let questionTitle = question.title.replaceAll('{{username}}', user)
  let leadershipValue: any = null
  if (
    question.friendlyID ===
      LEADERSHIP_VALUES_IMPROVEMENT_TO_FOCUS_1_QUESTION_ID ||
    question.friendlyID === LEADERSHIP_VALUES_WHAT_WORKS_WELL_1_QUESTION_ID
  ) {
    const leadershipValuesToFocus =
      upToDateAssessmentAnswers[PEER_LEADERSHIP_VALUES_TO_FOCUS]
    const orderedValues = values.filter((value: any) =>
      leadershipValuesToFocus.includes(value.value),
    )
    if (leadershipValuesToFocus && leadershipValuesToFocus.length > 0) {
      questionTitle = questionTitle.replaceAll(
        '{{leadershipvalue}}',
        orderedValues[0].label,
      )
      leadershipValue = orderedValues[0]
    }
  }
  if (
    question.friendlyID ===
      LEADERSHIP_VALUES_IMPROVEMENT_TO_FOCUS_2_QUESTION_ID ||
    question.friendlyID === LEADERSHIP_VALUES_WHAT_WORKS_WELL_2_QUESTION_ID
  ) {
    const leadershipValuesToFocus =
      upToDateAssessmentAnswers[PEER_LEADERSHIP_VALUES_TO_FOCUS]
    const orderedValues = values.filter((value) =>
      leadershipValuesToFocus.includes(value.value),
    )
    if (leadershipValuesToFocus.length > 1) {
      questionTitle = questionTitle.replaceAll(
        '{{leadershipvalue}}',
        orderedValues[1].label,
      )
      leadershipValue = orderedValues[1]
    }
  }
  const questionDescription = question.description?.replaceAll(
    '{{username}}',
    user,
  )

  useEffect(() => {
    setValue(getAnswer(answer) || '')
  }, [answer])

  const isValid = (): string | null => {
    console.log('Checking validity', question, value)
    if (
      !question?.optional &&
      question?.type === 'textarea' &&
      question?.minWordLimit &&
      !satisfiesWordCount(value, question.minWordLimit)
    ) {
      return t('please_enter_at_least_words', {
        wordCount: question.minWordLimit,
      })
    }

    if (
      !question?.optional &&
      question?.type === 'textarea' &&
      (!value || !hasWords(value))
    ) {
      return t('please_enter_value_field')
    }

    return null
  }

  const onLocalSubmit = () => {
    const errorMessage = isValid()
    console.log('Error:', errorMessage)
    if (errorMessage) {
      console.log('Error:', errorMessage)
      showToast(errorMessage, 'error')
      return
    }

    if (value.length > 0 || question.optional) {
      if (leadershipValue) {
        dispatch(
          itemSelect({
            questionId: question.friendlyID,
            answers: [leadershipValue.value],
            otherAnswer: value,
          }),
        )
      } else {
        dispatch(
          itemSelect({
            questionId: question.friendlyID,
            answers: value,
          }),
        )
      }
      setValue('')
      onSubmit()
    } else {
      showToast(t('please_enter_value_field'), 'error')
    }
  }

  const minWordLimit = question.minWordLimit || 0
  const wordCount = value
    ? value
        .trim()
        .split(/\s+/)
        .filter((word: any) => word.length > 0).length
    : 0
  const isMinWordCountMet = wordCount >= minWordLimit
  const wordCountProgress = Math.min((wordCount / minWordLimit) * 100, 100)

  return (
    <AssessmentItemWrapper>
      <Toast
        open={toast.open}
        message={toast.message}
        severity={toast.severity}
        onClose={hideToast}
      />
      <AssessmentProgress onBack={handleBack} percent={progress} />
      <AnimatePresence key={question.friendlyID}>
        <AssessmentQuestion
          progress={progress}
          onButtonClick={onLocalSubmit}
          title={questionTitle}
          subTitle={questionDescription}
          isLoading={isLoading}
          buttonText={isLast ? t('finish') : t('continue')}
        >
          <AnimatePresence key={question.friendlyID}>
            <motion.div
              initial={{ opacity: 0, y: -200 }}
              animate={{ opacity: 1, y: 0 }}
            >
              <TextField
                placeholder={question.placeholder ?? 'Your answer here'}
                multiline
                autoFocus
                minRows={7}
                variant="filled"
                style={{
                  width: '100%',
                }}
                value={value}
                onChange={(event) => setValue(event.target.value)}
                InputProps={{
                  disableUnderline: true,
                  inputProps: {
                    style: {
                      padding: '0 12px',
                    },
                  },
                }}
              />
              <Box sx={{ mt: 2, mb: 1 }}>
                <Typography
                  variant="caption"
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: 0.5,
                  }}
                >
                  <span>{t('current_words', { count: wordCount })}</span>
                  <span>{t('min_words', { count: minWordLimit })}</span>
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={wordCountProgress}
                  sx={{
                    height: 8,
                    borderRadius: 4,
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    '& .MuiLinearProgress-bar': {
                      borderRadius: 4,
                      backgroundColor: isMinWordCountMet
                        ? '#4caf50'
                        : '#2196f3',
                    },
                  }}
                />
              </Box>
            </motion.div>
          </AnimatePresence>
        </AssessmentQuestion>
      </AnimatePresence>
    </AssessmentItemWrapper>
  )
}

export default TextAreaQuestion
