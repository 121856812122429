import { ChakraProvider } from '@chakra-ui/react'

import { MyCalendar } from './MyCalendar'
import { useParams } from 'react-router-dom'
import { SelectCalendar } from './SelectCalendar'
import { chakraTheme } from '../../../lib/styles/chakraTheme'

export const CalendarConnection = () => {
  const params = useParams()
  return (
    <ChakraProvider theme={chakraTheme}>
      {params.tabId === 'calendars' ? <MyCalendar /> : <SelectCalendar />}
    </ChakraProvider>
  )
}
