import { Box, Typography } from '@mui/material'
import Lottie from 'react-lottie'

import arrowDown from '../../assets/lottie/arrow-down.json'
import { useEffect, useState } from 'react'

export function ScrollDownIndicator() {
  const [isVisible, setIsVisible] = useState(true)

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: arrowDown,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const handleClick = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    })
    setIsVisible(false)
  }

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY
      const pageHeight =
        document.documentElement.scrollHeight - window.innerHeight
      const scrollPercentage = (scrollPosition / pageHeight) * 100

      if (scrollPercentage >= 20) {
        setIsVisible(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  if (!isVisible) {
    return null
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        top: '30%',
        right: '20px',
        zIndex: 9999,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        backgroundColor: '#F7FAFC',
        borderRadius: '8px',
        padding: '12px',
      }}
      onClick={handleClick}
    >
      <Lottie options={defaultOptions} height={60} width={60} />
      <Typography
        variant="body1"
        align="center"
        sx={{ color: '#ff480b' }}
        mt={0}
      >
        Scroll down to see more
      </Typography>
    </Box>
  )
}
