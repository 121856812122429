import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Animated } from './Animated'
import { QuestionHeader } from './question-types.style'
import { Box, Link, Stack } from '@mui/material'
import {
  PeerAssessmentQuestion,
  PeerAssessmentUser,
} from '../../../../interfaces/main-interfaces'
export function QuestionTitle({
  data,
  user,
  leadershipValue,
  highlightDescription,
}: {
  data: PeerAssessmentQuestion
  user: PeerAssessmentUser
  leadershipValue?: string
  highlightDescription?: boolean
}) {
  const [showDescription, setShowDescription] = useState(false)
  const { t } = useTranslation()

  let title = data.title?.replace(/{{username}}/gi, user.name)
  if (leadershipValue) {
    title = title?.replace(
      /{{leadershipValue}}/gi,
      '<br/><i>' + leadershipValue + '</i>',
    )
  }
  return (
    <Animated key={data._id}>
      <QuestionHeader>
        <h1>
          <div
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        </h1>
        <Box style={{ marginTop: 2 }}>
          <div
            style={
              highlightDescription
                ? { fontStyle: 'italic', fontSize: 'larger' }
                : {}
            }
          >
            {data.description
              ?.replace(/{{username}}/gi, user.name)
              .split('\\n')
              .map((part, index) => {
                if (part.startsWith('**') && part.endsWith('**')) {
                  return (
                    <b key={`${data._id}_${index}_desc`}>
                      <span>{part.replaceAll('**', '')}</span>
                      <br />
                    </b>
                  )
                }
                return (
                  <div>
                    <span key={`${data._id}_${index}_desc`}> {part} </span>
                    <br />
                  </div>
                )
              })}
          </div>
          {data.info && (
            <div style={{ marginTop: '8px' }}>
              <Link
                onClick={() => setShowDescription(!showDescription)}
                style={{ cursor: 'pointer' }}
              >
                <div
                  style={{
                    textDecoration: 'underline',
                    textAlign: 'center',
                  }}
                >
                  {showDescription
                    ? t('hide_description')
                    : t('show_description')}
                  {showDescription ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </div>
              </Link>
            </div>
          )}
        </Box>
        {data.info && (
          <div>
            {showDescription ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: data.info,
                }}
              />
            ) : (
              <div></div>
            )}
          </div>
        )}
      </QuestionHeader>
    </Animated>
  )
}
