import { styled } from '@mui/material'

export const ChatListHeaderWrapper = styled('div')(({ theme }) => ({
  height: '70px',
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 16px',
  h4: {
    font: '700 24px Poppins, sans-serif',
  },
  span: {
    font: '500 12px Poppins, sans-serif',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '3px 8px',
    borderRadius: '100px',
  },
  [theme.breakpoints.down('md')]: {
    height: 'auto',
    border: 'none',
    padding: '0 0 12px 0',
    h4: {
      font: '600 20px Poppins, sans-serif',
    },
  },
}))
