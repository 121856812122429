import { ButtonBase, styled } from '@mui/material'

export const AccountButtonWrapper = styled(ButtonBase)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: theme.palette.grey[600],
  gap: '6px',
  padding: '4px 4px 4px 6px',
  borderRadius: '12px',
  '& > .profile-info': {
    display: 'flex',
    alignItems: 'center',
  },
  '& > .profile-info .profile-icon': {
    padding: '8px',
  },
  '& > .profile-info .info': {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexDirection: 'column',
    p: {
      font: '500 13px Poppins, sans-serif',
      lineHeight: '16px',
      margin: 0,
      color: theme.palette.grey[800],
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'left',
      width: '130px',
    },
    span: {
      font: '400 12px Poppins, sans-serif',
      lineHeight: '16px',
      margin: 0,
      color: theme.palette.grey[500],
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '150px',
    },
  },
  [theme.breakpoints.down('md')]: {
    width: 'auto',
  },
}))
