import { Box, Button, Heading, HStack, Icon, Text } from '@chakra-ui/react'
import { FcGoogle } from 'react-icons/fc'

import { Card } from './Card'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../app/store/store.config'
import { syncGoogleCalendar } from '../../../lib/utils/syncGoogleCalendar'
import GoogleCalendar from '../../../assets/icons/google-calendar.svg'

export const SelectCalendar = () => {
  const secondaryTextColor = '#999'
  const { t } = useTranslation()
  const user = useAppSelector((store) => store.auth.user)

  return (
    <Box>
      <Card>
        <Text px="4" fontSize={18}>
          {t('select_calendar')}
        </Text>
        <Text px="4" fontSize={15} color={secondaryTextColor}>
          {t('connect_calendar_description')}
        </Text>

        <HStack
          mt="6"
          mb="3"
          px="2"
          py="3"
          backgroundColor="#eee"
          border={'1px solid #eee'}
        >
          <Icon as={FcGoogle} fontSize={24} />
          <Text as="b">Google</Text>
        </HStack>

        <Item
          name="Google Calendar"
          desc="Gmail, G Suite"
          onConnect={() =>
            syncGoogleCalendar({
              userId: user?.user._id,
              redirect: '/settings/calendars',
            })
          }
        />
      </Card>
    </Box>
  )
}

const Item = ({ name, desc, onConnect }: any) => {
  const { t } = useTranslation()
  const secondaryTextColor = '#999'

  return (
    <HStack p="4" justify="space-between">
      <HStack spacing="4">
        <img src={GoogleCalendar} alt="" style={{ width: 32 }} />
        <Text>{name}</Text>
      </HStack>
      <Text fontSize={14} color={secondaryTextColor}>
        {desc}
      </Text>

      <Button onClick={onConnect} colorScheme="primary">
        {t('connect')}
      </Button>
    </HStack>
  )
}
