import { Button, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useState } from 'react'
import Lottie from 'react-lottie'
import { useGetCoachInfoQuery } from '../../../app/store/services/manager.service'
import noCoach from '../../../assets/lottie/coach.json'
import { useAuth } from '../../../hooks/useAuth'
import { CoachInfo } from '../../../interfaces/main-interfaces'
import { DashboardCard } from '../../../lib/shared/dashboard-card/DashboardCard'
import { DashboardCardLink } from '../../../lib/shared/dashboard-card/dashboard-card.style'
import { CoachDialog } from '../../../lib/widgets/coach-dialog/CoachDialog'
import { DashboardCoach } from './dashboard-coach/DashboardCoach'
import { DashboardCoachesWrapper, NoCoach } from './dashboard-coaches.style'

export function DashboardCoaches() {
  const { t } = useTranslation()
  const { user } = useAuth()
  const navigate = useNavigate()
  const [coach, setCoach] = useState<CoachInfo | undefined>(undefined)

  const { data: coachInfo, isFetching } = useGetCoachInfoQuery(
    {},
    { refetchOnMountOrArgChange: true },
  )
  const isHR = user?.user.isHR

  return !isFetching && !coachInfo ? (
    <NoCoach>
      <Lottie
        options={{
          animationData: noCoach,
          loop: true,
          autoplay: true,
        }}
        width={130}
      />
      <h4>{t('coachNotSelected')}</h4>
      {isHR && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/onboarding/coach-selection')}
        >
          {t('selectCoach')}
        </Button>
      )}
    </NoCoach>
  ) : (
    <DashboardCard title={t('myCoach')}>
      <DashboardCoachesWrapper>
        <Stack>
          <DashboardCoach
            onCoachSelect={() => setCoach(coachInfo)}
            avatar={coachInfo?.profile.picture ?? ''}
            name={coachInfo?.profile.name ?? ''}
            isSelected={false}
          />
          {!isHR && (
            <DashboardCardLink to={coachInfo?.chatLink ?? '/group-chat'}>
              {t('chatWithCoach')}
            </DashboardCardLink>
          )}
        </Stack>
      </DashboardCoachesWrapper>
      {coach && (
        <CoachDialog coach={coach} onClose={() => setCoach(undefined)} />
      )}
    </DashboardCard>
  )
}
