import { styled } from '@mui/material'

export const OnboardingContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: '500px',
  [theme.breakpoints.down('lg')]: {
    maxWidth: '100%',
    width: '100%',
  },
}))
