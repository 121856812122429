import { useCallback } from 'react'
import * as Sentry from '@sentry/react'
import { useAppDispatch, useAppSelector } from '../app/store/store.config'
import { resetStore, setUser } from '../app/store/slices/authSlice'
import { useGetMeReqMutation } from '../app/store/services/auth.service'
import Cookies from 'js-cookie'
import { SELECTED_GROUP_COOKIE_KEY } from '../constants'
import posthog from 'posthog-js'

export const useAuth = () => {
  const { user } = useAppSelector((store) => store.auth)
  const [getMe] = useGetMeReqMutation()
  const dispatch = useAppDispatch()

  const logout = useCallback(() => {
    dispatch(resetStore())
    localStorage.clear()
    Cookies.remove(SELECTED_GROUP_COOKIE_KEY)

    Sentry.setUser(null)
    posthog.reset()
  }, [dispatch])

  const checkUser = useCallback(async () => {
    if (user) {
      try {
        const response = await getMe().unwrap()
        dispatch(setUser({ ...user, user: response }))
      } catch (error: any) {
        // TODO: Check the error and logout if needed
        console.log(error)
      }
    }
  }, [dispatch, getMe, user])

  return {
    user,
    logout,
    checkUser,
  }
}
