import { SVGProps } from 'react'
const CompletedSessionsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={62}
    height={62}
    fill="none"
    {...props}
  >
    <circle cx={30.875} cy={31.197} r={30.625} fill="#D1E5FA" />
    <path
      fill="#1C7AEB"
      d="M42.71 21.052c-.397-.389-.833-.584-1.389-.584h-1.944v-1.44c0-.662-.238-1.207-.714-1.713a2.42 2.42 0 0 0-1.746-.7h-.992c-.675 0-1.23.233-1.746.7a2.328 2.328 0 0 0-.715 1.713v1.44h-5.873v-1.44c0-.662-.238-1.207-.714-1.713a2.42 2.42 0 0 0-1.746-.7h-.992c-.675 0-1.23.233-1.746.7a2.328 2.328 0 0 0-.714 1.713v1.44h-1.945c-.516 0-.992.195-1.389.584-.397.39-.595.818-.595 1.363v19.232c0 .506.198.973.595 1.363.397.389.834.583 1.39.583h21.547c.516 0 .992-.194 1.389-.584.396-.389.595-.817.595-1.362V22.415c.04-.545-.159-.973-.556-1.363Zm-7.262-1.518h1.945v2.998h-1.945v-2.998Zm-11.746 0h1.945v2.998h-1.945v-2.998Zm17.62 22.074H19.773V26.23H41.32v15.378Z"
    />
    <path
      fill="#1C7AEB"
      d="M29.218 38.805a.648.648 0 0 0 .357.117.648.648 0 0 0 .357-.117l7.818-7.67a.62.62 0 0 0 .119-.35.437.437 0 0 0-.12-.311l-.713-.7a.456.456 0 0 0-.318-.118.648.648 0 0 0-.357.117l-6.786 6.657-3.373-3.309a.648.648 0 0 0-.357-.117.455.455 0 0 0-.317.117l-.715.7a.437.437 0 0 0-.119.312c0 .117.04.234.12.35l4.404 4.322Z"
    />
  </svg>
)
export default CompletedSessionsIcon
