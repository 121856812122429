import { ReactNode } from 'react'
import { OnboardingHeaderContent } from './onboarding-shared.styles'

export interface OnboardingHeaderProps {
  title: string | ReactNode
  subTitle?: string | ReactNode
}

export function OnboardingHeader({ title, subTitle }: OnboardingHeaderProps) {
  return (
    <OnboardingHeaderContent>
      <h1> {title} </h1>
      {subTitle && <p> {subTitle} </p>}
    </OnboardingHeaderContent>
  )
}
