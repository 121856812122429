import { useTranslation } from 'react-i18next'
import { SessionStatsContent } from './SessionStatsContent'
import { useGetSessionStatsQuery } from '../../../app/store/services/manager.service'
import { useMemo } from 'react'

export function SessionStats() {
  const { t } = useTranslation()
  const { data: sessions } = useGetSessionStatsQuery(void 0, {
    refetchOnMountOrArgChange: true,
  })

  const sessionData = useMemo(() => {
    if (!sessions)
      return {
        group: { upcomingCount: 0, completedCount: 0, remainingCount: 0 },
        individual: { upcomingCount: 0, completedCount: 0, remainingCount: 0 },
        all: { upcomingCount: 0, completedCount: 0, remainingCount: 0 },
      }
    return {
      group: {
        upcomingCount: sessions.upcomingSessions.group ?? 0,
        completedCount: sessions.completedSessions.group ?? 0,
        remainingCount: sessions.remainingSessions.group ?? 0,
      },
      individual: {
        upcomingCount: sessions.upcomingSessions.individual ?? 0,
        completedCount: sessions.completedSessions.individual ?? 0,
        remainingCount: sessions.remainingSessions.individual ?? 0,
      },
      all: {
        upcomingCount:
          (sessions.upcomingSessions.group ?? 0) +
          (sessions.upcomingSessions.individual ?? 0),
        completedCount:
          (sessions.completedSessions.group ?? 0) +
          (sessions.completedSessions.individual ?? 0),
        remainingCount:
          (sessions.remainingSessions.group ?? 0) +
          (sessions.remainingSessions.individual ?? 0),
      },
    }
  }, [sessions])

  return (
    <SessionStatsContent showViewMore={false} sessionData={sessionData.all} />
  )
}
