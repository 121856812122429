import { Dialog, styled } from '@mui/material'

export const NewChatWrapper = styled(Dialog)(() => ({
  '.MuiPaper-root': {
    boxShadow: 'none',
  },
}))

export const NewChatContent = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: 'auto 1fr auto',
  width: '450px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}))

export const NewChatHeader = styled('div')(({ theme }) => ({
  padding: '12px',
  borderBottom: `1px solid ${theme.palette.divider}`,
  h4: {
    font: '600 18px Poppins, sans-serif',
  },
}))

export const NewChatBody = styled('div')(({ theme }) => ({
  maxHeight: '400px',
  minHeight: '300px',
  position: 'relative',
  overflowY: 'auto',
  '.loading': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(255,255,255,.4)',
  },
  [theme.breakpoints.down('md')]: {
    '.MuiButtonBase-root': {
      gap: '12px',
      padding: '4px 12px',
    },
    '.MuiListItemAvatar-root': {
      width: '40px !important',
      height: '40px !important',
      minWidth: 0,
      div: {
        width: '40px',
        height: '40px',
      },
    },
    '.MuiListItemText-primary': {
      font: '500 12px Poppins, sans-serif',
    },
    '.MuiListItemText-secondary': {
      font: '400 12px Poppins, sans-serif',
    },
  },
}))

export const ListItemFooter = styled('div')(({ theme }) => ({
  padding: '12px',
  borderTop: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  justifyContent: 'flex-end',
}))
