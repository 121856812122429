import { useEffect } from 'react'
import { useRouteError } from 'react-router-dom'
import { Box, Button, Center, Heading, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { IoMdConstruct } from 'react-icons/io'
import { FiRefreshCcw } from 'react-icons/fi'
import { useAuth } from './hooks/useAuth'
import { useAppSelector } from './app/store/store.config'

// This component is rendered when a route throws an error
export const ErrorPage: React.FC = () => {
  const error = useRouteError() as any
  const user = useAppSelector((state) => state.auth.user?.user)
  const { t } = useTranslation()
  const { logout } = useAuth()

  useEffect(() => {
    if (import.meta.env.DEV) {
      console.log(error)
      return
    }

    const email = user?.email
    const date = new Date().toISOString()

    fetch('https://api.hupo.co/v2/error/client', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        uri: window.location.href,
        time: date,
        message: error.message,
        stack: error.stack,
        userEmail: email,
      }),
    }).then((res) => res.json())
  }, [error, user])

  return (
    <Box textAlign="center" py={10} px={6}>
      <Center mb={10}>
        <IoMdConstruct size={100} color="#E2E8F0" />
      </Center>
      <Heading
        display="inline-block"
        as="h2"
        size="2xl"
        mb={6}
        fontWeight="bold"
        lineHeight="shorter"
        color="gray.900"
      >
        {t('error')}
      </Heading>
      <Text fontSize="18px" mt={3} mb={10}>
        {error ?? t('error_description')}
      </Text>

      <Button
        size="lg"
        colorScheme="primary"
        leftIcon={<FiRefreshCcw />}
        onClick={logout}
      >
        {t('error_button')}
      </Button>
    </Box>
  )
}
