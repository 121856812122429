import { SVGProps } from 'react'
const StepCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={14}
    fill="none"
    {...props}
  >
    <path
      fill="#FF4B0A"
      stroke="#FF4B0A"
      strokeWidth={2.572}
      d="m15.15 2.195-8.5 8.668a.615.615 0 0 1-.436.185.604.604 0 0 1-.436-.185L2.49 7.508a.615.615 0 0 0-.436-.185.605.605 0 0 0-.436.185.628.628 0 0 0-.181.444.638.638 0 0 0 .181.445l3.288 3.352c.347.353.817.552 1.308.552.49 0 .96-.199 1.307-.552l8.5-8.665a.628.628 0 0 0 .18-.444.638.638 0 0 0-.18-.445.615.615 0 0 0-.436-.184.605.605 0 0 0-.436.184Z"
    />
  </svg>
)
export default StepCheck
