import { Flex, Text } from '@chakra-ui/react'
import { FC } from 'react'

import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { processNewLineText } from '../../../utils/text'
import { AssessmentProgress } from '../assessment-progress/AssessmentProgress'
import { AssessmentQuestion } from '../assessment-question/AssessmentQuestion'
import { AssessmentItemWrapper } from '../assessment.style'

const StepQuestion: FC<any> = ({
  handleBack,
  isLoading,
  progress,
  question,
  onSubmit,
  isLast,
}) => {
  const { t } = useTranslation()
  return (
    <AssessmentItemWrapper>
      <AssessmentProgress onBack={handleBack} percent={progress} />
      <AnimatePresence key={question.friendlyID}>
        <AssessmentQuestion
          progress={progress}
          onButtonClick={onSubmit}
          title={question.title}
          subTitle={question.description}
          isLoading={isLoading}
          buttonText={isLast ? t('finish') : t('continue')}
        >
          <motion.div
            initial={{ opacity: 0, y: -200 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <Flex direction="column" py={5} gridRowGap={5} alignItems="center">
              <Text
                fontWeight="600"
                textAlign="center"
                lineHeight={8}
                fontSize={['2xl', '3xl']}
                mt={20}
              >
                {processNewLineText(question.title)}
              </Text>
              <Text
                fontWeight="400"
                maxWidth={600}
                lineHeight={1.3}
                fontSize={['xl']}
                textAlign="center"
                mt={2}
                mb={20}
              >
                {processNewLineText(question.description)}
              </Text>
            </Flex>
          </motion.div>
        </AssessmentQuestion>
      </AnimatePresence>
    </AssessmentItemWrapper>
  )
}

export default StepQuestion
