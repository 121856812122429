import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import moment from 'moment-timezone'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import {
  useCreateSessionMutation,
  useLazyCheckAvailabilityQuery,
  useUpdateSessionMutation,
} from '../../../../app/store/services/manager.service'
import { sessionDurations } from '../../../../constants/sessionDurations'
import { SuggestedDate } from '../../../../interfaces/main-interfaces'
import { AppDatePicker } from '../../../../lib/shared/DatePicker'
import { FormField } from '../../../../lib/shared/FormField'
import { FormSelect } from '../../../../lib/shared/FormSelect'
import { CheckSlotsDTO, CreateSessionDTO } from '../dto/scheduling.dto'
import { SuggestionItem } from './SuggestionItem'
import {
  CreateSessionContent,
  CreateSessionForm,
  CreateSessionWrapper,
  SuggesstionList,
} from './create-session.style'

const convertToYYYYMMDD = (date: string) => {
  return moment(date).format('YYYY-MM-DD')
}

export function CreateSession({
  initialData,
  onClose,
  open,
  defaultTitle,
  canBookSession,
  defaultIndividualTitle,
}: {
  open: boolean
  onClose: () => void
  initialData: CreateSessionDTO | null
  defaultTitle: string
  canBookSession: { team: boolean; individual: boolean }
  defaultIndividualTitle: string
}) {
  const { t } = useTranslation()

  const [
    triggerCheckAvailability,
    { data: availabilitySlots, error: availabilitySlotsError },
  ] = useLazyCheckAvailabilityQuery()

  const [selectedSlot, setSelectedSlot] = useState('')
  const [suggestions, setSuggestions] = useState<SuggestedDate[]>([])
  const [loading, setLoading] = useState(false)
  const [coachAvailable, setCoachAvailable] = useState(true)

  const [createSession, { isLoading }] = useCreateSessionMutation()
  const [updateSession, { isLoading: updateLoading }] =
    useUpdateSessionMutation()

  const isRescheduling = !!initialData?.id

  const messages = {
    success: isRescheduling ? 'sessionRescheduled' : 'sessionCreated',
    error: isRescheduling ? 'sessionCreateError' : 'sessionUpdateError',
  }

  const { control, reset, handleSubmit, setValue, watch } = useForm({
    defaultValues: initialData || {},
  })

  useEffect(() => {
    if (!availabilitySlots) return
    setSuggestions([
      ...availabilitySlots.data.free,
      ...availabilitySlots.data.partial,
    ])
    setLoading(false)
  }, [availabilitySlots])

  const confirm = useConfirm()

  const handleMutate = useCallback(
    async (body: any) => {
      const response = initialData?.id
        ? await updateSession({
            body,
            id: initialData.id,
          }).unwrap()
        : await createSession(body).unwrap()
      // TODO: Handle if there's an error in response message
      console.log(response)

      toast.success(t(messages.success))

      onClose()
    },
    [createSession, initialData, messages.success, onClose, t, updateSession],
  )

  const onSubmit = handleSubmit(async (data) => {
    const start = moment(data.start).toISOString()
    const today = moment()
    // Check whether start time is in the past.
    const startTimeIsInPast = moment(start).isBefore(today)
    if (startTimeIsInPast) {
      toast.error(t('startingDateIsPast'))
      return
    }
    try {
      const body = {
        duration: data.duration,
        start,
        title: data.title,
      }
      if (!coachAvailable) {
        handleMutate(body)
      } else {
        confirm({
          title: t('warning'),
          description: t('coachIsNotAvailable'),
          confirmationText: t('anywayProceed'),
        }).then(() => {
          handleMutate(body)
        })
      }
    } catch (error: any) {
      console.log(error)
      toast.error(t(messages.error))
    }
  })

  const handleCheck = useCallback(
    async (data: CheckSlotsDTO) => {
      setCoachAvailable(false)
      try {
        setLoading(true)
        await triggerCheckAvailability({
          date: data.date,
          duration: data.duration,
        }).unwrap()
      } catch (error: any) {
        if (error?.status === 400) {
          setCoachAvailable(true)
          setSuggestions([])
        }
      } finally {
        setLoading(false)
      }
    },
    [triggerCheckAvailability],
  )

  useEffect(() => {
    reset(
      initialData
        ? {
            duration: initialData.duration,
            start: initialData.start,
            title: initialData.title,
          }
        : {},
    )
    if (initialData) {
      handleCheck({
        date: convertToYYYYMMDD(initialData.start),
        duration: initialData.duration,
      })
    }
  }, [handleCheck, initialData, reset])

  const suggestionsPartial = suggestions.filter(
    (item) => item?.availableMembers,
  )

  if (
    !isRescheduling && // no need to check limits when rescheduling as number of allowed sessions is not affected
    !canBookSession.team
  ) {
    return (
      <CreateSessionWrapper open={open} onClose={onClose}>
        <DialogTitle>
          {t(isRescheduling ? 'updateGroupSession' : 'bookGroupSession')}
        </DialogTitle>
        <DialogContent>
          <p>{t('no_team_coaching_sessions_left')}</p>
          <p
            dangerouslySetInnerHTML={{
              __html: t('contact_hupo_for_more_sessions').replace(
                'team@hupo.co',
                '<a style="color: blue; text-decoration: underline" href="mailto:team@hupo.co">team@hupo.co</a>',
              ),
            }}
          ></p>
        </DialogContent>
        <DialogActions
          sx={{ display: 'grid', justifyContent: 'stretch', p: '16px 22px' }}
        >
          <Button
            type="submit"
            variant="contained"
            size="small"
            onClick={onClose}
          >
            {t('close')}
          </Button>
        </DialogActions>
      </CreateSessionWrapper>
    )
  }

  return (
    <CreateSessionWrapper open={open} onClose={onClose}>
      <DialogTitle>
        {t(isRescheduling ? 'updateGroupSession' : 'bookGroupSession')}
      </DialogTitle>
      <DialogContent>
        <CreateSessionForm id="sessionForm" onSubmit={onSubmit}>
          <CreateSessionContent>
            <FormField
              variant="filled"
              label={t('title')}
              control={control}
              defaultValue={defaultTitle}
              name="title"
            />
            <AppDatePicker
              variant="filled"
              label={t('startTime')}
              control={control}
              name="start"
              onChange={() => {
                setSelectedSlot('')
                handleCheck({
                  date: convertToYYYYMMDD(watch('start')),
                  duration: watch('duration'),
                })
              }}
              rules={{ required: { value: true, message: t('requiredField') } }}
            />
            <FormSelect
              variant="filled"
              label={t('duration')}
              control={control}
              name="duration"
              rules={{ required: { value: true, message: t('requiredField') } }}
              options={sessionDurations.filter(
                (item) => item.value === initialData?.duration,
              )}
              onChange={() => {
                setSelectedSlot('')
                handleCheck({
                  date: convertToYYYYMMDD(watch('start')),
                  duration: watch('duration'),
                })
              }}
            />
            {
              <SuggesstionList>
                <h4> {t('suggestions')} </h4>
                <div className={`list ${loading ? 'loading' : ''}`}>
                  {loading ? (
                    <CircularProgress />
                  ) : suggestions.length > 0 ? (
                    <>
                      <Typography fontWeight="600" fontSize="14px">
                        {t('fullyAvailable')}
                      </Typography>
                      {suggestions
                        .filter((item) => !item?.availableMembers)
                        .map((slot, index) => (
                          <SuggestionItem
                            selectedSlot={selectedSlot}
                            setSelectedSlot={(value: string) => {
                              setSelectedSlot(value)
                              setValue('start', moment(value).toISOString())
                            }}
                            slot={slot}
                            key={`suggestion_${index}`}
                          />
                        ))}
                      {suggestionsPartial.length > 0 && (
                        <Stack pt="12px">
                          <Typography fontWeight="600" fontSize="14px">
                            {t('partiallyAvailable')}
                          </Typography>
                        </Stack>
                      )}
                      {suggestionsPartial.map((slot, index) => (
                        <SuggestionItem
                          selectedSlot={selectedSlot}
                          setSelectedSlot={(value: string) => {
                            setSelectedSlot(value)
                            setValue('start', moment(value).toISOString())
                          }}
                          slot={slot}
                          key={`suggestion_${index}`}
                        />
                      ))}
                    </>
                  ) : (
                    <div className="emptySuggestions">
                      <img src="/schedule.svg" alt="Schedule" />
                      <h6> {t('noSuggestions')} </h6>
                    </div>
                  )}
                </div>
              </SuggesstionList>
            }
          </CreateSessionContent>
        </CreateSessionForm>
      </DialogContent>
      <DialogActions
        sx={{ display: 'grid', justifyContent: 'stretch', p: '16px 22px' }}
      >
        <Button
          disabled={loading || isLoading || updateLoading || !selectedSlot}
          type="submit"
          form="sessionForm"
          variant="contained"
          size="large"
        >
          {t(
            loading
              ? 'checkingForAvailabilities'
              : isLoading || updateLoading
                ? 'loading'
                : isRescheduling
                  ? 'reschedule_'
                  : 'book',
          )}
        </Button>
      </DialogActions>
    </CreateSessionWrapper>
  )
}
