import { Dialog, styled } from '@mui/material'

export const ViewEventWrapper = styled(Dialog)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    '.MuiDialogContent-root': {
      padding: '12px',
    },
  },
}))

export const ViewEventContent = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: '24px',
  '.eventContent': {
    display: 'grid',
    gap: '12px',
    '.eventItem': {
      borderRadius: '12px',
      border: `1px solid ${theme.palette.divider}`,
      background: theme.palette.grey[100],
      padding: '6px 12px',
      display: 'grid',
      gap: '4px',
      [theme.breakpoints.down('md')]: {
        padding: '3px 6px',
      },
      h4: {
        font: '600 14px Poppins, sans-serif',
      },
      p: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        },
      },
      '.itemTitle': {
        font: '400 12px Poppins, sans-serif',
        lineHeight: '14px',
      },
      '.itemSubtitle': {
        font: '400 12px Poppins, sans-serif',
        lineHeight: '14px',
      },
      '.attendees': {
        display: 'grid',
        gap: '12px',
        div: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          h5: {
            font: '400 12px Poppins, sans-serif',
          },
          p: {
            font: '400 12px Poppins, sans-serif',
          },
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          },
        },
      },
    },
  },
  '.footer': {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gap: '12px',
  },
}))

export const ViewEventTop = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '12px',
  padding: '16px 14px 16px 24px',
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  '.actions': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '6px',
  },
  '.eventHeader': {
    display: 'grid',
    gap: '5px',
    h2: {
      font: '600 16px Poppins, sans-serif',
      lineHeight: '22px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
    },
    '.eventDate': {
      font: '500 12px Poppins, sans-serif',
    },
  },
}))
