import { Component } from 'react'
import { Box, Typography, Button, Container, Paper } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { store } from './app/store/store.config'

type Props = {
  children: React.ReactNode
}

type State = {
  hasError: boolean
  error: any
  troubleshootModalOpen: boolean
}

// Component that catches errors in its children and displays a fallback UI
export class ErrorBoundary extends Component<Props> {
  state: State = {
    hasError: false,
    troubleshootModalOpen: false,
    error: null,
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true, error }
  }

  componentDidCatch(error: any, errorInfo: any) {
    if (import.meta.env.DEV) {
      console.log(error)
      console.log(errorInfo?.componentStack)
      return
    }

    const email = store.getState().auth.user?.user.email
    const date = new Date().toISOString()

    fetch('https://api.hupo.co/v2/error/client', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        uri: window.location.href,
        time: date,
        message: error.message,
        stack: error.stack,
        componentStack: errorInfo?.componentStack,
        userEmail: email,
      }),
    }).then((res) => res.json())
  }

  setTroubleshootModalOpen = (open: boolean) => {
    this.setState({ troubleshootModalOpen: open })
  }

  onTroubleshootingClick = () => {
    this.setTroubleshootModalOpen(true)
  }

  render() {
    if (!this.state.hasError && !this.state.error) {
      return this.props.children
    }

    const message = import.meta.env.PROD
      ? 'An unexpected error occurred.'
      : this.state.error?.message
    const stack = import.meta.env.PROD ? null : this.state.error?.stack

    return (
      <>
        <Container maxWidth="sm">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '100vh',
            }}
          >
            <ErrorOutlineIcon
              sx={{ fontSize: 60, color: 'error.main', mb: 2 }}
            />
            <Typography
              variant="h2"
              component="h1"
              gutterBottom
              color="error.main"
              fontSize={40}
            >
              An error occurred
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom>
              {message}
            </Typography>
            {stack && (
              <Typography variant="body1" color="text.secondary" paragraph>
                {stack}
              </Typography>
            )}
            <Box
              sx={{
                mt: 3,
                display: 'flex',
                justifyContent: 'center',
                gap: 2,
              }}
            >
              <Button
                variant="contained"
                href="/"
                color="error"
                disableElevation
              >
                Go to Homepage
              </Button>
              <Button
                variant="outlined"
                color="error"
                disableElevation
                onClick={() => window.location.reload()}
              >
                Try Again
              </Button>
            </Box>
          </Box>
        </Container>
      </>
    )
  }
}
