import { QuestionProps } from '../components/PeerAssessmentArea'
import { QuestionTitle } from './components/QuestionHeader'

export function InfoQuestion({ data, user }: QuestionProps) {
  return (
    <>
      <QuestionTitle data={data} user={user} />
      <div></div>
    </>
  )
}
