import { GOOGLE_CALENDAR_SCOPES } from '../../constants'
import { envVars } from '../../constants/envVars'

export const syncGoogleCalendar = ({
  userId,
  redirect,
}: {
  userId: string | undefined
  redirect: string
}) => {
  if (!userId) {
    return
  }

  const params = {
    client_id: envVars.GOOGLE_CLIENT_ID,
    redirect_uri: envVars.GOOGLE_CALLBACK_URL,
    scope: GOOGLE_CALENDAR_SCOPES.join(' '),
    state: JSON.stringify({ userId, redirect }),
    access_type: 'offline',
    prompt: 'consent',
    // include_granted_scopes: 'true',
    response_type: 'code',
  }

  const form = document.createElement('form')
  form.setAttribute('action', 'https://accounts.google.com/o/oauth2/v2/auth')
  form.setAttribute('method', 'GET')

  for (const p in params) {
    const input = document.createElement('input')
    input.setAttribute('type', 'hidden')
    input.setAttribute('name', p)
    input.setAttribute('value', params[p as keyof typeof params])
    form.appendChild(input)
  }

  document.body.appendChild(form)
  form.submit()
}
