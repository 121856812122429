import { Box, Flex, HStack, Link, Text } from '@chakra-ui/react'
import { FC, useEffect, useState } from 'react'
import { MdExpandLess, MdExpandMore } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import { useTheme } from '@mui/material'

import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { setRating } from '../../../app/store/slices/assessmentSlice'
import { useAppSelector } from '../../../app/store/store.config'
import {
  agreeDisagreeScale,
  leadershipValueScaleSelf,
} from '../../../utils/ratings'
import { AssessmentProgress } from '../assessment-progress/AssessmentProgress'
import { AssessmentQuestion } from '../assessment-question/AssessmentQuestion'
import { AssessmentItemWrapper } from '../assessment.style'
import useToast from '../../../hooks/useToast'
import Toast from '../../../lib/shared/Toast'

const SelectOption: FC<any> = ({ rating, selected, onItemSelected }) => {
  const isItemSelected = selected?.scale === rating.scale
  const theme: any = useTheme()

  return (
    <Box
      key={rating.level}
      boxShadow="0 0px 5px rgba(0,0,0,0.15)"
      borderColor={'#000'}
      mb={20}
      borderRadius={20}
      padding={15}
      bg={isItemSelected ? theme.palette.primary['400'] : '#fdf5f5'}
      cursor="pointer"
      onClick={() => onItemSelected(rating)}
    >
      <Flex direction={'column'} alignItems="center">
        <Text
          color={isItemSelected ? '#fff' : '#000'}
          textAlign="center"
          fontWeight="600"
          fontSize={'xl'}
        >
          {rating.level}
        </Text>
      </Flex>
    </Box>
  )
}

const ValueQuestion: FC<any> = ({
  handleBack,
  isLoading,
  progress,
  question,
  onSubmit,
  valuesType,
  leadershipValue,
}) => {
  const [selected, setSelected] = useState(null)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { toast, showToast, hideToast } = useToast()
  const user = useAppSelector((state) => state.assessment.user)
  const [showDescription, setShowDescription] = useState(false)

  const onItemSelected = (rating: any) => {
    setSelected(rating)

    dispatch(
      setRating({
        focusArea: leadershipValue.value,
        scale: rating.scale,
      }),
    )
  }

  const onLocalSubmit = () => {
    if (!selected) {
      showToast(t('select_option'), 'error')
      return
    }
    onSubmit()
    setSelected(null)
  }

  const valueScaleInstance = valuesType
    ? agreeDisagreeScale(t)
    : leadershipValueScaleSelf(t)

  return (
    <AssessmentItemWrapper>
      <Toast
        open={toast.open}
        message={toast.message}
        severity={toast.severity}
        onClose={hideToast}
      />
      <AssessmentProgress onBack={handleBack} percent={progress} />
      <AssessmentQuestion
        progress={progress}
        onButtonClick={onLocalSubmit}
        title={question.title.replaceAll('{{username}}', user)}
        subTitle={
          <>
            <Text textAlign="center" fontSize={'xl'} fontStyle="italic">
              {leadershipValue.label}
            </Text>
            {!!leadershipValue.descriptionHtml && (
              <>
                <Link onClick={() => setShowDescription(!showDescription)}>
                  <div style={{ textAlign: 'center' }}>
                    <HStack>
                      <Text textAlign="center" as="u">
                        {showDescription
                          ? t('hide_description')
                          : t('show_description')}
                      </Text>
                      {showDescription ? (
                        <MdExpandLess size={24} />
                      ) : (
                        <MdExpandMore size={24} />
                      )}
                    </HStack>
                  </div>
                </Link>
                {showDescription ? (
                  <div
                    style={{ fontSize: '1.em' }}
                    dangerouslySetInnerHTML={{
                      __html: leadershipValue.descriptionHtml,
                    }}
                  />
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        }
        isLoading={isLoading}
        buttonText={t('continue')}
      >
        <AnimatePresence key={question.friendlyID}>
          <motion.div
            initial={{ opacity: 0, y: -200 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <Box my={3}>
              {valueScaleInstance.map((rating, index) => (
                <SelectOption
                  key={'rating#' + index}
                  rating={rating}
                  selected={selected}
                  onItemSelected={onItemSelected}
                />
              ))}
            </Box>
          </motion.div>
        </AnimatePresence>
      </AssessmentQuestion>
    </AssessmentItemWrapper>
  )
}

export default ValueQuestion
