import { useTranslation } from 'react-i18next'
import { ChatListHeaderWrapper } from './chat-list-header.style'

export function ChatListHeader() {
  const { t } = useTranslation()

  return (
    <ChatListHeaderWrapper>
      <h4>{t('messages')}</h4>
    </ChatListHeaderWrapper>
  )
}
