import { styled } from '@mui/material'

export const ChatListUserWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
  justifyContent: 'space-between',
  gap: '12px',
  padding: '12px',
  cursor: 'pointer',
  ':hover': {
    background: '#F1F1F1',
  },
  ':active': {
    background: theme.palette.grey[200],
  },
  '&.active': {
    background: theme.palette.grey[200],
  },
  [theme.breakpoints.down('md')]: {
    borderRight: '12px 0',
    padding: '6px 0',
  },
}))

export const ChLUserLeft = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: '12px',
  alignItems: 'center',
  alignContent: 'center',
  gridTemplateColumns: 'auto 1fr',
  img: {
    background: theme.palette.grey[100],
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '100px',
    userSelect: 'none',
  },
  '.textContent': {
    display: 'grid',
    gap: '5px',

    h4: {
      font: '500 14px Poppins, sans-serif',
      whiteSpace: 'nowrap',
      userSelect: 'none',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      color: '#222222',
      svg: {
        fontSize: '20px',
        color: '#222222',
      },
    },
    p: {
      font: '400 13px Poppins, sans-serif',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      userSelect: 'none',
      color: '#919191',
    },
  },
}))

export const ChLUserUnreadMsgs = styled('span')(({ theme }) => ({
  font: '500 12px Poppins, sans-serif',
  background: theme.palette.primary.main,
  borderRadius: '100px',
  color: theme.palette.primary.contrastText,
  userSelect: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  aspectRatio: '1/1',
  width: '25px',
  height: '25px',
}))
