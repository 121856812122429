import { Dialog, alpha, styled } from '@mui/material'

export const CreateSessionWrapper = styled(Dialog)(() => ({
  '.MuiDialog-paper': {
    boxShadow: 'none',
    width: '500px',
    h2: {
      font: '700 18px Poppins, sans-serifs',
    },
  },
}))

export const CreateSessionForm = styled('form')(() => ({
  display: 'grid',
  gap: '16px',
}))

export const CreateSessionContent = styled('div')(() => ({
  display: 'grid',
  gap: '12px',
}))

export const SuccessMessage = styled('div')(({ theme }) => ({
  background: 'linear-gradient(to right,#CFF4AF, #78E2B4)',
  padding: '6px 12px',
  font: '450 16px Poppins, sans-serif',
  borderRadius: '12px',
  color: theme.palette.success.dark,
}))

export const SuggesstionList = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: '12px',
  h4: {
    font: '600 16px Poppins, sans-serif',
  },
  '.list': {
    display: 'grid',
    gap: '6px',
    overflowY: 'auto',
    height: '300px',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '12px',
    position: 'relative',
    background: theme.palette.grey[100],
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '12px',
    '&.loading': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  '.emptySuggestions': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    // textAlign: "center",
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    gap: '24px',
    img: {
      width: '150px',
    },
    h6: {
      font: '400 14px Poppins, sans-serif',
      maxWidth: '400px',
      textAlign: 'center',
    },
  },
}))

export const SuggesstionItemWrapper = styled('div')(({ theme }) => ({
  padding: '6px 12px',
  display: 'grid',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '12px',
  cursor: 'pointer',
  borderLeft: '5px solid #06C755',
  background: theme.palette.background.paper,
  position: 'relative',
  '&.partial': {
    borderLeft: `5px solid ${theme.palette.warning.light}`,
    '.selected': {
      color: theme.palette.warning.light,
    },
    '&.active': {
      background: alpha(theme.palette.warning.light, 0.1),
    },
  },
  p: {
    font: '600 14px Poppins, sans-serif',
  },
  '.hint': {
    font: '500 12px Poppins, sans-serif',
    color: theme.palette.grey[500],
  },
  '&.active': {
    background: '#06c75610',
  },
  '.selected': {
    position: 'absolute',
    top: '50%',
    right: 12,
    transform: 'translateY(-50%)',
    width: '24px',
    height: '24px',
    color: '#06c756',
  },
}))
