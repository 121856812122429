import { styled } from '@mui/material'

export const ProfileFormWrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '450px 1fr',
  gap: '24px',
  alignItems: 'flex-start',
  alignContent: 'flex-start',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}))

export const ProfileFormLeft = styled('div')(({ theme }) => ({
  display: 'grid',
  h4: {
    font: '500 14px Poppins, sans-serif',
  },
  p: {
    font: '400 12px Poppins, sans-serif',
    color: theme.palette.grey[500],
  },
}))

export const ProfileFormRight = styled('form')(({ theme }) => ({
  maxWidth: '600px',
  display: 'grid',
  gap: '24px',
  background: theme.palette.background.paper,
  padding: '24px',
  borderRadius: '12px',
  border: `1px solid ${theme.palette.divider}`,
  '.MuiListItem-root': {
    gap: '12px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '12px',
  },
}))
