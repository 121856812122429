import { useState } from 'react'
import { AlertColor } from '@mui/material'

interface ToastState {
  open: boolean
  message: string
  severity: AlertColor
}

const useToast = () => {
  const [toast, setToast] = useState<ToastState>({
    open: false,
    message: '',
    severity: 'info',
  })

  const showToast = (message: string, severity: AlertColor = 'info') => {
    setToast({ open: true, message, severity })
  }

  const hideToast = () => {
    setToast((prev) => ({ ...prev, open: false }))
  }

  return { toast, showToast, hideToast }
}

export default useToast
