import { SVGProps } from 'react'
const ScheduledSessionsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={62}
    height={62}
    fill="none"
    {...props}
  >
    <circle cx={30.625} cy={31.197} r={30.625} fill="#FFDBCD" />
    <path
      fill="#FF4B0A"
      d="M42.46 22.146c-.397-.39-.833-.584-1.389-.584h-1.944v-1.44c0-.662-.238-1.207-.714-1.713a2.42 2.42 0 0 0-1.746-.701h-.992c-.675 0-1.23.234-1.746.7a2.328 2.328 0 0 0-.715 1.714v1.44h-5.873v-1.44c0-.662-.238-1.207-.714-1.713a2.42 2.42 0 0 0-1.746-.701h-.992c-.675 0-1.23.234-1.746.7a2.328 2.328 0 0 0-.714 1.714v1.44h-1.945c-.516 0-.992.195-1.389.584-.397.39-.595.818-.595 1.363V42.74c0 .506.198.973.595 1.362.397.39.834.584 1.39.584h21.547c.516 0 .992-.194 1.389-.584.396-.39.595-.817.595-1.362V23.509c.04-.545-.159-.974-.556-1.363Zm-7.262-1.518h1.945v2.997h-1.945v-2.997Zm-11.746 0h1.945v2.997h-1.945v-2.997Zm17.62 22.074H19.523V27.324H41.07v15.378Z"
    />
    <circle cx={24.063} cy={30.833} r={0.729} fill="#FF4B0A" />
    <path
      stroke="#FF4B0A"
      strokeLinecap="round"
      strokeWidth={0.729}
      d="M25.521 30.833h10.938"
    />
    <circle cx={24.063} cy={33.75} r={0.729} fill="#FF4B0A" />
    <path
      stroke="#FF4B0A"
      strokeLinecap="round"
      strokeWidth={0.729}
      d="M25.521 33.75h10.938"
    />
    <circle cx={24.063} cy={36.666} r={0.729} fill="#FF4B0A" />
    <path
      stroke="#FF4B0A"
      strokeLinecap="round"
      strokeWidth={0.729}
      d="M25.521 36.666h10.938"
    />
    <circle cx={24.063} cy={39.583} r={0.729} fill="#FF4B0A" />
    <path
      stroke="#FF4B0A"
      strokeLinecap="round"
      strokeWidth={0.729}
      d="M25.521 39.583h10.938"
    />
  </svg>
)
export default ScheduledSessionsIcon
