import { PeerAssessmentValue } from '../../../interfaces/main-interfaces'
import { QuestionProps } from '../components/PeerAssessmentArea'
import { Animated } from './components/Animated'
import { QuestionTitle } from './components/QuestionHeader'
import { ValueScoreList } from './components/question-types.style'

export function MultiSelectQuestion({
  data,
  user,
  index,
  values,
  answers,
  setAnswers,
}: QuestionProps & { values: PeerAssessmentValue[] }) {
  console.clear()
  console.log(data, values, answers.answers)
  return (
    <>
      <QuestionTitle data={data} user={user} />

      <Animated key={data.friendlyID}>
        <ValueScoreList>
          {values.map((item) => {
            const value = answers.answers[data.friendlyID] || []
            const isActive = value?.includes(item.value)
            return (
              <div
                className={isActive ? 'active' : ''}
                onClick={() =>
                  setAnswers({
                    ...answers,
                    answers: {
                      ...answers.answers,
                      [data.friendlyID]: isActive
                        ? value.filter((elem: string) => elem !== item.value)
                        : [...value, item.value],
                    },
                  })
                }
                key={item.value}
              >
                {item.label}
              </div>
            )
          })}
        </ValueScoreList>
      </Animated>
    </>
  )
}
