import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { envVars } from '../../../constants/envVars'

export const COACH_TAG = {
  COACH: 'COACH',
}

export const coachService = createApi({
  reducerPath: 'coachApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${envVars.BASE_URL}/coach`,
  }),
  tagTypes: [COACH_TAG.COACH],
  endpoints: (builder) => ({
    postVerifyHupoHighlightsSummary: builder.mutation({
      query: ({ sessionId, ...body }) => ({
        url: `/summaries/${sessionId}/verify`,
        method: 'POST',
        body,
      }),
    }),
    getHupoHighlightsSummary: builder.query({
      query: ({ sessionId }) => ({
        url: `/summaries/${sessionId}`,
        method: 'GET',
      }),
    }),
    postHupoHighlightsSummary: builder.mutation({
      query: ({ sessionId, ...body }) => ({
        url: `/summaries/${sessionId}`,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useGetHupoHighlightsSummaryQuery,
  usePostHupoHighlightsSummaryMutation,
  usePostVerifyHupoHighlightsSummaryMutation,
} = coachService
