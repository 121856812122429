import { ReactNode } from 'react'
import {
  DashboardCardTitle,
  DashboardCardWrapper,
} from './dashboard-card.style'

export interface DashboarCardProps {
  title: string
  children: ReactNode
}

export function DashboardCard({ children, title }: DashboarCardProps) {
  return (
    <DashboardCardWrapper>
      <DashboardCardTitle> {title} </DashboardCardTitle>
      {children}
    </DashboardCardWrapper>
  )
}
