import { Box, Text } from '@chakra-ui/react'
import { Person, Star } from '@mui/icons-material'
import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material'
import { useMemo } from 'react'
import { Check } from 'react-feather'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  PeerAssessmentHeader,
  PeerAssessmentUsersList,
} from './peer-assessment.style'
import { PeerAssessmentItem } from '../../../interfaces/main-interfaces'

export function PeerAssessmentUsers({
  questions,
  company,
  isMobileMenuOpen,
}: {
  questions: PeerAssessmentItem[]
  company?: string
  isMobileMenuOpen: boolean
}) {
  const navigate = useNavigate()
  const { user, author } = useParams<{
    user: string
    author: string
    question: string
  }>()
  const { pathname } = useLocation()

  const pendingQuestions = useMemo(() => {
    return questions.filter((question) => question.status !== 'COMPLETED')
  }, [questions])

  const completedQuestions = useMemo(() => {
    return questions.filter((question) => question.status === 'COMPLETED')
  }, [questions])

  return (
    <PeerAssessmentUsersList className={isMobileMenuOpen ? 'open' : ''}>
      <PeerAssessmentHeader>
        <img src="/images/hupo-logo.svg" alt="Hupo" />
      </PeerAssessmentHeader>
      {/* <Box>
        <Text fontSize="sm" fontWeight="bold" color="gray.500" mt={4} mb={2}>
          Leadership values
        </Text>
        <ListItemButton
          selected={pathname.includes('u/q/leadership-values')}
          disableRipple
          onClick={() =>
            navigate(`/peer-assessment/${author}/u/q/leadership-values`)
          }
        >
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: 'warning.light' }}>
              <Star />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={company} />
        </ListItemButton>
      </Box> */}
      <Box>
        {pendingQuestions.length > 0 ? (
          <Text fontSize="sm" fontWeight="bold" color="gray.500" mt={4} mb={2}>
            Pending Requests
          </Text>
        ) : (
          <></>
        )}
        {pendingQuestions.map((question) => (
          <ListItemButton
            disableRipple
            selected={user === question.user._id}
            key={question.user._id}
            onClick={() =>
              navigate(
                `/peer-assessment/${author}/${question.user._id}/${
                  question.questionIndex &&
                  question.questionIndex < question.questions.length
                    ? question.questions[question.questionIndex]?._id
                    : question.questions[0]._id
                }`,
              )
            }
          >
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: 'error.light' }}>
                <Person />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={question.user.name}
              secondary={question.user.email}
            />
          </ListItemButton>
        ))}
      </Box>

      <Box>
        {completedQuestions.length > 0 ? (
          <Text fontSize="sm" fontWeight="bold" color="gray.500" mt={4} mb={2}>
            Completed Requests
          </Text>
        ) : (
          <></>
        )}
        {completedQuestions.map((question) => (
          <ListItemButton
            disableRipple
            selected={user === question.user._id}
            key={question.user._id}
            onClick={() =>
              navigate(
                `/peer-assessment/${author}/${question.user._id}/${
                  question.questions[question.questions.length - 1]._id
                }`,
              )
            }
          >
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: 'success.light' }}>
                <Check />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={question.user.name}
              secondary={question.user.email}
            />
          </ListItemButton>
        ))}
      </Box>
    </PeerAssessmentUsersList>
  )
}
