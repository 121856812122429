import {
  Box,
  Divider,
  Flex,
  Stack,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react'
import _ from 'lodash'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiChevronRight } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import { useAppSelector } from '../../../app/store/store.config'
import { AssessmentItemWrapper } from '../assessment.style'
import { AssessmentProgress } from '../assessment-progress/AssessmentProgress'
import { AssessmentQuestion } from '../assessment-question/AssessmentQuestion'
import { AnimatePresence, motion } from 'framer-motion'
import { itemSelect } from '../../../app/store/slices/assessmentSlice'
import { useTheme } from '@mui/material'
import Toast from '../../../lib/shared/Toast'
import useToast from '../../../hooks/useToast'

const SelectOption: FC<any> = ({
  option,
  selected,
  onItemSelected,
  subItem,
  readonly,
  type,
}) => {
  const isListType = type === 'list'
  const theme: any = useTheme()
  return (
    <Box
      bg={
        selected.has(option.value)
          ? theme.palette.primary['400']
          : isListType
            ? '#f0f0f0'
            : '#fff'
      }
      // boxShadow='0 2px 10px rgba(0,0,0,.1)'
      mb={10}
      borderRadius={100}
      key={option.value}
      cursor={readonly ? 'normal' : 'pointer'}
      userSelect="none"
      _active={
        isListType ? {} : { backgroundColor: theme.palette.primary['600'] }
      }
      onClick={() => (readonly ? null : onItemSelected(option.value))}
    >
      <Box style={{ padding: subItem ? 12 : 20 }}>
        <Text
          color={selected.has(option.value) ? '#fff' : '#000'}
          textAlign="center"
        >
          {option?.label}
        </Text>
      </Box>
    </Box>
  )
}

const SelectionQuestion: FC<any> = ({
  handleBack,
  isLoading,
  readonly = false,
  initiallySelected = [],
  progress,
  answer,
  question,
  onSubmit,
  type = undefined,
}) => {
  const [selected, setSelected] = useState(
    answer && answer.length > 0 ? new Set(answer) : new Set(initiallySelected),
  )
  const dispatch = useDispatch()
  const { toast, showToast, hideToast } = useToast()
  const minSelection = question.minSelectionLimit
  const maxSelection = question.maxSelectionLimit
  const [expanded, setExpanded] = useState(
    readonly
      ? new Set(question.options.map((option: any) => option.value))
      : new Set(question.options.length > 0 ? [question.options[0].value] : []),
  )
  const { t } = useTranslation()
  const theme: any = useTheme()
  const [otherValue, setOtherValue] = useState('')

  const user = useAppSelector((state) => state.assessment.user)
  const radio = question.type === 'select'
  const showOtherOption = useMemo(() => {
    return (
      question.hasOtherText &&
      selected.size === 1 &&
      (_.last(question.options) as any).value === selected.values().next().value
    )
  }, [selected, question])

  const onItemSelected = (item: any) => {
    console.log('onItemSelected, selected(before):', selected)
    if (readonly) {
      return
    }

    const answers = new Set(selected)
    if (radio) {
      answers.clear()
      answers.add(item)
    } else {
      if (selected.has(item)) {
        answers.delete(item)
      } else {
        answers.add(item)
      }
    }
    setSelected(answers)

    console.log('onItemSelected, selected(after):', answers)
  }

  const onLocalSubmit = () => {
    if (radio && selected.size === 0) {
      showToast(t('select_option'), 'error')
    } else if (selected.size < minSelection || selected.size > maxSelection) {
      console.log('onLocalSubmit, selected:', selected)
      const errorMessage =
        minSelection === maxSelection
          ? t('select_x_items', { count: minSelection })
          : t('select_between_x_y_items', {
              min: minSelection,
              max: maxSelection,
            })
      showToast(errorMessage, 'error')
    } else {
      dispatch(
        itemSelect({
          questionId: question.friendlyID,
          answers: Array.from(selected),
          otherAnswer: showOtherOption ? otherValue : null,
        }),
      )

      onSubmit()
    }
  }

  return (
    <AssessmentItemWrapper>
      <Toast
        open={toast.open}
        message={toast.message}
        severity={toast.severity}
        onClose={hideToast}
      />
      <AssessmentProgress onBack={handleBack} percent={progress} />
      <AssessmentQuestion
        progress={progress}
        onButtonClick={onLocalSubmit}
        title={question.title.replaceAll('{{username}}', user)}
        subTitle={question.description?.replaceAll('{{username}}', user)}
        isLoading={isLoading}
        buttonText={t('continue')}
      >
        <AnimatePresence key={question.friendlyID}>
          <motion.div
            initial={{ opacity: 0, y: -200 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <Stack>
              <Box>
                {question.options.map((option: any) => {
                  if (option.children?.length > 0) {
                    const groupExpanded = expanded.has(option.value)
                    const onPress = () => {
                      const newExpanded = new Set(expanded)
                      if (groupExpanded) {
                        newExpanded.delete(option.value)
                      } else {
                        newExpanded.add(option.value)
                      }
                      setExpanded(newExpanded)
                    }

                    const selectedCount = option.children.filter((item: any) =>
                      selected.has(item.value),
                    ).length

                    return (
                      <Box
                        key={option.value}
                        bg="#eef"
                        borderRadius="10px"
                        mb={24}
                        py={10}
                        px="2rem"
                      >
                        <Flex
                          mb={5}
                          justifyContent="center"
                          gridColumnGap={5}
                          alignItems="center"
                          onClick={onPress}
                          cursor="pointer"
                          // _hover={{ backgroundColor: '#eee' }}
                          _active={{ backgroundColor: '#ddd' }}
                          py={3}
                          borderRadius="10px"
                          userSelect="none"
                        >
                          {!readonly && selectedCount > 0 && (
                            <Box
                              w={16}
                              h={16}
                              borderRadius={100}
                              alignItems="center"
                              display={'flex'}
                              flexShrink={0}
                              justifyContent={'center'}
                              backgroundColor={theme.palette.primary['500']}
                            >
                              <Text
                                fontSize="small"
                                color="#fff"
                                fontWeight={'700'}
                              >
                                {selectedCount}
                              </Text>
                            </Box>
                          )}
                          <VStack>
                            <Text
                              fontSize="xl"
                              fontWeight="600"
                              textAlign={'center'}
                            >
                              {option?.label}
                            </Text>
                            {option.description && (
                              <Text
                                fontSize="md"
                                fontWeight="300"
                                textAlign={'center'}
                              >
                                {option.description}
                              </Text>
                            )}
                          </VStack>
                          <Box
                            style={{
                              transform: groupExpanded
                                ? 'rotate(90deg)'
                                : 'none',
                            }}
                          >
                            <BiChevronRight size={30} />
                          </Box>
                        </Flex>
                        {groupExpanded
                          ? option.children.map((childItem: any) => (
                              <SelectOption
                                subItem
                                readonly={readonly}
                                key={childItem.value}
                                option={childItem}
                                selected={selected}
                                onItemSelected={onItemSelected}
                                type={type}
                              />
                            ))
                          : null}
                        <Divider mb={2} />
                      </Box>
                    )
                  } else {
                    return (
                      <SelectOption
                        readonly={readonly}
                        key={option.value}
                        option={option}
                        selected={selected}
                        onItemSelected={onItemSelected}
                        type={type}
                      />
                    )
                  }
                })}
                {showOtherOption && (
                  <Textarea
                    mr={30}
                    value={otherValue}
                    onChange={(event) => setOtherValue(event.target.value)}
                    placeholder={question.otherTextPlaceholder}
                    height={200}
                  />
                )}
              </Box>
            </Stack>
          </motion.div>
        </AnimatePresence>
      </AssessmentQuestion>
    </AssessmentItemWrapper>
  )
}

export default SelectionQuestion
