import { styled } from '@mui/material'

export const DashboardCoachWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '24px',
  width: '100%',
  justifyContent: 'space-between',
  padding: '12px 0',
  button: {
    font: '500 12px Poppins, sans-serif',
    background: 'transparent',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    '&:disabled': {
      color: 'var(--bold-orange-40)',
    },
  },
}))

export const DashboardCoachLeft = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '12px',
  img: {
    width: '80px',
    height: '80px',
    borderRadius: '160px',
  },
  div: {
    // display: "grid",
    gap: '3px',
    h4: {
      font: '600 16px Poppins, sans-serif',
      lineHeight: '18px',
    },
    p: {
      font: '500 12px Poppins, sans-serif',
      lineHeight: '14px',
      color: theme.palette.grey[500],
    },
  },
}))
