import { IconButton, Menu, TextareaAutosize } from '@mui/material'
import { ChatEditorWrapper } from './chat-editor.style'
import { AttachFile, Delete, EmojiEmotions, Send } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import Hotkeys from 'react-hot-keys'
import { KeyboardEvent, useRef, useState } from 'react'
import EmojiPicker, { EmojiStyle, SuggestionMode } from 'emoji-picker-react'

interface Props {
  onSend: (message: { text: string; file?: File }) => void
}

export function ChatEditor(props: Props) {
  const { t } = useTranslation()
  const [message, setMessage] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)
  const [file, setFile] = useState<File | null>(null)
  const [emojiAnchor, setEmojiAnchor] = useState<HTMLButtonElement | null>(null)

  const onSend = () => {
    if (file) {
      props.onSend({ text: message || file.name, file })
      setFile(null)
    } else {
      props.onSend({ text: message })
    }
    setMessage('')
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
      onSend()
    }
  }

  return (
    <>
      <ChatEditorWrapper>
        <div>
          <input
            type="file"
            className="hidden"
            ref={inputRef}
            onChange={(event) =>
              setFile(
                event.target.files && event.target.files?.length > 0
                  ? event.target.files[0]
                  : null,
              )
            }
          />

          <IconButton onClick={(event) => setEmojiAnchor(event.currentTarget)}>
            <EmojiEmotions />
          </IconButton>
          <IconButton onClick={() => inputRef.current?.click()}>
            <AttachFile />
          </IconButton>
          {file && (
            <IconButton onClick={() => setFile(null)}>
              <Delete />
            </IconButton>
          )}
        </div>
        <Hotkeys keyName="ctrl+enter">
          <TextareaAutosize
            maxRows={3}
            placeholder={t('typeMessage')}
            value={message || (file ? file.name : '')}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            disabled={!!file}
          />
        </Hotkeys>
        <IconButton color="primary" onClick={onSend}>
          <Send />
        </IconButton>
      </ChatEditorWrapper>
      <Menu
        open={!!emojiAnchor}
        anchorEl={emojiAnchor}
        onClose={() => setEmojiAnchor(null)}
        MenuListProps={{
          sx: {
            padding: 0,
          },
        }}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
      >
        <EmojiPicker
          suggestedEmojisMode={SuggestionMode.FREQUENT}
          onEmojiClick={(emoji) => {
            setMessage(`${message}${emoji.emoji}`)
          }}
          searchDisabled
          previewConfig={{
            showPreview: false,
          }}
        />
      </Menu>
    </>
  )
}
