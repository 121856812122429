import { Stack, Tab, Tabs, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { ProfileTab } from './ProfileTab'
import { AnimatePresence, motion } from 'framer-motion'
import { CalendarConnection } from './calendar'

export function Settings() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()
  const activeTab = params.tabId

  return (
    <Stack gap="24px" bgcolor="#F7F8F9">
      <Stack
        sx={(theme) => ({
          borderBottom: `1px solid ${theme.palette.divider}`,
        })}
      >
        <Tabs
          value={activeTab === 'new-calendar' ? 'calendars' : activeTab}
          onChange={(_event, value) => navigate('/settings/' + value)}
        >
          <Tab value="profile" label={t('profile')} />
          <Tab
            value={'calendars'}
            onClick={() =>
              activeTab === 'new-calendar'
                ? navigate('/settings/calendars')
                : {}
            }
            label={t('calendars')}
          />
        </Tabs>
      </Stack>
      <Box>
        <AnimatePresence key={activeTab}>
          <motion.div
            initial={{
              y: -50,
              opacity: 0,
            }}
            animate={{
              y: 0,
              opacity: 1,
            }}
          >
            {activeTab === 'profile' ? <ProfileTab /> : <CalendarConnection />}
          </motion.div>
        </AnimatePresence>
      </Box>
    </Stack>
  )
}
