import { styled } from '@mui/material'

export const PageTitle = styled('h1')(({ theme }) => ({
  font: '700 24px Poppins, sans-serif',
  margin: 0,
  [theme.breakpoints.down('lg')]: {
    fontSize: '18px',
  },
}))

export const SearchItem = styled('div')(({ theme }) => ({
  position: 'sticky',
  background: theme.palette.background.paper,
  padding: '10px 12px',
  top: 0,
  zIndex: 1,
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  marginBottom: '10px',
  div: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    padding: '6px 12px',
    background: theme.palette.grey[100],
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '12px',
    alignItems: 'center',
    color: theme.palette.grey[400],
    input: {
      background: 'transparent',
      border: 'none',
      outline: 'none !important',
      padding: '3px',
      font: '500 14px Poppins, sans-serif',
      '::placeholder': {
        color: theme.palette.grey[400],
      },
    },
  },
}))

export const SearchLoading = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '150px',
}))

export const LoadingOverlayWrapper = styled('div')(() => ({
  position: 'relative',
  '.loadingContent': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))
