import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { envVars } from '../../../constants/envVars'
import { PostSessionUserFeedbackRequest } from '../../../interfaces/main-interfaces'

export const meetingService = createApi({
  reducerPath: 'meetingApi',
  baseQuery: fetchBaseQuery({
    baseUrl: envVars.BASE_URL,
    //   prepareHeaders: (headers, api) => {
    //     const store = api.getState() as RootState;
    //     if (store.auth.user) {
    //       headers.set('Authorization', `Bearer ${store.auth.user.token}`);
    //     }
    //     return headers;
    //   },
  }),
  endpoints: (builder) => ({
    // getResources: builder.query<Resource[], void>({
    //   query: () => 'user/resources',
    //   transformResponse: (response: { data: Resource[] }) => response.data,
    // }),
    getMeetingInfo: builder.query({
      query: ({ sessionId, participantId }) => ({
        url: `/meeting/`,
        method: 'GET',
        params: { sessionId, participantId },
      }),
    }),
    postSessionUserFeedback: builder.mutation<
      any,
      PostSessionUserFeedbackRequest
    >({
      query: (body) => ({
        url: '/feedback/user',
        method: 'POST',
        body,
      }),
    }),
  }),
})

// Hooks generated by the library service
export const { useGetMeetingInfoQuery, usePostSessionUserFeedbackMutation } =
  meetingService
