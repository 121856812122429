import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import Cookies from 'js-cookie'
import { SELECTED_GROUP_COOKIE_KEY } from '../../../constants'
import { envVars } from '../../../constants/envVars'
import { HighlightSummary, Resource } from '../../../interfaces/main-interfaces'
import { RootState } from '../store.config'

export const libraryService = createApi({
  reducerPath: 'libraryApi',
  baseQuery: fetchBaseQuery({
    baseUrl: envVars.BASE_URL,
    prepareHeaders: (headers, api) => {
      const store = api.getState() as RootState
      if (store.auth.user) {
        headers.set('Authorization', `Bearer ${store.auth.user.token}`)
      }
      const groupId = Cookies.get(SELECTED_GROUP_COOKIE_KEY)
      if (groupId) {
        headers.set('groupid', groupId)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    getResources: builder.query<Resource[], void>({
      query: () => 'user/resources',
      transformResponse: (response: { data: Resource[] }) => response.data,
    }),
    getResourceRecommendations: builder.query<any, void>({
      query: () => 'user/resources/recommendations',
      transformResponse: (response: { data: any }) => response.data,
    }),
    pinResource: builder.mutation<void, string>({
      query: (resourceId) => ({
        url: `user/resources/${resourceId}/pin`,
        method: 'POST',
      }),
    }),
    unpinResource: builder.mutation<void, string>({
      query: (resourceId) => ({
        url: `user/resources/${resourceId}/unpin`,
        method: 'POST',
      }),
    }),
    getHighlights: builder.query<
      {
        sections: string[]
        summaries: HighlightSummary[]
      },
      void
    >({
      query: () => ({
        url: `user/notes/session-summaries`,
      }),
    }),
  }),
})

// Hooks generated by the library service
export const {
  useGetResourcesQuery,
  useGetResourceRecommendationsQuery,
  usePinResourceMutation,
  useUnpinResourceMutation,
  useGetHighlightsQuery,
} = libraryService
