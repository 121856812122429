import { styled } from '@mui/material'

export const TimezoneBody = styled('div')(() => ({
  padding: '16px',
  display: 'grid',
  gap: '24px',
  '.timezoneTop': {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    gap: '16px',
  },
  '.timezoneMessage': {
    display: 'grid',
    gap: '4px',

    h4: {
      font: '600 16px Poppins, sans-serif',
    },
    p: {
      font: '300 14px Poppins, sans-serif',
      display: 'flex',
      flexWrap: 'wrap',
      gap: '3px',
      rowGap: 0,
      lineHeight: '24px',
      b: {
        fontWeight: '500',
      },
    },
  },
  '.timezoneForm': {
    display: 'grid',
    gap: '20px',

    '.timezoneFooter': {
      display: 'grid',
      gap: '12px',
      gridTemplateColumns: 'auto 1fr',
    },
  },
}))
