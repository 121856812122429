import { useNavigate, useParams } from 'react-router-dom'
import {
  ChLUserLeft,
  ChLUserUnreadMsgs,
  ChatListUserWrapper,
} from './chat-list-user.style'
import { Avatar } from '@mui/material'
import { stringToColor } from '../../../../lib/utils/stringToColor'
import { Group, School } from '@mui/icons-material'

export interface ChatListUserProps {
  avatar?: string
  title: string
  unreadMessagesCount: number
  lastMessage: string
  id: string
  type: 'group' | 'single'
  withCoach: boolean
}

export function ChatListUser({
  id,
  avatar,
  lastMessage,
  title,
  type,
  withCoach,
  unreadMessagesCount,
}: ChatListUserProps) {
  const navigate = useNavigate()
  const { id: chatId } = useParams()

  return (
    <ChatListUserWrapper
      className={chatId === id ? 'active' : ''}
      onClick={() => navigate(`/group-chat/${id}`)}
    >
      <ChLUserLeft>
        {avatar ? (
          <Avatar
            sx={{ width: '50px', height: '50px' }}
            src={avatar}
            alt={title}
          />
        ) : (
          <Avatar
            sx={{
              background: stringToColor(title),
              width: '50px',
              height: '50px',
            }}
          >
            {title
              ?.split(' ')
              .map((part) => part[0]?.toUpperCase())
              .slice(0, 2)
              .join('')}
          </Avatar>
        )}
        <div className="textContent">
          <h4>
            {type === 'group' && <Group fontSize="inherit" />}{' '}
            {withCoach && <School fontSize="inherit" />}{' '}
            {title.length > 33 ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: title.split('<>').join('<><br/>'),
                }}
              />
            ) : (
              title
            )}
          </h4>
          <p>{lastMessage}</p>
        </div>
      </ChLUserLeft>
      {unreadMessagesCount > 0 && (
        <ChLUserUnreadMsgs>
          {unreadMessagesCount < 100 ? unreadMessagesCount : '99+'}
        </ChLUserUnreadMsgs>
      )}
    </ChatListUserWrapper>
  )
}
