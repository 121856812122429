import { PropsWithChildren } from 'react'
import { ChatUtilsContext } from './ChatUtilsContext'
import { useChatUtilsStore } from './useChatUtils'

export function ChatUtilsProvider(props: PropsWithChildren) {
  const chatUtils = useChatUtilsStore()

  return (
    <ChatUtilsContext.Provider value={chatUtils}>
      {props.children}
    </ChatUtilsContext.Provider>
  )
}
