import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  socketId: undefined,
}

const socketSlice = createSlice({
  name: 'sockets',
  initialState: initialState,
  reducers: {
    updateSocket: (state, { payload }) => {
      console.log('updateSocket reducer', payload)
      state.socketId = payload
    },
  },
})

export default socketSlice

export const { updateSocket } = socketSlice.actions
