import { Star, StarBorder } from '@mui/icons-material'
import { Button, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Resource } from '../../interfaces/main-interfaces'
import { ResourceCardWrapper } from './resource.style'

export function ResourceCard({
  data,
  loading,
  currentResource,
  onToggle,
  reason,
  chapters,
}: {
  data: Resource
  loading: boolean
  currentResource: string | null
  onToggle: (resourceId: any, isPinned: any) => Promise<void>
  reason?: string
  chapters?: string
}) {
  const { t } = useTranslation()

  const paidLabel = data.paid ? t('paid') : t('included')
  console.log('ResourceCard -> data', data)

  return (
    <ResourceCardWrapper className={data.type === 'internal' ? 'internal' : ''}>
      <div
        className={data.paid ? 'resourceStatusPaid' : 'resourceStatusIncluded'}
      >
        <span>{paidLabel}</span>
      </div>
      <div className="resourceBody">
        <div className="resourceContent">
          <div className="resourceTop">
            <h3 className="lineClamp" style={{ '--line-count': 2 } as any}>
              {data.name}
            </h3>
            <p>{data.author}</p>
          </div>

          <div className="resourceBottom">
            <span className="badge">{t(data.type)}</span>
            {!reason && (
              <>
                <p
                  className="summary lineClamp"
                  style={{ '--line-count': 3 } as any}
                >
                  {data.summary}
                </p>
                <p className="category">
                  {data.tags ? data.tags.join(' • ') : ''}
                </p>
              </>
            )}
          </div>

          {reason && (
            <div className="resourceBottom">
              <p
                className="summary lineClamp"
                style={{ 'font-weight': 'bold' } as any}
              >
                {t('recommendationReason')}
              </p>
              <p className="summary lineClamp">{reason}</p>
            </div>
          )}
          {chapters && (
            <div className="resourceBottom">
              <p
                className="summary lineClamp"
                style={{ 'font-weight': 'bold' } as any}
              >
                {t('recommendationChapters')}
              </p>
              <p
                className="summary lineClamp"
                style={{ '--line-count': 3 } as any}
              >
                {chapters}
              </p>
            </div>
          )}
        </div>

        <div
          className="resourceFooter"
          style={{ gridTemplateColumns: reason ? '1fr' : '1fr 1fr' }}
        >
          <Button href={data.link} target="_blank" variant="contained">
            {t('view')}
          </Button>
          {!reason && (
            <Button
              disabled={currentResource === data._id && loading}
              onClick={
                onToggle ? () => onToggle(data._id, data.pinned) : () => {}
              }
              startIcon={
                currentResource === data._id && loading ? (
                  <CircularProgress color="inherit" size="14px" />
                ) : data.pinned ? (
                  <Star />
                ) : (
                  <StarBorder />
                )
              }
              variant={data.pinned ? 'soft' : 'outlined'}
            >
              {t(data.pinned ? 'unpin' : 'pin')}
            </Button>
          )}
        </div>
      </div>
    </ResourceCardWrapper>
  )
}
