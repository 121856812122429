import { styled } from '@mui/material'

export const AQWrapper = styled('div')(({ theme }) => ({
  paddingTop: '24px',
  overflowY: 'auto',
  display: 'grid',
  gridTemplateRows: '1fr auto',
  background: 'rgba(255,255,255,0.95)',
  gap: '24px',
  '.aqContent': {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    gap: '24px',
    justifyContent: 'center',
    '.aqBody, .aqHeader': {
      width: '500px',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: '0 24px',
      },
    },
  },
  '.aqHeader': {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    h3: {
      font: '700 28px Poppins,sans-serif',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        font: '600 24px Poppins,sans-serif',
        lineHeight: '28px',
      },
    },
    p: {
      font: '400 16px Poppins,sans-serif',
      textAlign: 'center',
      display: 'grid',
      gap: '3px',
      '&.italic': {
        fontStyle: 'italic',
      },
      [theme.breakpoints.down('md')]: {
        font: '400 12px Poppins,sans-serif',
      },
    },
  },
  '.aqBody': {
    overflowY: 'auto',
  },
  '.aqFooter': {
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: '12px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    background: theme.palette.background.paper,
    position: 'sticky',
    bottom: 0,
    [theme.breakpoints.down('md')]: {
      width: '12px 24px',
    },
    '.cta': {
      height: '50px',
      font: '600 18px Poppins, sans-serif',
      width: '500px',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        height: '40px',
        font: '500 14px Poppins, sans-serif',
      },
    },
  },
}))
