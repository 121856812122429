import { useTranslation } from 'react-i18next'
import { ratingScale } from '../../../utils/ratings'
import { QuestionTitle } from './components/QuestionHeader'
import { Animated } from './components/Animated'
import { ValueScoreList } from './components/question-types.style'
import { QuestionProps } from '../components/PeerAssessmentArea'

export function RatingQuestion({
  data,
  user,
  index,
  answers,
  setAnswers,
}: QuestionProps) {
  const { t } = useTranslation()
  const options = ratingScale(t)

  return (
    <>
      <QuestionTitle data={data} user={user} />
      <Animated key={data.friendlyID}>
        <ValueScoreList>
          {options.map((item) => {
            return (
              <div
                className={
                  answers.ratings[data.friendlyID] === item.scale
                    ? 'active'
                    : ''
                }
                onClick={() =>
                  setAnswers({
                    ...answers,
                    ratings: {
                      ...answers.ratings,
                      [data.friendlyID]: item.scale,
                    },
                  })
                }
                key={item.level}
              >
                {item.level}
              </div>
            )
          })}
        </ValueScoreList>
      </Animated>
    </>
  )
}
