import {
  DyteEmojiPicker,
  DyteMeeting,
  generateConfig,
  provideDyteDesignSystem,
  registerAddons,
} from '@dytesdk/react-ui-kit'
import { useDyteSelector } from '@dytesdk/react-web-core'
import { useEffect, useRef, useState } from 'react'

import { Box } from '@chakra-ui/react'
import CustomButton from '@dytesdk/ui-kit-addons/custom-controlbar-button'
import { Close } from '@mui/icons-material'
import { Fade, Modal, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { AlternativeLinkFromMenu } from './AlternativeLinkMenu'
import PostSessionUserFeedback from './PostSessionUserFeedback'
import MeetingEnded from './MeetingEnded'

export default function GroupFacetime({
  meeting,
  isCoach,
  sessionId,
  coachName,
  userId,
  roomName,
}: any) {
  const { t } = useTranslation()

  const [displayEmojiPicker, setDisplayEmojiPicker] = useState(false)
  const [currentEmojiReactions, setCurrentEmojiReactions] = useState<any>({})
  const [config, setConfig] = useState<any>(undefined)
  const [isMeetingEnded, setIsMeetingEnded] = useState(false)

  const participants = useDyteSelector((m) => m.participants.joined)
  const roomJoined = useDyteSelector((m) => m.self.roomJoined)
  const timeoutMap: any = useRef({})

  useEffect(() => {
    provideDyteDesignSystem(document.body, {
      colors: {
        'video-bg': '#333333',
      },
    })
  }, [])

  const handleMeetingEnded = () => {
    setIsMeetingEnded(true)
  }

  useEffect(() => {
    if (meeting) {
      meeting.self.on('roomLeft', handleMeetingEnded)
    }
    return () => {
      if (meeting) {
        meeting.self.on('roomLeft', handleMeetingEnded)
      }
    }
  }, [meeting])

  useEffect(() => {
    if (!roomJoined) return

    const timer = setTimeout(() => {
      toast.message(t('ai_transcription_in_progress'), {
        duration: 5000,
        position: 'bottom-center',
      })
    }, 3000) // 3 seconds
    return () => clearTimeout(timer)
  }, [t, roomJoined])

  useEffect(() => {
    if (!meeting) return

    const { config } = generateConfig(meeting.self.config, meeting)

    const startIcon = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="512px" height="512px" viewBox="0 0 512 512" enable-background="new 0 0 512 512" xml:space="preserve" fill="#ffffff">
<path d="M256,64c105.875,0,192,86.125,192,192s-86.125,192-192,192S64,361.875,64,256S150.125,64,256,64 M256,32
	C132.281,32,32,132.281,32,256s100.281,224,224,224s224-100.281,224-224S379.719,32,256,32L256,32z M192,144
	c-17.688,0-32,21.5-32,48c0,26.531,14.313,48,32,48s32-21.469,32-48C224,165.5,209.688,144,192,144z M320,144
	c-17.688,0-32,21.5-32,48c0,26.531,14.313,48,32,48s32-21.469,32-48C352,165.5,337.688,144,320,144z M399.375,292.469
	c2.469-8.469-2.406-17.344-10.906-19.844c-8.469-2.531-17.344,2.438-19.844,10.875C354.125,333.25,307.813,368,256,368
	s-98.125-34.75-112.641-84.5c-2.469-8.438-11.25-13.406-19.844-10.875c-8.484,2.5-13.344,11.375-10.875,19.844
	C131.109,355.781,190.063,400,256,400S380.875,355.781,399.375,292.469z"/>
</svg>`
    const customButton = new CustomButton({
      label: 'Emoji',
      onClick: () => setDisplayEmojiPicker(true),
      icon: startIcon,
      position: 'left',
    }) as any

    const newConfig = registerAddons([customButton], meeting, config)
    setConfig(newConfig)
  }, [meeting])

  const handleEmojiPick = (emoji: any) => {
    setDisplayEmojiPicker(false)
    meeting.participants.broadcastMessage('emoji', {
      emojiType: emoji.detail,
      self: meeting.self,
    } as any)
  }
  meeting.participants.on('broadcastedMessage', ({ payload, ...rest }: any) => {
    if (payload.emojiType) {
      setCurrentEmojiReactions((prevReactions: any) => {
        const newReactions = { ...prevReactions }
        newReactions[payload?.self?.id] = {
          emoji: payload.emojiType,
          name: payload?.self?.name,
        }
        return newReactions
      })

      if (timeoutMap.current[payload?.self?.id]) {
        clearTimeout(timeoutMap.current[payload?.self?.id])
      }

      timeoutMap.current[payload?.self?.id] = setTimeout(() => {
        setCurrentEmojiReactions((prevReactions: any) => {
          const newReactions = { ...prevReactions }
          delete newReactions[payload?.self?.id]
          return newReactions
        })
        delete timeoutMap.current[payload?.self?.id]
      }, 3000)
    }
  })

  if (!roomJoined && isMeetingEnded) {
    if (isCoach) {
      return <MeetingEnded sessionId={sessionId} roomName={roomName} />
    }
    return (
      <PostSessionUserFeedback
        coachName={coachName}
        sessionId={sessionId}
        userId={userId}
      />
    )
  }

  const shouldShowAlternativeLinkMenu = participants.size > 0 && roomJoined

  return (
    <div className="h-full w-full flex flex-col bg-[#141414] text-white overflow-hidden">
      <div>
        {shouldShowAlternativeLinkMenu && (
          <AlternativeLinkFromMenu
            sessionId={sessionId}
            participantId={meeting.self.id}
            isCoach={isCoach}
          />
        )}
      </div>
      <DyteMeeting meeting={meeting} config={config} mode="fill" />
      <Modal
        open={displayEmojiPicker}
        onClose={() => setDisplayEmojiPicker(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '20px',
        }}
      >
        <Box overflow="hidden" pt={4} bgColor="#1E1E1E">
          <Box
            position="relative"
            right={4}
            top={4}
            display="flex"
            justifyContent="right"
            mb="-24px"
            style={{ cursor: 'pointer' }}
          >
            <Close
              sx={{ color: '#FFF' }}
              onClick={() => setDisplayEmojiPicker(false)}
            />
          </Box>
          <Box
            style={{
              width: 400,
            }}
          >
            <DyteEmojiPicker
              style={{ zIndex: 100000 }}
              onDyteEmojiClicked={(e) => handleEmojiPick(e)}
            />
          </Box>
        </Box>
      </Modal>
      {Object.entries(currentEmojiReactions || {}).map(([id, emoji]: any) => (
        <Fade in>
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: '20%',
              transform: 'translateX(-50%)',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              animation: 'reaction-animation 3s ease-in-out forwards',
              '@keyframes reaction-animation': {
                '0%': {
                  transform: 'translateY(-100%)',
                  opacity: 1,
                },
                '100%': {
                  transform: 'translateY(-600%)',
                  opacity: 1,
                },
              },
            }}
          >
            <Typography variant="h4">{emoji.emoji}</Typography>
            <Typography
              variant="body1"
              sx={{
                mt: '4px',
                backgroundColor: '#888',
                borderRadius: 8,
                padding: '2px 8px',
                fontSize: 12,
              }}
            >
              {id === meeting.self.id ? 'You' : emoji.name}
            </Typography>
          </Box>
        </Fade>
      ))}
    </div>
  )
}
