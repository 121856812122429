import { styled } from '@mui/material'

export const ChatboxWrapper = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'grid',
  gridTemplateRows: 'auto 1fr auto',
  overflow: 'hidden',
  position: 'relative',
  '&.empty,&.loading': {
    gridTemplateRows: '1fr',
  },
  '.loading': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(255,255,255,.4)',
  },
  [theme.breakpoints.down('md')]: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    background: theme.palette.background.default,
    margin: 0,
    height: 'calc(100dvh - 70px)',
    maxHeight: 'calc(100dvh - 85px)',
  },
}))

export const ChatEmptyWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: '12px',

  h4: {
    font: '600 18px Poppins, sans-serif',
  },
  p: {
    font: '400 14px Poppins, sans-serif',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

export const ChatMessagesList = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: '16px',
  padding: '12px',
  alignItems: 'flex-start',
  alignContent: 'flex-start',
  overflowY: 'auto',
  background: theme.palette.grey[100],
  [theme.breakpoints.down('md')]: {
    padding: '12px',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '12px',
  },
}))
