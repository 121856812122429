import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import Cookies from 'js-cookie'
import { SELECTED_GROUP_COOKIE_KEY } from '../../../constants'
import { envVars } from '../../../constants/envVars'
import { PeerAssessment } from '../../../interfaces/main-interfaces'
import { GetAssessmentQuestionsRequest } from '../../../types/api'
import { RootState } from '../store.config'
import {
  AssessmentQuestion,
  AssessmentStatus,
  AssessmentValue,
} from '../../../interfaces/main-interfaces'
import { TAG } from './manager.service'
import { GroupMemberAssessmentStatus } from '../../../lib/widgets/group-members/GroupMembers'

export const ASSESSMENT_TAG = {
  ASSESSMENT: 'ASSESSMENT',
  PEER_ASSESSMENT: 'PEER_ASSESSMENT',
}

export const assessmentService = createApi({
  reducerPath: 'assessmentApi',
  baseQuery: fetchBaseQuery({
    baseUrl: envVars.BASE_URL_V1,
    prepareHeaders: (headers, api) => {
      const store = api.getState() as RootState
      if (store.auth.user) {
        headers.set('Authorization', `Bearer ${store.auth.user.token}`)
      }
      const groupId = Cookies.get(SELECTED_GROUP_COOKIE_KEY)
      if (groupId) {
        headers.set('groupid', groupId)
      }
      return headers
    },
  }),
  tagTypes: [ASSESSMENT_TAG.ASSESSMENT, ASSESSMENT_TAG.PEER_ASSESSMENT],
  endpoints: (builder) => ({
    getAssessmentStatus: builder.query<AssessmentStatus, void>({
      query: () => ({
        url: 'v2/user/assessment-status',
      }),
      providesTags: [ASSESSMENT_TAG.ASSESSMENT],
    }),

    getAssessmentQuestionsForUser: builder.query<
      { questions: AssessmentQuestion[]; values: AssessmentValue[] },
      void
    >({
      query: () => ({
        url: 'user/assessment',
      }),
    }),

    postAssessmentQuestions: builder.mutation<any, any>({
      query: (body) => ({
        url: 'user/assessment',
        body,
        method: 'POST',
      }),
      invalidatesTags: [ASSESSMENT_TAG.ASSESSMENT],
    }),

    getAssessmentValueScores: builder.query<
      {
        scores: { self: Record<string, number>; team: Record<string, number> }
        values: Record<string, string>
        answers: any[]
      },
      void
    >({
      query: () => ({
        url: 'user/assessment/value-scores',
        method: 'GET',
      }),
      providesTags: [ASSESSMENT_TAG.ASSESSMENT],
    }),

    getHighlightedAreas: builder.query({
      query: (params) => ({
        url: 'user/assessment/highlighted-areas',
        params,
      }),
      providesTags: [ASSESSMENT_TAG.ASSESSMENT],
    }),

    getAssessmentAnswers: builder.query<any, void>({
      query: () => ({
        url: 'user/assessment/answers',
      }),
      providesTags: [ASSESSMENT_TAG.ASSESSMENT],
    }),

    getPeerAssessment: builder.query<PeerAssessment, Record<string, string>>({
      query: (params) => ({
        params,
        url: '/v2/peer/assessment/questions',
        method: 'GET',
      }),
      providesTags: [ASSESSMENT_TAG.PEER_ASSESSMENT],
    }),
    postPeerAssessment: builder.mutation<any, any>({
      query: (body) => ({
        body,
        url: '/v2/peer/assessment',
        method: 'POST',
      }),
      invalidatesTags: [ASSESSMENT_TAG.PEER_ASSESSMENT],
    }),
    savePeerAssessmentAnswer: builder.mutation<any, any>({
      query: (body) => ({
        body,
        url: '/v2/peer/assessment/answer',
        method: 'POST',
      }),
      invalidatesTags: [ASSESSMENT_TAG.PEER_ASSESSMENT],
    }),
    getLeadershipValueScores: builder.query<any, any>({
      query: () => ({
        url: '/v2/user/assessment/leadership-value-scores',
        method: 'GET',
      }),
      providesTags: [ASSESSMENT_TAG.PEER_ASSESSMENT],
    }),
    getLeadershipValueScoresForHR: builder.query<any, any>({
      query: (userId) => ({
        url: '/v2/user/assessment/leadership-value-scores-hr',
        method: 'GET',
      }),
      providesTags: [ASSESSMENT_TAG.PEER_ASSESSMENT],
    }),
    getAssessmentQuestions: builder.query<any, GetAssessmentQuestionsRequest>({
      query: (params: GetAssessmentQuestionsRequest) => ({
        url: '/assessment/questions',
        method: 'GET',
        params,
      }),
    }),
    saveAssessmentAnswer: builder.mutation({
      query: (body) => ({
        url: '/assessment/answer',
        method: 'POST',
        body,
      }),
      invalidatesTags: [ASSESSMENT_TAG.ASSESSMENT],
    }),
    getTeamAssessmentStatus: builder.query<GroupMemberAssessmentStatus[], void>(
      {
        query: () => ({
          url: '/v2/manager/group/assessment-status',
        }),
        providesTags: [ASSESSMENT_TAG.ASSESSMENT, TAG.GROUP_MEMBERS],
      },
    ),
  }),
})

export const {
  useGetPeerAssessmentQuery,
  usePostPeerAssessmentMutation,
  useSavePeerAssessmentAnswerMutation,
  useGetLeadershipValueScoresQuery,
  useGetLeadershipValueScoresForHRQuery,
  useLazyGetAssessmentQuestionsForUserQuery,
  useSaveAssessmentAnswerMutation,
  useGetAssessmentQuestionsForUserQuery,
  useLazyGetAssessmentQuestionsQuery,
  usePostAssessmentQuestionsMutation,
  useGetAssessmentStatusQuery,
  useGetAssessmentValueScoresQuery,
  useGetHighlightedAreasQuery,
  useGetAssessmentAnswersQuery,
  useGetTeamAssessmentStatusQuery,
} = assessmentService
