import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useHideChatlio } from '../../hooks/useHideChatlio'
import { ChatBox } from './chat-box/ChatBox'
import { ChatList } from './chat-list/ChatList'
import { ChatWrapper, EmptyChatBox } from './chat.style'

export function ChatPage() {
  const { id: chatId } = useParams()
  const { t } = useTranslation()
  useHideChatlio()

  return (
    <ChatWrapper>
      <ChatList />
      {chatId ? (
        <ChatBox chatId={chatId} />
      ) : (
        <EmptyChatBox>
          <img src="/chat.png" alt="Chat image" />
          <h4> {t('selectUserToChat')} </h4>
        </EmptyChatBox>
      )}
    </ChatWrapper>
  )
}
