import moment from 'moment'

export function getStartTime() {
  const now = moment()
  const nowMinutes = now.get('minutes')

  if (nowMinutes < 15) {
    return now.set('minutes', 30)
  } else {
    return now.set('hours', now.get('hours') + 1).set('minutes', 0)
  }
}
