import { Button, Container } from '@mui/material'
import moment from 'moment'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useAuth } from '../../hooks/useAuth'
import { DashboardCard } from '../../lib/shared/dashboard-card/DashboardCard'

export function DiagnosticsRequired() {
  const { t } = useTranslation()
  const { user } = useAuth()
  const navigate = useNavigate()
  const date = useMemo(
    () => ({
      start: moment(),
      end: moment().add(30, 'days'),
    }),
    [],
  )

  return (
    <DashboardCard title={t('teamDiagnostics')}>
      <p style={{ fontSize: '14px', paddingTop: '4px' }}>
        {t('completeTeamDiagnosticsBeforeViewingOverallProgressResults')}
      </p>
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          maxWidth: '100%',
        }}
      >
        <Button onClick={() => navigate('/assessment')} variant="contained">
          {t('completeTeamDiagnostics')}
        </Button>
      </Container>
    </DashboardCard>
  )
}
