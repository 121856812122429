import {
  Box,
  Checkbox,
  CheckboxGroup,
  HStack,
  Stack,
  Icon,
  ListItem,
  Radio,
  RadioGroup,
  Text,
  UnorderedList,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { AiOutlineCalendar } from 'react-icons/ai'
import { BsCalendar2Plus, BsCalendar4Week } from 'react-icons/bs'

import { Card } from './Card'
import { CalendarModal } from './Modal'
import { useTranslation } from 'react-i18next'
import { usePatchExternalCalendarMutation } from '../../../app/store/services/calendar.service'

export const CalendarConfig = ({ calendars, refetchCalendars }: any) => {
  const { t } = useTranslation()
  const calendarToCreateEvent = useMemo(
    () => calendars.find((cal: any) => cal.createEvents === true),
    [calendars],
  )
  const toast = useToast()
  const {
    isOpen: isConflictOpen,
    onOpen: onConflictOpen,
    onClose: onConflictClose,
  } = useDisclosure()
  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure()
  const [selectedCalendar, setSelectedCalendar] = useState<any>(null)
  const [calendarsToCheck, setCalendarsToCheck] = useState<any>([])
  const [selectedCalendarToCreateEvent, setSelectedCalendarToCreateEvent] =
    useState<string>(calendarToCreateEvent?.id || 'false')

  const secondaryTextColor = '#999'

  const [
    patchCalendar,
    { isSuccess: isCalendarPatchSuccess, isError: isCalendarPatchError },
  ] = usePatchExternalCalendarMutation()

  useEffect(() => {
    const checkForConflicts = (selectedCalendar?.calendarList || [])
      .filter((cal: any) => cal.checkConflicts)
      .map((cal: any) => cal.id)

    setCalendarsToCheck(checkForConflicts)
  }, [selectedCalendar])

  useEffect(() => {
    let title = ''
    let description = ''
    let status: false | 'error' | 'success' = false

    if (isCalendarPatchSuccess) {
      title = 'Success'
      description = 'Calendar updated'
      status = 'success'
      refetchCalendars()
    }

    if (isCalendarPatchError) {
      title = 'Error'
      description = 'Failed to update calendar'
      status = 'error'
    }

    if (title && description && status) {
      toast({
        title,
        description,
        status,
        duration: 2000,
        isClosable: true,
      })
    }
  }, [isCalendarPatchSuccess, isCalendarPatchError, refetchCalendars, toast])

  const openModal = (calId: string) => {
    if (calId === 'add') {
      onAddOpen()
      return
    }
    const cal = calendars.find((cal: any) => cal.id === calId)
    if (cal) {
      setSelectedCalendar(cal)
      onConflictOpen()
    }
  }

  return (
    <>
      <Card px="0">
        <Text px="3">{t('configuration')}</Text>
        <Box w="100%" h="1px" bg="gray.200" my="4" px="2.5" />

        <Stack
          justify="space-between"
          px="3"
          direction={{ base: 'column', lg: 'row' }}
          spacing={{ base: 5 }}
        >
          <HStack spacing="3" px={{ base: 3 }}>
            <Icon as={BsCalendar4Week} fontSize={24} />
            <Box>
              <Text>{t('check_for_conflicts')}</Text>
              <Text fontSize="sm" color={secondaryTextColor}>
                {t('check_for_conflicts_description')}
              </Text>
            </Box>
          </HStack>

          <VStack>
            {calendars.map((cal: any) => (
              <Item
                key={cal.id}
                title="Check"
                id={cal.externalId}
                onEdit={() => openModal(cal.id)}
              />
            ))}
          </VStack>
        </Stack>

        <Box w="100%" h="1px" bg="gray.200" my="4" px="2.5" />

        <Stack
          px="3"
          direction={{ base: 'column', lg: 'row' }}
          spacing={{ base: 5 }}
        >
          <VStack align="flex-start" spacing="5" px={{ base: 3 }}>
            <HStack spacing="3">
              <Icon as={BsCalendar2Plus} fontSize={24} />
              <Box>
                <Text>{t('add_to_calendar')}</Text>
                <Text fontSize="sm" color={secondaryTextColor}>
                  {t('add_to_calendar_description')}
                </Text>
              </Box>
            </HStack>

            {!calendarToCreateEvent && (
              <Box p="4" borderRadius="md" border="1px solid #f00">
                <Text fontSize="sm">{t('no_calendar_text')}</Text>
              </Box>
            )}
          </VStack>

          <Item
            title={t('add_to')}
            id={calendarToCreateEvent?.externalId}
            onEdit={() => openModal('add')}
          />
        </Stack>
      </Card>
      <CalendarModal
        isOpen={isConflictOpen}
        onClose={onConflictClose}
        onConfirm={async () => {
          await patchCalendar({
            calendarId: selectedCalendar?.id,
            conflictList: calendarsToCheck,
          })
          onConflictClose()
        }}
        title={t('how_check_for_conflicts')}
      >
        <HStack justify="center" mb="3">
          <Icon as={AiOutlineCalendar} fontSize={24} />
          <Text as="b">{selectedCalendar?.externalId}</Text>
        </HStack>
        <Box mb="3">
          <Text as="b">{t('check_places_for_conflicts')}</Text>
        </Box>
        <CheckboxGroup
          colorScheme="primary"
          onChange={setCalendarsToCheck}
          value={calendarsToCheck}
        >
          <VStack align="flex-start" spacing={[1, 5]}>
            {selectedCalendar?.calendarList.map((c: any) => (
              <Checkbox key={c.id} value={c.id}>
                {c.summary}
              </Checkbox>
            ))}
          </VStack>
        </CheckboxGroup>
      </CalendarModal>

      <CalendarModal
        isOpen={isAddOpen}
        onClose={() => {
          setSelectedCalendarToCreateEvent(calendarToCreateEvent?.id || 'false')
          onAddClose()
        }}
        title={t('which_calendar_add')}
        onConfirm={async () => {
          await patchCalendar({
            calendarId: selectedCalendarToCreateEvent,
            createEvents: true,
          })
          onAddClose()
        }}
      >
        <RadioGroup
          onChange={setSelectedCalendarToCreateEvent}
          value={selectedCalendarToCreateEvent}
        >
          <VStack align="flex-start">
            {calendars.map((cal: any) => (
              <Radio colorScheme="primary" key={cal.id} value={cal.id}>
                {cal.externalId}
              </Radio>
            ))}
            <Radio value="false">{t('dont_add_to_calendar')}</Radio>
          </VStack>
        </RadioGroup>

        {/* {selectedCalendarToCreateEvent !== 'false' && (
          <FormControl mt="5">
            <FormLabel htmlFor="country">{t('add_to_calendar')}</FormLabel>
            <Select defaultValue={selectedCalendarToCreateEvent} id="Calender">
              {calendars.map((cal: any) => (
                <option key={cal.id} value={cal.id}>
                  {cal.externalId}
                </option>
              ))}
            </Select>
          </FormControl>
        )} */}
      </CalendarModal>
    </>
  )
}

const Item = ({ title, id, onEdit }: any) => {
  const { t } = useTranslation()
  const secondaryTextColor = '#999'

  return (
    <HStack
      alignSelf="flex-start"
      spacing="4"
      justify="space-between"
      borderRadius="md"
      border="1px solid #eee"
      py="2"
      px="4"
      minW="sm"
      _hover={{
        cursor: 'pointer',
        borderColor: 'red',
      }}
      onClick={onEdit}
    >
      <HStack spacing="5">
        <Icon fontSize={24} as={AiOutlineCalendar} />

        <Box>
          <Text fontSize={14} color={secondaryTextColor}>
            {id ? `${title} ${id}` : t('calendar_not_selected')}
          </Text>
          {id ? (
            <UnorderedList>
              <ListItem fontSize={14}>{id}</ListItem>
            </UnorderedList>
          ) : (
            <Text fontSize={14}>{t('dont_add_to_calendar')}</Text>
          )}
        </Box>
      </HStack>
      <Text alignSelf="flex-start" fontSize={14} color="red">
        Edit
      </Text>
    </HStack>
  )
}
