import { styled } from '@mui/material'

export const ChatEditorWrapper = styled('div')(({ theme }) => ({
  padding: '12px',
  borderTop: `1px solid ${theme.palette.grey[300]}`,
  display: 'grid',
  alignItems: 'flex-end',
  gridTemplateColumns: 'auto 1fr auto',
  gap: '12px',
  textarea: {
    border: 'none',
    paddingBottom: '8px',
    font: '400 18px Poppins, sans-serif',
    outline: 'none',
    resize: 'none',
  },
  [theme.breakpoints.down('md')]: {
    padding: '6px 0 0 0',
    background: theme.palette.background.paper,
    border: 'none',
    textarea: {
      border: 'none',
      paddingBottom: '8px',
      font: '400 14px Poppins, sans-serif',
      outline: 'none',
      resize: 'none',
      background: theme.palette.background.paper,
    },
  },
}))
