/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment'
import {
  ChatBubbleContent,
  ChatBubbleMessage,
  ChatBubbleWrapper,
} from './chat-bubble.style'
import { Avatar, IconButton, Stack } from '@mui/material'
import { stringToColor } from '../../../../lib/utils/stringToColor'
import Linkify from 'react-linkify'
import { generateTwoLetterName } from '../../../../lib/utils/generateTwoLetterName'
import { useEffect, useRef } from 'react'
import { CloudDownload } from '@mui/icons-material'
import {
  bytesToKilobytes,
  bytesToMegabytes,
} from '../../../../lib/utils/converters'
import { isImageUrl } from '../../../../lib/utils/detectFile'

export interface ChatBubbleProps {
  type: 'single' | 'group'
  read: string[]
  from: {
    avatar?: string
    name: string
    id: string
    email?: string
  }
  message: {
    text: string
    image?: string
  }
  file:
    | {
        url: string
        name: string
        size: number
        type: string
      }
    | Record<string, never>
  userId: string
  chatId: string
  isMe: boolean
  id: string
  date: string
}

export interface IMarkAsRead {
  data: { userId: string; chatId: string; messageId: string }
  isMe: boolean
  read: string[]
}

export function ChatBubble({
  content,
  onMount,
}: {
  content: ChatBubbleProps
  onMount: (data: IMarkAsRead) => void
}) {
  const { from, id, message, type, date, isMe, userId, chatId, read, file } =
    content
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    onMount({
      data: {
        chatId,
        messageId: id,
        userId,
      },
      isMe,
      read,
    })
  }, [])

  return (
    <ChatBubbleWrapper
      ref={ref}
      id={`chat_bubble_${id}`}
      className={isMe ? 'mine' : ''}
    >
      <ChatBubbleContent>
        <Stack gap="6px">
          <Stack flexDirection="row" alignItems="flex-end" gap="6px">
            {!isMe &&
              type === 'group' &&
              (from.avatar ? (
                <Avatar src={from.avatar} alt={from.name} />
              ) : (
                <Avatar
                  sx={{
                    background: stringToColor(from.name),
                  }}
                >
                  {generateTwoLetterName(from.name)}
                </Avatar>
              ))}
            <ChatBubbleMessage className={isMe ? 'mine' : ''}>
              {!isMe && type === 'group' && <h4>{from.name}</h4>}
              {message.image && (
                <img
                  src={message.image}
                  alt={`chat_msg_${id.toString()}--image`}
                />
              )}

              {Object.keys(file).length > 0 && isImageUrl(file.url) && (
                <img
                  src={file.url}
                  alt={file.name}
                  onClick={() => window.open(file.url, '_blank')}
                  style={{ cursor: 'pointer' }}
                />
              )}

              {Object.keys(file).length > 0 && !isImageUrl(file.url) && (
                <div>
                  <IconButton onClick={() => window.open(file.url, '_blank')}>
                    <CloudDownload />
                  </IconButton>
                  <span className="file-name">{file?.name}</span>
                  <span
                    style={{
                      fontSize: '0.8rem',
                      color: '#666',
                    }}
                  >
                    {' '}
                    (
                    {file.size / 1000 > 1024
                      ? bytesToMegabytes(file.size)
                      : bytesToKilobytes(file.size)}{' '}
                    {file.size / 1000 > 1024 ? 'MB' : 'KB'})
                  </span>
                </div>
              )}

              <pre className="messageText">
                <Linkify
                  componentDecorator={(href, text) => (
                    <a key={href} href={href} target="_blank">
                      {text}
                    </a>
                  )}
                >
                  {message.text}
                </Linkify>
              </pre>
            </ChatBubbleMessage>
          </Stack>
          <span className="date">{moment(date).format('h:mm a')}</span>
        </Stack>
      </ChatBubbleContent>
    </ChatBubbleWrapper>
  )
}
