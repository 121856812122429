import { useTranslation } from 'react-i18next'
import { CoachDialogWrapper } from './coach-dialog.style'
import { Close } from '@mui/icons-material'
import { CoachInfo } from '../../../interfaces/main-interfaces'
import { Player, BigPlayButton } from 'video-react'
import 'video-react/dist/video-react.css'
import { breakLine } from '../../utils/breakLine'
import { Button, useMediaQuery, useTheme } from '@mui/material'

export interface CoachDialogProps {
  coach: CoachInfo
  onClose: () => void
  onSelect?: () => void
}

export function CoachDialog({ onClose, coach, onSelect }: CoachDialogProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const isSm = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <CoachDialogWrapper open={true} onClose={() => onClose()}>
      <div className="coachDialogContent">
        <div className="coachDialohHeader">
          <h2> {t('aboutCoach', { name: coach.profile.name })} </h2>
          <button onClick={() => onClose()}>
            <Close />
          </button>
        </div>
        <div className="coachDialogBody">
          {coach.profile.video && (
            <Player
              src={coach.profile.video}
              fluid={false}
              width={'auto' as any}
              height={isSm ? 200 : 250}
            >
              <BigPlayButton position="center" />
            </Player>
          )}
          <div className="coachInfo">
            <div className="infoGroup">
              <h2>Summary</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: breakLine(coach.bio?.summary || ''),
                }}
              ></p>
            </div>
            <div className="infoGroup">
              <h2>Bio</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: breakLine(coach.bio?.full || ''),
                }}
              ></p>
            </div>
            <div className="infoGroup">
              <h2>Coaching Philosophy</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: breakLine(coach.bio?.philosophy || ''),
                }}
              ></p>
            </div>
            <div className="infoGroup">
              <h2>Background</h2>
              <div className="expertises">
                {coach.bio?.background?.map((item, index) => (
                  <span key={`background_${index}`}>{item} </span>
                ))}
              </div>
            </div>
            <div className="infoGroup">
              <h2>Languages</h2>
              <div className="expertises">
                {coach.bio?.languages?.map((item, index) => (
                  <span key={`languages_${index}`}>{item} </span>
                ))}
              </div>
            </div>
            <div className="infoGroup">
              <h2>Licenses</h2>
              <div className="expertises">
                {coach.bio?.licenses?.map((item, index) => (
                  <span key={`licenses_${index}`}>{item} </span>
                ))}
              </div>
            </div>
            <div className="infoGroup">
              <h2>Coching style</h2>
              <div className="expertises">
                {coach.bio?.style?.map((item, index) => (
                  <span key={`style_${index}`}>{item} </span>
                ))}
              </div>
            </div>
          </div>
        </div>
        {onSelect && (
          <div className="coachDialogFooter">
            <Button onClick={() => onSelect()} size="large" variant="contained">
              {t('select')}
            </Button>
          </div>
        )}
      </div>
    </CoachDialogWrapper>
  )
}
