import { useTranslation } from 'react-i18next'
import { Button, Stack } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGoogleLogin } from '@react-oauth/google'
import { OnboardingContent } from '../onboardint-styles'
import { OnboardingHeader } from '../shared/OnboardingHeader'
import GoogleIcon from '../../../assets/icons/Google'
import { useAppDispatch } from '../../../app/store/store.config'
import { setUser } from '../../../app/store/slices/authSlice'
import { useGoogleAuthMutation } from '../../../app/store/services/auth.service'
import { toast } from 'sonner'
import { usePostHog } from 'posthog-js/react'

export function GoogleConnect() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const posthog = usePostHog()
  const [googleAuth, { isLoading }] = useGoogleAuthMutation()

  const login = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async ({ code }) => {
      try {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const respose = await googleAuth({ code, timeZone }).unwrap()
        dispatch(setUser(respose))

        if (respose.existingUser) {
          const nextURL = searchParams.get('nextURL')
          navigate(nextURL ?? '/')
        } else {
          navigate('/onboarding/connect-calendar')
        }

        posthog.capture('login', {
          method: 'google',
        })
      } catch (error: any) {
        if (error.data.message) {
          toast.error(error.data.message)
        }
      }
    },
    onError: (error) => {
      posthog.capture('login_error', {
        method: 'google',
        error: error.error_description,
      })
      if (error.error_description) {
        toast.error(error.error_description)
        console.log(error.error_uri)
      } else {
        toast.error(t('somethingWentWrong'))
      }
    },
  })

  return (
    <OnboardingContent>
      <Stack gap="24px" alignItems="center">
        <OnboardingHeader
          title={t('completeProfile')}
          subTitle={t('completeProfileSubtitle')}
        />
        <Button
          variant="outlined"
          className="circular"
          sx={{ minWidth: '260px' }}
          size="large"
          startIcon={<GoogleIcon />}
          onClick={() => login()}
          disabled={isLoading}
        >
          {t(isLoading ? 'syncing' : 'continueWithGoogle')}
        </Button>
      </Stack>
    </OnboardingContent>
  )
}
