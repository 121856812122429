import { StepLabel, Stepper, styled } from '@mui/material'

export const OnboardingWrapper = styled('main')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  minHeight: '100dvh',
  '&.signin': {
    gridTemplateColumns: '1fr',
  },
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr',
  },
}))

export const OnboardingLeft = styled('aside')(({ theme }) => ({
  background: 'url(/onboarding-background.jpg)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
  gap: '150px',
  padding: '50px 24px 150px 24px',
  [theme.breakpoints.down('lg')]: {
    '&:not(.signin)': {
      display: 'none',
    },
    '&.signin': {
      padding: '24px 12px',
    },
  },
}))

export const OnboardingLogo = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  img: {
    width: '150px',
    [theme.breakpoints.down('xl')]: {
      width: '110px',
    },
  },
}))

export const OnboardingStepper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '50px',
  '.signinLink': {
    color: theme.palette.primary.contrastText,
    font: '400 14px Poppins, sans-serif',
    ':hover': {
      textDecoration: 'underline',
    },
  },
  '&.signin': {
    background: 'rgba(0,0,0,.1)',
    maxWidth: '400px',
    height: 'fit-content',
    margin: '0 auto',
    padding: '36px 24px',
    borderRadius: '12px',
    gap: '24px',
    backdropFilter: 'blur(20px)',
    border: `1px solid ${theme.palette.primary.dark}`,
  },
}))

export const OnboardingStepperHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: '8px',
  h1: {
    margin: 0,
    lineHeight: '36px',
    textAlign: 'center',
    color: '#ffffff',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '36px',
    fontWeight: '600',
  },
  p: {
    margin: 0,
    textAlign: 'center',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '500',
    display: 'flex',
    flexDirection: 'column',
    color: 'var(--bold-orange-40)',
  },

  '&.signin': {
    h1: {
      fontSize: '30px',
      lineHeight: '32px',
      maxWidth: '250px',
    },
    p: {
      maxWidth: '350px',
    },
  },

  [theme.breakpoints.down('xl')]: {
    h1: {
      fontSize: '32px',
    },
    p: {
      fontSize: '14px',
      lineHeight: '18px',
    },
  },
}))

export const OnboardingStepperContent = styled(Stepper)(({ theme }) => ({
  '.MuiStepConnector-root': {
    marginLeft: 0,
    height: '60px',
    width: '64px',
    position: 'relative',
    [theme.breakpoints.down('xl')]: {
      width: '56px',
      height: '52px',
    },
    '.MuiStepConnector-line': {
      top: 0,
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      position: 'absolute',
      width: '2px',
      border: 'none',
      background: '#ffffff',
    },
  },
}))

export const OnboardingStepLabel = styled(StepLabel)(() => ({
  gap: '26px',
  padding: 0,
}))

export const StepIcon = styled('div')(({ theme }) => ({
  width: '64px',
  height: '64px',
  borderRadius: '100px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid white',
  color: 'white',
  fontFamily: 'Poppins, sans-serif',
  fontSize: '24px',
  '&.active': {
    background: '#fff',
    color: 'var(--bold-orange)',
  },
  [theme.breakpoints.down('xl')]: {
    width: '56px',
    height: '56px',
  },
}))

export const StpeTitle = styled('span')(({ theme }) => ({
  fontFamily: 'Poppins, sans-serif',
  color: 'var(--bold-orange-60) !important',
  fontSize: '20px',
  lineHeight: '22px',
  fontWeight: '600',
  '&.active': {
    color: '#ffffff !important',
  },
  [theme.breakpoints.down('xl')]: {
    fontSize: '24px',
  },
}))

export const StepSubtitle = styled('span')(({ theme }) => ({
  fontFamily: 'Poppins, sans-serif',
  color: 'var(--bold-orange-60)',
  fontSize: '14px',
  fontWeight: '500',
  '&.active': {
    color: '#ffffff',
  },
  [theme.breakpoints.down('xl')]: {
    fontSize: '14px',
  },
}))

export const OnboardingRight = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '24px',
  [theme.breakpoints.down('lg')]: {
    padding: '12px',
  },
}))
