import { Close } from '@mui/icons-material'
import { CoachDialogWrapper } from '../coach-dialog/coach-dialog.style'
import { Trans, useTranslation } from 'react-i18next'
import { useAuth } from '../../../hooks/useAuth'
import { TimezoneBody } from './timezone.style'
import Lottie from 'react-lottie'
import warning from '../../../assets/lottie/warning.json'
import TimezoneSelect from 'react-timezone-select'
import { Button } from '@mui/material'
import { useCallback, useState } from 'react'
import { useUpdateTimezoneMutation } from '../../../app/store/services/manager.service'
import { toast } from 'sonner'

export function TimezonDialog({ onClose }: { onClose: () => void }) {
  const detectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const [timezone, setTimezone] = useState(detectedTimezone)
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [updateTimezone] = useUpdateTimezoneMutation()
  const { user, checkUser } = useAuth()

  const handleSubmit = useCallback(async () => {
    setLoading(true)
    const response = await updateTimezone({ timezone }).unwrap()
    if (response.ok) {
      checkUser().then(() => {
        toast.success(t('timezoneUpdated'))
        setLoading(false)
        onClose()
      })
    } else {
      setLoading(false)
      toast.error(t('timezoneError'))
    }
  }, [checkUser, onClose, t, timezone, updateTimezone])

  return (
    <CoachDialogWrapper
      open={true}
      onClose={() => !loading && onClose()}
      PaperProps={{
        sx: {
          overflowY: 'visible',
        },
      }}
    >
      <TimezoneBody>
        <div className="timezoneTop">
          <Lottie
            options={{
              animationData: warning,
              loop: true,
              autoplay: true,
            }}
            isPaused={false}
            width={70}
            height={70}
          />
          <div className="timezoneMessage">
            <h4> {t('timeZoneChanged')} </h4>
            <Trans i18nKey="timezoneData">
              <p className="timezoneData">
                <span>Your previous timezone was</span>
                <b> {user?.user.timezone}</b>
                <span>
                  and it is not the same as your current detected timezone
                </span>
                <b>{detectedTimezone}</b>
              </p>
            </Trans>
          </div>
        </div>
        <div className="timezoneForm">
          <TimezoneSelect
            styles={{
              control: (base) => ({
                ...base,
                borderRadius: '12px',
              }),
            }}
            value={timezone}
            onChange={(value: any) => setTimezone(value.value)}
          />
          <div className="timezoneFooter">
            <Button onClick={() => !loading && onClose()} variant="outlined">
              {t('close')}
            </Button>
            <Button
              onClick={handleSubmit}
              disabled={loading}
              variant="contained"
            >
              {t('save')}
            </Button>
          </div>
        </div>
      </TimezoneBody>
    </CoachDialogWrapper>
  )
}
