import {
  Avatar,
  Divider,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { AccountButtonWrapper } from './account-button.style'
import { KeyboardArrowDown } from '@mui/icons-material'
import { useAuth } from '../../../hooks/useAuth'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generateTwoLetterName } from '../../utils/generateTwoLetterName'
import { stringToColor } from '../../utils/stringToColor'
import { useNavigate } from 'react-router-dom'

export interface AccountButtonProps {}

export function AccountButton() {
  const { user, logout } = useAuth()
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null)
  const { t } = useTranslation()
  const theme = useTheme()
  const isSm = useMediaQuery(theme.breakpoints.down('md'))
  const navigate = useNavigate()
  const profilePicture = user?.user.profile.picture
  const profileName = user?.user.profile.name
  const avatarIcon = () => {
    return profilePicture ? (
      <Avatar src={profilePicture} alt={profileName} />
    ) : (
      <Avatar sx={{ background: stringToColor(profileName || '') }}>
        {generateTwoLetterName(profileName || '')}
      </Avatar>
    )
  }
  return (
    <>
      <AccountButtonWrapper
        disableRipple
        onClick={(event) => setAnchor(event.currentTarget)}
      >
        {!isSm ? (
          <div className="profile-info">
            <div className="profile-icon">{avatarIcon()}</div>
            <div className="info">
              <p className="accountUser">{profileName} </p>
              <span>{user?.user?.title} </span>
            </div>
          </div>
        ) : (
          avatarIcon()
        )}
        <KeyboardArrowDown fontSize="small" color="inherit" />
      </AccountButtonWrapper>
      <Menu
        anchorEl={anchor}
        open={!!anchor}
        onClose={() => setAnchor(null)}
        MenuListProps={{
          dense: true,
        }}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        slotProps={{
          paper: {
            sx: () => ({
              width: '200px',
              marginTop: '5px',
            }),
          },
        }}
      >
        <MenuItem
          onClick={() => {
            navigate('/settings/profile')
            setAnchor(null)
          }}
        >
          {t('settings')}
        </MenuItem>
        <Divider />
        <MenuItem onClick={logout}>{t('logout')}</MenuItem>
      </Menu>
    </>
  )
}
