import { useTranslation } from 'react-i18next'
import { hrLinks, teamMemberLinks } from '../constants/navLinks'
import {
  ChatMessageCount,
  SidebarContent,
  SidebarLink,
  SidebarOverlay,
  SidebarWrapper,
} from './sidebar.style'
import { useAuth } from '../../../hooks/useAuth'
import { useChatUtils } from '../../../app/providers/chat-utils/useChatUtils'
import { useAppDispatch, useAppSelector } from '../../../app/store/store.config'
import { setSidebarVisible } from '../../../app/store/slices/uiSlice'
import { HeaderLogo } from '../main-layout.style'
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { useEffect, useMemo, useState } from 'react'

import Cookies from 'js-cookie'
import { SELECTED_GROUP_COOKIE_KEY } from '../../../constants'
import { assessmentService } from '../../../app/store/services/assessment.service'
import { calendarService } from '../../../app/store/services/calendar.service'
import { authService } from '../../../app/store/services/auth.service'
import { libraryService } from '../../../app/store/services/library.service'
import { managerService } from '../../../app/store/services/manager.service'

export function Sidebar() {
  const { t } = useTranslation()
  const { user } = useAuth()
  const isHR = user?.user.isHR
  const { chats } = useChatUtils()
  const unreadMessagesCount = chats?.unreadMessagesCount || 0
  const visible = useAppSelector((store) => store.ui.sidebarVisible)
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const isMd = useMediaQuery(theme.breakpoints.down('lg'))

  const handleGroupChange: any = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    console.log(event.target.value)
    setSelectedGroup(event.target.value as string)
    Cookies.set(SELECTED_GROUP_COOKIE_KEY, event.target.value as string)

    // Reset all api caches that use groupid header
    dispatch(assessmentService.util.resetApiState())
    dispatch(calendarService.util.resetApiState())
    dispatch(authService.util.resetApiState())
    dispatch(libraryService.util.resetApiState())
    dispatch(managerService.util.resetApiState())
    location.reload()
  }

  const groups = useMemo(() => {
    if (user?.user.groups && user.user.groups.length > 0) {
      return user.user.groups
    }
    return []
  }, [user])

  useEffect(() => {
    const group = Cookies.get(SELECTED_GROUP_COOKIE_KEY)
    if (group) {
      setSelectedGroup(group)
    }
  }, [])

  const initialGroupValue = useMemo(() => {
    if (groups.length === 0) {
      return null
    }
    const cookieGroupId = Cookies.get(SELECTED_GROUP_COOKIE_KEY)
    if (
      cookieGroupId &&
      groups.findIndex(
        (el: { _id: string; value: string }) => el._id === cookieGroupId,
      ) !== -1
    ) {
      return cookieGroupId as string
    }
    if (groups.length > 0) {
      return groups[0]._id
    }
    return null
  }, [groups])

  const [selectedGroup, setSelectedGroup] = useState(initialGroupValue)

  return (
    <>
      <SidebarWrapper className={visible ? 'visible' : ''}>
        {isMd && (
          <Stack
            p="12px 12px 0 20px"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
          >
            <HeaderLogo to="/">
              <img src="/logo-full.png" alt="Hupo" />
            </HeaderLogo>
            <IconButton onClick={() => dispatch(setSidebarVisible(false))}>
              <Close />
            </IconButton>
          </Stack>
        )}
        <SidebarContent>
          {isHR && groups.length > 0 && (
            <>
              <FormControl fullWidth margin="dense">
                <InputLabel id="group-select-label">{t('group')}</InputLabel>
                <Select
                  labelId="group-select-label"
                  id="group-select"
                  value={selectedGroup}
                  label={t('group')}
                  onChange={handleGroupChange}
                >
                  {groups.map((group: any) => (
                    <MenuItem key={group._id} value={group._id}>
                      {group.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
          {(isHR ? hrLinks : teamMemberLinks).map((link) => {
            const isChat =
              link.label === 'team-chat' || link.label === 'group-chat'

            return (
              <SidebarLink
                onClick={() => isMd && dispatch(setSidebarVisible(false))}
                key={link.to}
                to={link.to}
              >
                <span className="linkIcon">
                  <link.icon />
                </span>
                <p className="linkLabel">{t(link.label)}</p>

                {isChat && unreadMessagesCount > 0 && (
                  <ChatMessageCount>
                    {unreadMessagesCount < 100 ? unreadMessagesCount : '99+'}
                  </ChatMessageCount>
                )}
              </SidebarLink>
            )
          })}
        </SidebarContent>
      </SidebarWrapper>
      {isMd && visible && (
        <SidebarOverlay
          onClick={() => dispatch(setSidebarVisible(false))}
          className={visible ? 'visible' : ''}
        />
      )}
    </>
  )
}
