export const sessionDurations = [
  {
    label: '45 minutes',
    value: 45,
  },
  {
    label: '1 hour',
    value: 60,
  },
  {
    label: '1.5 hours',
    value: 90,
  },
  {
    label: '2 hours',
    value: 120,
  },
  // {
  //   label: "2.5 hours",
  //   value: 150,
  // },
  // {
  //   label: "3 hours",
  //   value: 180,
  // },
]
