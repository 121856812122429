import { Button, Input, Text } from '@chakra-ui/react'
import { FC, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'

import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { itemSelect } from '../../../app/store/slices/assessmentSlice'
import { useAppSelector } from '../../../app/store/store.config'
import useToast from '../../../hooks/useToast'
import Toast from '../../../lib/shared/Toast'
import { AssessmentProgress } from '../assessment-progress/AssessmentProgress'
import { AssessmentQuestion } from '../assessment-question/AssessmentQuestion'
import { AssessmentItemWrapper } from '../assessment.style'

const MultiEmailQuestion: FC<any> = ({
  handleBack,
  isLoading,
  progress,
  question,
  answer,
  isLast,
  onSubmit,
}) => {
  const { t } = useTranslation()
  const { toast, showToast, hideToast } = useToast()
  const user = useAppSelector((state) => state.assessment.user)
  console.log(
    'MultiEmailQuestion, companyId:',
    user?.company?._id,
    ', language:',
    user?.language,
    ', answer:',
    answer,
  )

  const minEmailsCount = question.minSelectionLimit ?? 2
  const maxEmailsCount = question.maxSelectionLimit ?? 3
  const [emailsCount, setEmailsCount] = useState(minEmailsCount)

  const inputRef = useRef<string[]>(new Array(emailsCount).fill(''))
  const emailValidationError = useRef<string[]>(new Array(emailsCount).fill(''))
  const [dummyUpdate, setDummyUpdate] = useState(0)

  const dispatch = useDispatch()

  const emailValidation = yup
    .string()
    .email(t('please_enter_valid_email'))
    .required(t('email_is_required'))

  const onLocalSubmit = async () => {
    const emails = inputRef.current
      ?.map((email) => (email ? email.trim()?.toLowerCase() : ''))
      .filter((email) => email.length > 0)
    if (question.optional && emails.length === 0) {
      onSubmit()
      return
    }

    try {
      for (const email of emails) {
        emailValidation.validateSync(email)
      }

      if (emails.length < minEmailsCount) {
        if (question.minSelectionLimit) {
          showToast(t('min_emails_required', { minEmailsCount }), 'error')
        } else {
          showToast(t('emails_required'), 'error')
        }
        return
      }

      dispatch(
        itemSelect({
          questionId: question.friendlyID,
          answers: emails.join(','),
        }),
      )
      onSubmit()
    } catch (validationError: any) {
      showToast(validationError.errors, 'error')
    }
  }

  return (
    <AssessmentItemWrapper>
      <Toast
        open={toast.open}
        message={toast.message}
        severity={toast.severity}
        onClose={hideToast}
      />
      <AssessmentProgress onBack={handleBack} percent={progress} />
      <AssessmentQuestion
        progress={progress}
        onButtonClick={onLocalSubmit}
        title={question.title}
        subTitle={question.description}
        isLoading={isLoading}
        buttonText={isLast ? t('finish') : t('continue')}
      >
        <AnimatePresence key={question.friendlyID}>
          <motion.div
            initial={{ opacity: 0, y: -200 }}
            animate={{ opacity: 1, y: 0 }}
          >
            {new Array(emailsCount).fill(null).map((_, index) => (
              <div style={{ marginBottom: '1rem' }}>
                <Input
                  key={'colleague' + index}
                  placeholder={t('colleague_n_email', {
                    orderNum: index + 1,
                  })}
                  display="block"
                  padding="5px 10px"
                  w="100%"
                  border="1px solid #FF4B0A"
                  borderRadius="8px"
                  outlineColor="#FF4B0A"
                  onChange={(event) =>
                    (inputRef.current[index] = event.target.value)
                  }
                  autoFocus={index === 0}
                  onBlur={() => {
                    const email = inputRef.current[index].trim().toLowerCase()
                    emailValidationError.current[index] = ''
                    if (email) {
                      try {
                        emailValidation.validateSync(email)
                      } catch (validationError: any) {
                        emailValidationError.current[index] =
                          validationError.errors
                      }
                    }
                    setDummyUpdate(dummyUpdate + 1)
                  }}
                  onSubmit={onLocalSubmit}
                />
                {emailValidationError.current[index] && (
                  <Text
                    //status="danger"
                    //style={styles.errors}
                    style={{
                      color: 'red',
                      fontSize: '12px',
                      marginBottom: '5px',
                    }}
                    key={'colleague-email-error' + index}
                  >
                    {emailValidationError.current[index]}
                  </Text>
                )}
              </div>
            ))}
            {emailsCount < maxEmailsCount && (
              <Button
                onClick={() => setEmailsCount(emailsCount + 1)}
                colorScheme="primary"
                variant="outline"
                style={{ borderRadius: 100 }}
                width="100%"
              >
                {t('add_another_colleague_email')}
              </Button>
            )}
          </motion.div>
        </AnimatePresence>
      </AssessmentQuestion>
    </AssessmentItemWrapper>
  )
}

export default MultiEmailQuestion
