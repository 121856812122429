import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { useGetHighlightsQuery } from '../../app/store/services/library.service'
import { HighlightsWrapper } from './notes.style'
import { CircularProgress, Link, Typography } from '@mui/material'
import Lottie from 'react-lottie'
import noData from '../../assets/lottie/no-data.json'

function containsNormalizedTitle(title: string, arrayOfStrings: string[]) {
  return arrayOfStrings.some(
    (val) => val.replace(/[.:]/gi, '') === title.replace(/[.:]/gi, ''),
  )
}

function renderSummary(summary: string, sections: string[], sessionId: string) {
  if (summary.includes('. **')) {
    return summary.split('\n').map((line, index) => {
      const isTitle = line.includes('. **') && line.includes(':**')
      const content = isTitle
        ? line.replace('. **', '. ').replace(':**', ':') // no need to bold the title (css class takes care of it)
        : line.includes('- **') && line.includes(':**')
          ? line.replace('- **', '- <i>').replace(':**', ':</i>')
          : line
      return (
        <p
          className={isTitle ? 'title' : ''}
          key={`${sessionId}_${index}-part`}
          dangerouslySetInnerHTML={{ __html: content }}
        ></p>
      )
    })
  }

  return summary.split('\n').map((part, index) => (
    <p
      className={containsNormalizedTitle(part, sections) ? 'title' : ''}
      key={`${sessionId}_${index}-part`}
    >
      {part}
    </p>
  ))
}

export function HupoHighlights() {
  const { data, isLoading } = useGetHighlightsQuery()
  const { t } = useTranslation()
  if (isLoading) {
    return (
      <HighlightsWrapper>
        <div className="body">
          <CircularProgress />
        </div>
      </HighlightsWrapper>
    )
  }

  return (
    <HighlightsWrapper>
      {data?.summaries?.length ? (
        data?.summaries.map((item) => (
          <div className="body">
            <div key={item.sessionId} className="summaryItem">
              <div className="summaryHeader">
                <h3> {item.title} </h3>
                <p>
                  {moment(item.start).format('D-MMM, h:mm A')} -{' '}
                  {moment(item.end).format('h:mm A')}
                </p>
              </div>
              <div className="summaryBody">
                {renderSummary(item.content, data.sections, item.sessionId)}
                {item.recordingUrls?.length > 0 ? (
                  <div>
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: '600',
                        fontSize: 14,
                        marginTop: 16,
                      }}
                    >
                      {t('session_recordings')}:
                    </Typography>
                    {item.recordingUrls?.map((recording, i) => (
                      <Link
                        href={recording}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <p>Recording {i + 1}</p>
                      </Link>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ))
      ) : (
        <Typography
          variant="body1"
          sx={{
            textAlign: 'center',
            px: '10px',
            py: '20px',
            // backgroundColor: "background.paper",
            // border: "1px solid #e0e0e0",
            borderRadius: '4px',
          }}
        >
          {t('noHupoHighlightsYet')}
          <Lottie
            options={{
              animationData: noData,
              loop: true,
              autoplay: true,
            }}
            width={150}
          />
        </Typography>
      )}
    </HighlightsWrapper>
  )
}
