import { IconButton } from '@mui/material'
import { AccountButton } from '../../../lib/widgets/account-button/AccountButton'
import { HeaderLeft, HeaderLogo, HeaderWrapper } from '../main-layout.style'
import { Menu } from '@mui/icons-material'
import { useAppDispatch } from '../../../app/store/store.config'
import { setSidebarVisible } from '../../../app/store/slices/uiSlice'

export function Header() {
  const dispatch = useAppDispatch()
  return (
    <HeaderWrapper>
      <HeaderLeft>
        <IconButton
          onClick={() => dispatch(setSidebarVisible(true))}
          size="small"
        >
          <Menu />
        </IconButton>
        <HeaderLogo to="/">
          <img src="/logo-full.png" alt="Hupo" />
        </HeaderLogo>
      </HeaderLeft>
      <AccountButton />
    </HeaderWrapper>
  )
}
