import { PropsWithChildren } from 'react'
import { AQWrapper } from './assessment-question.style'
import { Button } from '@mui/material'
import { ChevronRight, Check } from 'react-feather'
import Confetti from 'react-confetti'
import { useTranslation } from 'react-i18next'

export function AssessmentQuestion({
  buttonText,
  onButtonClick,
  title,
  children,
  subTitle,
  isSubtitleQuestion,
  progress,
  isLoading,
}: PropsWithChildren<{
  title: string
  subTitle?: string | React.ReactNode
  buttonText: string
  onButtonClick: () => void
  isSubtitleQuestion?: boolean
  progress: number
  isLoading: boolean
}>) {
  const { t } = useTranslation()

  return (
    <AQWrapper>
      <div className="aqContent">
        <div className="aqHeader">
          <h3> {title} </h3>
          {subTitle && typeof subTitle === 'string' ? (
            <p>
              {subTitle.split('\\n').map((str: string) => (
                <span key={str}>
                  {str}
                  <br />
                </span>
              ))}
            </p>
          ) : (
            subTitle
          )}
        </div>
        <div className="aqBody">{children}</div>
      </div>
      <div className="aqFooter">
        <Button
          className="cta"
          variant="contained"
          size="large"
          disabled={isLoading}
          color={progress === 100 ? 'success' : 'primary'}
          endIcon={progress === 100 ? <Check /> : <ChevronRight />}
          onClick={() => onButtonClick()}
        >
          {isLoading ? t('submittingAnswers') : buttonText}
        </Button>
      </div>
      {progress === 100 && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          gravity={0.02}
        />
      )}
    </AQWrapper>
  )
}
