import { styled } from '@mui/material'

export const GroupMembersTable = styled('table')(({ theme }) => ({
  borderCollapse: 'collapse',
  minWidth: '1000px',
  width: '100%',

  'th,td': {
    textAlign: 'left',
    font: '400 14px Poppins, sans-serif',
    padding: '12px',

    div: {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      img: {
        width: '45px',
        height: '45px',
        objectFit: 'cover',
      },
    },

    '&:first-of-type': {
      width: '50px',
    },
  },
  '.calendarStatus': {
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    '&.connected': {
      color: theme.palette.success.main,
    },
    '&.notConnected': {
      color: theme.palette.error.main,
    },
    '&.partiallyConnected': {
      color: theme.palette.warning.main,
    },
  },
  thead: {
    background: '#F2F4F5',
    th: {
      color: theme.palette.primary.main,
      font: '600 16px Poppins, sans-serif',
    },
    'th:first-of-type': {
      borderRadius: '12px 0 0 12px',
    },
    'th:last-of-type': {
      borderRadius: '0 12px 12px 0',
    },
  },
}))
