import { styled } from '@mui/material'

export const ChatHeaderWrapper = styled('div')(({ theme }) => ({
  height: '70px',
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 16px',
  gap: '12px',
  [theme.breakpoints.down('md')]: {
    padding: '0 0 12px 0',
    height: 'auto',
    border: 'none',
    background: theme.palette.background.paper,
    '.MuiAvatar-root': {
      width: '40px',
      height: '40px',
    },
  },
}))

export const ChatHeaderLeft = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '12px',
}))

export const ChatPartnerInfo = styled('div')(({ theme }) => ({
  display: 'grid',
  h4: {
    font: '500 16px Poppins, sans-serif',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    svg: {
      fontSize: '24px',
      color: theme.palette.grey[500],
    },
  },
  p: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    font: '400 13px Poppins, sans-serif',
  },
  [theme.breakpoints.down('md')]: {
    h4: {
      font: '500 14px Poppins, sans-serif',
    },
    p: {
      font: '400 12px Poppins, sans-serif',
    },
  },
}))
