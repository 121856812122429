import { useTranslation } from 'react-i18next'
import {
  OnboardingLeft,
  OnboardingLogo,
  OnboardingRight,
  OnboardingStepper,
  OnboardingStepperHeader,
  OnboardingWrapper,
} from '../../layout/onboarding/onboarding-layout.style'
import { Button, Stack } from '@mui/material'
import GoogleIcon from '../../assets/icons/Google'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useAppDispatch } from '../../app/store/store.config'
import {
  useGoogleSignInMutation,
  useLoginMutation,
} from '../../app/store/services/auth.service'
import { useGoogleLogin } from '@react-oauth/google'
import { setUser } from '../../app/store/slices/authSlice'
import { toast } from 'sonner'
import { usePostHog } from 'posthog-js/react'

export function LoginPage() {
  const { t } = useTranslation()
  const posthog = usePostHog()

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [googleSignin, { isLoading }] = useGoogleSignInMutation()
  const [adminLogin] = useLoginMutation()

  const login = useGoogleLogin({
    onSuccess: async ({ access_token }) => {
      try {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const response = await googleSignin({
          accessToken: access_token,
          timeZone,
        }).unwrap()
        dispatch(setUser(response))

        const nextURL = searchParams.get('nextURL')
        navigate(nextURL ?? '/')

        posthog.capture('login', {
          method: 'google',
        })
      } catch (error: any) {
        if (error.data.message) {
          toast.error(error.data.message)
        }
      }
    },
    onError: (error) => {
      posthog.capture('login_error', {
        method: 'google',
        error: error.error_description,
      })
      if (error.error_description) {
        toast.error(error.error_description)
        console.log(error.error_uri)
      } else {
        toast.error(t('somethingWentWrong'))
      }
    },
  })

  const onLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData(e.target as HTMLFormElement)
    const email = formData.get('email') as string
    const adminSecret = formData.get('adminSecret') as string
    try {
      const response = await adminLogin({
        email,
        adminSecret,
      }).unwrap()
      dispatch(setUser(response))
      navigate('/')
    } catch (error: any) {
      if (error.data.message) {
        toast.error(error.data.message)
      }
    }
  }

  return (
    <OnboardingWrapper className="signin">
      <OnboardingLeft className="signin">
        <OnboardingLogo>
          <img alt="Hupo" src="/logo-full-white.png" />
        </OnboardingLogo>
        <OnboardingStepper className="signin">
          <OnboardingStepperHeader className="signin">
            <h1> {t('signin')} </h1>
            <p className="signin">
              {t('signinSubtitle')
                .split('/n')
                .map((part, index) => (
                  <span key={`subTitle-${index}`}> {part} </span>
                ))}
            </p>
          </OnboardingStepperHeader>
          <Stack mt="36px" alignItems="center" gap="12px">
            <Button
              className="circular"
              sx={(theme) => ({
                minWidth: '260px',
                background: theme.palette.primary.contrastText,
                ':hover': {
                  background: theme.palette.primary.contrastText,
                },
              })}
              size="large"
              startIcon={<GoogleIcon />}
              onClick={() => login()}
              disabled={isLoading}
            >
              {t(isLoading ? 'syncing' : 'continueWithGoogle')}
            </Button>
            <Link className="signinLink" to="/onboarding">
              {t('register')}
            </Link>
            <div id="alternative-login" style={{ display: 'none' }}>
              <form onSubmit={onLogin}>
                <input type="hidden" name="email" />
                <input type="hidden" name="adminSecret" />
                <div>
                  <input type="submit" value="Login" />
                </div>
              </form>
            </div>
          </Stack>
        </OnboardingStepper>
      </OnboardingLeft>
    </OnboardingWrapper>
  )
}
