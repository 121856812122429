import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useGetGroupMembersQuery } from '../../../app/store/services/manager.service'
import { DashboardCard } from '../../../lib/shared/dashboard-card/DashboardCard'
import { generateTwoLetterName } from '../../../lib/utils/generateTwoLetterName'
import { stringToColor } from '../../../lib/utils/stringToColor'

export function DashboardTeamMembers() {
  const { t } = useTranslation()
  const { data: groupMembers } = useGetGroupMembersQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    },
  )

  return (
    <DashboardCard title={t('groupMembers')}>
      <Stack>
        {groupMembers?.slice(0, 5)?.map((item) => (
          <ListItem key={item._id} disableGutters>
            <ListItemAvatar>
              {item.picture ? (
                <Avatar src={item.picture} />
              ) : (
                <Avatar
                  sx={{
                    background: stringToColor(item.name),
                  }}
                >
                  {generateTwoLetterName(
                    item?.name || item?.title || item._id || '',
                  )}
                </Avatar>
              )}
            </ListItemAvatar>
            <ListItemText primary={item.name} secondary={item.title} />
          </ListItem>
        ))}
      </Stack>
      <Link
        to="/group-members"
        style={{
          font: "600 14px 'Poppins', sans-serif",
          lineHeight: '14px',
          textDecoration: 'none',
          cursor: 'pointer',
          marginTop: '6px',
          color: 'var(--bold-orange)',
        }}
      >
        {t('viewAllMembers')} &gt;
      </Link>
    </DashboardCard>
  )
}
