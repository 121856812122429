import {
  persistReducer,
  PersistConfig,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { reducer } from './reducer'
import { envVars } from '../../constants/envVars'
import { authService } from './services/auth.service'
import { managerService } from './services/manager.service'
import { libraryService } from './services/library.service'
import { meetingService } from './services/meeting.service'
import { calendarService } from './services/calendar.service'
import uiSlice from './slices/uiSlice'
import { coreApi } from './services/core.service'
import { coachService } from './services/coach.service'
import { assessmentService } from './services/assessment.service'
import assessmentSlice from './slices/assessmentSlice'
import { feedbackService } from './services/feedback.service'

const persistConfig: PersistConfig<any> = {
  key: 'root',
  storage,
  blacklist: [
    authService.reducerPath,
    coachService.reducerPath,
    managerService.reducerPath,
    libraryService.reducerPath,
    meetingService.reducerPath,
    coreApi.reducerPath,
    uiSlice.name,
    calendarService.reducerPath,
    // assessmentSlice.name,
    assessmentService.reducerPath,
    feedbackService.reducerPath,
  ],
}

const persistedReducer = persistReducer(persistConfig, reducer as any)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: !envVars.IS_PRODUCTION,
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      authService.middleware,
      coachService.middleware,
      managerService.middleware,
      libraryService.middleware,
      meetingService.middleware,
      coreApi.middleware,
      calendarService.middleware,
      assessmentService.middleware,
      feedbackService.middleware,
    )
  },
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof reducer>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
