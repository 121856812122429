import {
  CalendarMonthOutlined,
  ChatOutlined,
  FolderOutlined,
  GroupOutlined,
  HomeOutlined,
  QueryStatsOutlined,
  AutoAwesomeOutlined,
  BarChart,
} from '@mui/icons-material'

export const hrLinks = [
  {
    label: 'home',
    to: '/',
    icon: HomeOutlined,
  },
  {
    label: 'group-members',
    to: '/group-members',
    icon: GroupOutlined,
  },
  {
    label: 'scheduled-sessions',
    to: '/scheduled-sessions',
    icon: CalendarMonthOutlined,
  },
  {
    label: 'progress',
    to: '/progress',
    icon: QueryStatsOutlined,
  },
  // {
  //   label: "hupo-perspectives",
  //   to: "/hupo-perspectives",
  //   icon: BarChart,
  // },
  // {
  //   label: "group-chat",
  //   to: "/group-chat",
  //   icon: ChatOutlined,
  // },
  {
    label: 'resources',
    to: '/resources',
    icon: FolderOutlined,
  },
  // {
  //   label: "hupoHighlights",
  //   to: "/notes",
  //   icon: AutoAwesomeOutlined,
  // },
]

export const teamMemberLinks = [
  {
    label: 'home',
    to: '/',
    icon: HomeOutlined,
  },
  {
    label: 'group-members',
    to: '/group-members',
    icon: GroupOutlined,
  },
  {
    label: 'scheduled-sessions',
    to: '/scheduled-sessions',
    icon: CalendarMonthOutlined,
  },
  {
    label: 'progress',
    to: '/progress',
    icon: QueryStatsOutlined,
  },
  {
    label: 'group-chat',
    to: '/group-chat',
    icon: ChatOutlined,
  },
  {
    label: 'resources',
    to: '/resources',
    icon: FolderOutlined,
  },
  {
    label: 'hupoHighlights',
    to: '/notes',
    icon: AutoAwesomeOutlined,
  },
]
