import { Center } from '@chakra-ui/react'
import { OutlinedInput } from '@mui/material'
import {
  LEADERSHIP_VALUES_IMPROVEMENT_TO_FOCUS_1_QUESTION_ID,
  LEADERSHIP_VALUES_IMPROVEMENT_TO_FOCUS_2_QUESTION_ID,
  LEADERSHIP_VALUES_WHAT_WORKS_WELL_1_QUESTION_ID,
  LEADERSHIP_VALUES_WHAT_WORKS_WELL_2_QUESTION_ID,
  PEER_LEADERSHIP_VALUES_TO_FOCUS,
  SUGGESTIONS_TO_IMPROVE_LOW_SCORES,
} from '../../assessment/AssessmentRouter'
import { QuestionProps } from '../components/PeerAssessmentArea'
import { Animated } from './components/Animated'
import { QuestionTitle } from './components/QuestionHeader'
import { styled } from '@mui/material/styles'

const TextAreaQuestionWrapper = styled('div')(({ theme }) => ({
  margin: '0 auto',
  display: 'flex',
  alignItems: 'center',
  '@media (max-width: 600px)': {
    padding: '0 24px',
  },
}))

const TextAreaOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  width: '650px',
  margin: '0 auto',
  '@media (max-width: 600px)': {
    width: '100%',
  },
}))

export function TextAreaQuestion({
  data,
  user,
  index,
  answers,
  values,
  setAnswers,
}: QuestionProps) {
  let leadershipValue = null

  if (
    data.friendlyID === LEADERSHIP_VALUES_IMPROVEMENT_TO_FOCUS_1_QUESTION_ID ||
    data.friendlyID === LEADERSHIP_VALUES_WHAT_WORKS_WELL_1_QUESTION_ID
  ) {
    const leadershipValuesToFocus =
      answers.answers[PEER_LEADERSHIP_VALUES_TO_FOCUS]
    const orderedValues = values?.filter((value: any) =>
      leadershipValuesToFocus?.includes(value.value),
    )
    if (
      leadershipValuesToFocus &&
      leadershipValuesToFocus.length > 0 &&
      orderedValues?.length
    ) {
      leadershipValue = orderedValues[0].label
    }
  }

  if (
    data.friendlyID === LEADERSHIP_VALUES_IMPROVEMENT_TO_FOCUS_2_QUESTION_ID ||
    data.friendlyID === LEADERSHIP_VALUES_WHAT_WORKS_WELL_2_QUESTION_ID
  ) {
    const leadershipValuesToFocus =
      answers.answers[PEER_LEADERSHIP_VALUES_TO_FOCUS]
    const orderedValues = values?.filter((value) =>
      leadershipValuesToFocus?.includes(value.value),
    )
    if (
      leadershipValuesToFocus?.length > 1 &&
      orderedValues &&
      orderedValues.length > 1
    ) {
      leadershipValue = orderedValues[1].label
    }
  }

  const extraDescriptionItems: any[] = []
  if (data.friendlyID === SUGGESTIONS_TO_IMPROVE_LOW_SCORES) {
    for (const leadershipValue in answers.ratings) {
      const score = answers.ratings[leadershipValue]
      if (score <= 3) {
        extraDescriptionItems.push(
          values?.find((value: any) => value.value === leadershipValue)?.label,
        )
      }
    }
  }

  return (
    <>
      <QuestionTitle
        data={data}
        user={user}
        leadershipValue={leadershipValue}
      />
      <Animated key={data._id}>
        {extraDescriptionItems.length > 0 && (
          <Center mb={6}>
            <ul
              style={{
                listStyle: 'disc',
                paddingLeft: '20px',
                fontStyle: 'italic',
              }}
            >
              {extraDescriptionItems.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </Center>
        )}
        <TextAreaQuestionWrapper>
          <TextAreaOutlinedInput
            multiline
            minRows={4}
            placeholder="Type here..."
            autoFocus
            value={answers.answers[data.friendlyID]}
            onChange={(event) =>
              setAnswers({
                ...answers,
                answers: {
                  ...answers.answers,
                  [data.friendlyID]: event.target.value,
                },
              })
            }
          />
        </TextAreaQuestionWrapper>
      </Animated>
    </>
  )
}
