import { Box, Button, Icon, Input, Link, Stack, Text } from '@chakra-ui/react'
import { FC, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import { FiPlusCircle } from 'react-icons/fi'

import { useTranslation } from 'react-i18next'
import { itemSelect } from '../../../app/store/slices/assessmentSlice'
import { AssessmentItemWrapper } from '../assessment.style'
import { AssessmentProgress } from '../assessment-progress/AssessmentProgress'
import { AnimatePresence, motion } from 'framer-motion'
import { AssessmentQuestion } from '../assessment-question/AssessmentQuestion'
import useToast from '../../../hooks/useToast'
import Toast from '../../../lib/shared/Toast'

const FeedbackEmailsQuestion: FC<any> = ({
  handleBack,
  isLoading,
  progress,
  question,
  answer,
  isLast,
  onSubmit,
  feedbackRequest,
}) => {
  const { t } = useTranslation()

  const feedbackRequestLimits = feedbackRequest ?? {
    manager: { max: 1, min: 1 },
    peer: { max: 3, min: 1 },
    reportee: { max: 3, min: 1 },
  }

  const peerInputRef = useRef<string[]>(
    new Array(feedbackRequestLimits.peer.max).fill(''),
  )
  const managerInputRef = useRef<string[]>(
    new Array(feedbackRequestLimits.manager.max).fill(''),
  )
  const reporteeInputRef = useRef<string[]>(
    new Array(feedbackRequestLimits.reportee.max).fill(''),
  )

  const onLocalSubmit = async () => {
    const peerEmails = peerInputRef.current
      ?.map((email) => email ? email.trim()?.toLowerCase() : '')
      .filter((email) => email.length > 0)
    const managerEmails = managerInputRef.current
      ?.map((email) => email ? email.trim()?.toLowerCase() : '')
      .filter((email) => email.length > 0)
    const reporteeEmails = reporteeInputRef.current
      ?.map((email) => email ? email.trim()?.toLowerCase() : '')
      .filter((email) => email.length > 0)

    if (
      question.optional &&
      peerEmails.length === 0 &&
      managerEmails.length === 0 &&
      reporteeEmails.length === 0
    ) {
      onSubmit()
      return
    }

    try {
      for (const email of managerEmails) {
        emailValidation.validateSync(email)
      }
      for (const email of peerEmails) {
        emailValidation.validateSync(email)
      }
      for (const email of reporteeEmails) {
        emailValidation.validateSync(email)
      }

      if (managerEmails.length < feedbackRequestLimits.manager.min) {
        showToast(
          t('min_type_emails_required', {
            min: feedbackRequestLimits.manager.min,
            type: 'manager',
          }),
          'error',
        )
        return
      }

      if (peerEmails.length < feedbackRequestLimits.peer.min) {
        showToast(
          t('min_type_emails_required', {
            min: feedbackRequestLimits.peer.min,
            type: 'peer',
          }),
          'error',
        )
        return
      }

      if (reporteeEmails.length < feedbackRequestLimits.reportee.min) {
        showToast(
          t('min_type_emails_required', {
            min: feedbackRequestLimits.reportee.min,
            type: 'reportee',
          }),
          'error',
        )
        return
      }

      const allEmailsArray = [
        ...peerEmails,
        ...reporteeEmails,
        ...managerEmails,
      ]
      const allEmailsSet = new Set(allEmailsArray)

      if (allEmailsSet.size < allEmailsArray.length) {
        // Some emails are duplicated
        showToast(t('provide_unique_emails'), 'error')
        return
      }

      dispatch(
        itemSelect({
          questionId: question.friendlyID,
          answers: JSON.stringify({
            peers: peerEmails,
            managers: managerEmails,
            reportees: reporteeEmails,
          }),
        }),
      )

      onSubmit()
    } catch (validationError: any) {
      reportError(validationError)
      showToast(validationError.errors, 'error')
    }
  }

  const dispatch = useDispatch()

  const { toast, showToast, hideToast } = useToast()

  const emailValidation = yup
    .string()
    .email(t('please_enter_valid_email'))
    .required(t('email_is_required'))

  return (
    <AssessmentItemWrapper>
      <Toast
        open={toast.open}
        message={toast.message}
        severity={toast.severity}
        onClose={hideToast}
      />
      <AssessmentProgress onBack={handleBack} percent={progress} />
      <Stack gridRowGap={1} py={10}>
        <AssessmentQuestion
          progress={progress}
          onButtonClick={onLocalSubmit}
          title={question?.title}
          subTitle={question?.description}
          isLoading={isLoading}
          isSubtitleQuestion={question.type === 'value-score'}
          buttonText={t('continue')}
        >
          <AnimatePresence key={question.friendlyID}>
            <motion.div
              initial={{ opacity: 0, y: -200 }}
              animate={{ opacity: 1, y: 0 }}
            >
              <EmailInputs
                label={t('managers_label')}
                type="manager"
                min={feedbackRequestLimits.manager.min}
                max={feedbackRequestLimits.manager.max}
                inputRef={managerInputRef}
                onLocalSubmit={null}
              />
              <EmailInputs
                label={t('peers_label')}
                type="peer"
                min={feedbackRequestLimits.peer.min}
                max={feedbackRequestLimits.peer.max}
                inputRef={peerInputRef}
                onLocalSubmit={null}
              />
              <EmailInputs
                label={t('reportees_label')}
                type="reportee"
                min={feedbackRequestLimits.reportee.min}
                max={feedbackRequestLimits.reportee.max}
                inputRef={reporteeInputRef}
                onLocalSubmit={onLocalSubmit}
              />
            </motion.div>
          </AnimatePresence>
        </AssessmentQuestion>
      </Stack>
    </AssessmentItemWrapper>
  )
}

const EmailInputs = ({
  label,
  type,
  min,
  max,
  inputRef,
  onLocalSubmit,
}: any) => {
  const { t } = useTranslation()

  const [emailsCount, setEmailsCount] = useState(min)
  const emailValidationError = useRef<string[]>(new Array(max).fill(''))
  const emailValidation = yup
    .string()
    .email(t('invalid_email'))
    .required(t('email_required'))

  const [dummyUpdate, setDummyUpdate] = useState(0)

  return (
    <Box style={{ paddingBottom: 10 }} key={type + '_p'}>
      <Text style={{ paddingBottom: 4 }}>{label}</Text>
      {new Array(emailsCount).fill(null).map((_, index) => (
        <Box key={type + index} style={{ marginBottom: 6 }}>
          <Box
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Input
              key={'colleague' + index}
              placeholder={t('peer_type_n_email', {
                orderNum: index + 1,
                peerType: type.charAt(0).toUpperCase() + type.slice(1),
              })}
              onChange={(event) =>
                (inputRef.current[index] = event.target.value)
              }
              autoFocus={index === 0}
              onBlur={() => {
                const email = inputRef.current[index].trim().toLowerCase()
                emailValidationError.current[index] = ''
                if (email) {
                  try {
                    emailValidation.validateSync(email)
                  } catch (validationError: any) {
                    emailValidationError.current[index] = validationError.errors
                  }
                }
                setDummyUpdate(dummyUpdate + 1)
              }}
              onSubmit={onLocalSubmit}
            />
            {index === emailsCount - 1 && emailsCount < max && (
              <Link
                onClick={() => setEmailsCount(emailsCount + 1)}
                style={{
                  marginLeft: 10,
                  padding: 8,
                  borderRadius: 20,
                }}
              >
                <Icon as={FiPlusCircle} width={18} height={18} />
              </Link>
            )}
          </Box>
          {emailValidationError.current[index] && (
            <Text
              style={{
                color: 'red',
                fontSize: '12px',
                marginBottom: '5px',
              }}
              key={'colleague-email-error' + index}
            >
              {emailValidationError.current[index]}
            </Text>
          )}
        </Box>
      ))}
    </Box>
  )
}

export default FeedbackEmailsQuestion
