/* eslint-disable react-hooks/exhaustive-deps */
import { PropsWithChildren, useEffect, useState } from 'react'
import { ChatContext } from './ChatContext'
import { Socket, io } from 'socket.io-client'
import { envVars } from '../../../constants/envVars'

export function ChatProvider(props: PropsWithChildren) {
  const [socket, setSocket] = useState<Socket | null>(null)

  useEffect(() => {
    if (socket && socket.connected) {
      socket.disconnect()
    }

    const newSocket = io(envVars.SOCKET_URL, {
      transports: ['websocket'],
    }).connect()
    setSocket(newSocket)
  }, [])

  return (
    <ChatContext.Provider value={{ socket } as any}>
      {props.children}
    </ChatContext.Provider>
  )
}
