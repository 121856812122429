import { ButtonBase, styled } from '@mui/material'

export const ChatListWrapper = styled('div')(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.divider}`,
  position: 'relative',
  minWidth: '360px',
  maxWidth: '400px',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  [theme.breakpoints.down('md')]: {
    borderRight: 'none',
  },
}))

export const ChatListFooter = styled('div')(({ theme }) => ({
  padding: '12px',
  display: 'grid',
  borderTop: `1px solid ${theme.palette.grey[300]}`,
  [theme.breakpoints.down('md')]: {
    borderTop: 'none',
    padding: '12px 0 0 0',
  },
}))

export const ChatListButton = styled(ButtonBase)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  font: '500 14px Poppins, sans-serif',
  height: '40px',
  borderRadius: '12px',
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
}))

export const ChatUserList = styled('ul')(() => ({
  flex: 1,
  display: 'grid',
  alignItems: 'flex-start',
  alignContent: 'flex-start',
  overflowY: 'auto',
}))
