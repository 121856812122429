import { styled } from '@mui/material'
import { NavLink } from 'react-router-dom'

export const SidebarWrapper = styled('aside')(({ theme }) => ({
  position: 'fixed',
  top: 70,
  left: 0,
  width: '300px',
  bottom: 0,
  zIndex: 4,
  borderRight: `1px solid ${theme.palette.grey[300]}`,
  background: theme.palette.background.paper,
  [theme.breakpoints.down('lg')]: {
    left: '-300px',
    width: '270px',
    transition: '.2s',
    zIndex: 6,
    top: 0,
    '&.visible': {
      left: 0,
    },
  },
}))

export const SidebarContent = styled('ul')(() => ({
  margin: 0,
  display: 'grid',
  padding: '12px',
}))

export const SidebarLink = styled(NavLink)(({ theme }) => ({
  display: 'flex',
  gap: '12px',
  alignItems: 'center',
  textDecoration: 'none',
  padding: '10px 12px',
  borderRadius: '5px',
  transition: '.2s',
  color: theme.palette.grey[700],
  position: 'relative',

  '.linkIcon': {
    width: '30px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  '.linkLabel': {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: '500',
    fontSize: '14px',
  },

  ':hover': {
    background: 'rgba(0,0,0,0.05)',
  },

  '&.active': {
    background: theme.palette.primary.light,
    color: theme.palette.primary.main,
  },
}))

export const ChatMessageCount = styled('span')(({ theme }) => ({
  font: '500 12px Poppins, sans-serif',
  background: theme.palette.primary.main,
  borderRadius: '10000px',
  color: theme.palette.primary.contrastText,
  userSelect: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  aspectRatio: '1 / 1',
  padding: '3px',
  position: 'absolute',
  right: 12,
  width: '25px',
  height: '25px',
}))

export const SidebarOverlay = styled('div')(() => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'rgba(0,0,0,0.6)',
  zIndex: 4,
  display: 'none',
  '&.visible': {
    display: 'block',
  },
}))
