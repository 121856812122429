import React from 'react'
import ReactDOM from 'react-dom/client'
import posthog, { PostHogConfig } from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { App } from './app/App'

import './lib/styles/reset.css'
import './index.css'
import './i18n'
import './sentry'
import { ErrorBoundary } from './error-boundary'

const isCheckly = () => {
  return navigator.userAgent.includes('Checkly, https://www.checklyhq.com')
}

const options: Partial<PostHogConfig> = {
  name: 'Group',
  person_profiles: 'identified_only',
  opt_out_capturing_persistence_type: 'localStorage',
  api_host: import.meta.env.VITE_POSTHOG_HOST,
  debug: import.meta.env.DEV,
  disable_session_recording: true,
  opt_out_capturing_by_default: true,
  loaded(posthog) {
    if (import.meta.env.PROD && !isCheckly()) {
      posthog.opt_in_capturing()
      posthog.startSessionRecording()
    }
  },
  session_recording: {
    maskAllInputs: true,
    maskTextSelector: '*',
  },
}

if (import.meta.env.PROD) {
  posthog.init(import.meta.env.VITE_POSTHOG_KEY, options)
}

const container = document.getElementById('root')
if (!container) throw new Error('Root element not found')
const root = ReactDOM.createRoot(container)

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <PostHogProvider client={posthog}>
        <App />
      </PostHogProvider>
    </ErrorBoundary>
  </React.StrictMode>,
)
