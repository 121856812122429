import {
  Avatar,
  Button,
  CircularProgress,
  ListItemAvatar,
  ListItemText,
  Stack,
} from '@mui/material'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import {
  useGetCoachOptionsQuery,
  useSelectCoachMutation,
} from '../../../app/store/services/manager.service'
import { OnboardingCoach } from '../../../interfaces/coach.interface'
import { generateTwoLetterName } from '../../../lib/utils/generateTwoLetterName'
import { CoachDialog } from '../../../lib/widgets/coach-dialog/CoachDialog'
import { OnboardingContent } from '../onboardint-styles'
import { OnboardingHeader } from '../shared/OnboardingHeader'
import { CoachSelectionItem } from './coach-selection.style'
import { usePostHog } from 'posthog-js/react'

export function CoachSelection() {
  const { data, isFetching } = useGetCoachOptionsQuery()
  const { t } = useTranslation()
  const [selectedCoach, setSelectedCoach] = useState<OnboardingCoach | null>(
    null,
  )
  const [currentCoach, setCurrentCoach] = useState<OnboardingCoach | null>(null)
  const [selectCoach, { isLoading }] = useSelectCoachMutation()

  const posthog = usePostHog()
  const navigate = useNavigate()

  const onFinish = useCallback(async () => {
    if (selectedCoach) {
      try {
        const response = await selectCoach({
          coachId: selectedCoach.coach._id,
        }).unwrap()

        if (response.ok) {
          posthog.capture('coach_selected', {
            coach_id: selectedCoach.coach._id,
          })
          toast.success(t('coachSelected'))
          navigate('/')
        }
      } catch (error: any) {
        posthog.capture('coach_selection_error', {
          error: error.data.message,
        })
      }
    } else {
      posthog.capture('coach_not_selected')
      toast.error(t('pleaseSelectCoach'))
    }
  }, [navigate, selectCoach, selectedCoach, posthog, t])

  return (
    <OnboardingContent>
      <Stack gap="24px">
        <OnboardingHeader title={t('selectGroupCoach')} />
        {isFetching ? (
          <Stack minHeight="200px" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        ) : (
          <Stack gap="24px">
            <Stack gap="12px">
              {data?.data.map((item) => (
                <CoachSelectionItem key={item.coach._id}>
                  <div className="top">
                    <ListItemAvatar>
                      {item.coach.profile.picture ? (
                        <Avatar
                          sx={{ width: '50px', height: '50px' }}
                          src={item.coach.profile.picture}
                        />
                      ) : (
                        <Avatar sx={{ width: '50px', height: '50px' }}>
                          {generateTwoLetterName(item.coach.profile.name)}
                        </Avatar>
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      primary={item.coach.profile.name}
                      secondary={item.coach.email}
                    />
                  </div>
                  <div className="bottom">
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => setCurrentCoach(item)}
                    >
                      {t('view')}
                    </Button>
                    <Button
                      size="small"
                      onClick={() => setSelectedCoach(item)}
                      variant={
                        selectedCoach &&
                        selectedCoach.coach._id === item.coach._id
                          ? 'contained'
                          : 'soft'
                      }
                    >
                      {t(
                        selectedCoach &&
                          selectedCoach.coach._id === item.coach._id
                          ? 'selected'
                          : 'select',
                      )}
                    </Button>
                  </div>
                </CoachSelectionItem>
              ))}
            </Stack>
            <Button
              onClick={onFinish}
              disabled={isLoading}
              variant="contained"
              size="large"
            >
              {t('finish')}
            </Button>
          </Stack>
        )}
      </Stack>
      {currentCoach && (
        <CoachDialog
          coach={{
            _id: currentCoach.coach._id,
            bio: currentCoach?.bio?.bio as any,
            chatLink: '',
            profile: currentCoach.coach.profile,
          }}
          onClose={() => setCurrentCoach(null)}
        />
      )}
    </OnboardingContent>
  )
}
