/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react'
import { ChatContext } from '../app/providers/chat-provider/ChatContext'
import { Message } from '../interfaces/main-interfaces'

export type NewChatMessage = Message & {
  chatId: string
}

export type SocketEventMap = {
  new_message: NewChatMessage
  seen: any
}

export function useEvent<K extends keyof SocketEventMap>(
  event: K,
  listener: (data: SocketEventMap[K]) => void,
) {
  const chatSTore = useContext(ChatContext)

  useEffect(() => {
    if (chatSTore?.socket) {
      chatSTore.socket.on(event, listener as any)

      return () => {
        chatSTore.socket.off(event, listener as any)
      }
    }
  }, [chatSTore?.socket])
}
