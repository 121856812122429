// @ts-expect-error - ignore for now
import GoogleSpeechRecognition from '@dytesdk/google-transcription'
import { useCallback, useState } from 'react'
import { envVars } from '../../../../constants/envVars'

export const useSpeechRecognition = ({
  meeting,
  sessionId,
  useStt,
  participant,
  isCoach,
}: any) => {
  const [gsrSocket, setGsrSocket] = useState<any>(null)

  const onStartSpeechRecognition = useCallback(() => {
    if (!useStt || !meeting || !sessionId || !participant) return

    const speech = new GoogleSpeechRecognition({
      meeting,
      target: 'en',
      source: 'en-US',
      baseUrl: envVars.SOCKET_URL,
    })
    const gsrSocket = speech.socketClient.socket
    setGsrSocket(gsrSocket)
    gsrSocket.emit('speech_join', {
      socketId: gsrSocket.id,
      email: participant.email,
      name: participant.profile.name,
      participantId: participant._id,
      isCoach: !!isCoach,
      sessionId,
    })

    speech.transcribe()
    console.log('Start transcribing')
  }, [meeting, sessionId, useStt, isCoach, participant])

  const onStopSpeechRecognition = useCallback(() => {
    if (!useStt || !meeting || !gsrSocket || !participant) return

    gsrSocket.emit('speech_leave', {
      socketId: gsrSocket.id,
      participantId: participant._id,
      sessionId,
    })
    console.log('Stop transcribing')
  }, [meeting, gsrSocket, sessionId, useStt, participant])

  return {
    speechSocket: useStt ? gsrSocket : '<disabled>',
    onStartSpeechRecognition,
    onStopSpeechRecognition,
  }
}
