import { createContext } from 'react'
import { ChatUtilsType } from './useChatUtils'

export const ChatUtilsContext = createContext<ChatUtilsType>({
  chatsLoading: true,
  privateChats: { data: [] },
  teamChats: { data: [] },
  handleNewMessage: () => undefined,
  markAsRead: () => undefined,
} as any)
