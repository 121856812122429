import { PropsWithChildren } from 'react'
import { LoadingOverlayWrapper } from './shared.styles'
import { CircularProgress } from '@mui/material'

export function LoadingOverlay({
  isLoading,
  children,
}: PropsWithChildren<{ isLoading: boolean }>) {
  return (
    <LoadingOverlayWrapper>
      {isLoading && (
        <div className="loadingContent">
          <CircularProgress />
        </div>
      )}
      {children}
    </LoadingOverlayWrapper>
  )
}
