import { Add } from '@mui/icons-material'
import { Button, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useMemo, useState } from 'react'
import { useGetGroupMembersQuery } from '../../app/store/services/manager.service'
import { useAuth } from '../../hooks/useAuth'
import { PageTitle } from '../../lib/shared/shared.styles'
import { GroupMembers } from '../../lib/widgets/group-members/GroupMembers'
import { DashboardWrapper } from '../dashboard/dashboard.style'
import { InviteMember } from './InviteMember'

export function GroupMembersPage() {
  const { t } = useTranslation()
  const { user } = useAuth()
  const [open, setOpen] = useState(false)

  const { data: groupMembers } = useGetGroupMembersQuery({
    includeInvited: '1',
  })

  const hasMemberInvitationCapability = useMemo(() => {
    return user?.user?.isHR || user?.user?.capabilities?.canInviteMember
  }, [user])

  return (
    <DashboardWrapper>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        sx={(theme) => ({
          [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            gap: '12px',
          },
        })}
      >
        <div>
          <PageTitle> {t('groupMembers')} </PageTitle>
          {hasMemberInvitationCapability && (
            <p style={{ fontSize: '16px', fontWeight: 300 }}>
              {t('groupMembersPageDescription')}
            </p>
          )}
        </div>
        {hasMemberInvitationCapability && (
          <div style={{ alignSelf: 'flex-end' }}>
            <Button
              onClick={() => setOpen(true)}
              startIcon={<Add />}
              variant="contained"
            >
              {t('inviteGroupMember')}
            </Button>
          </div>
        )}
      </Stack>
      {open && (
        <InviteMember onClose={() => setOpen(false)} member={undefined} />
      )}
      <Stack
        sx={(theme) => ({
          filter: 'var(--card-shadow)',
          padding: '22px',
          background: '#ffffff',
          borderRadius: '12px',
          overflowX: 'auto',
          [theme.breakpoints.down('lg')]: {
            padding: '12px',
          },
        })}
      >
        <GroupMembers members={groupMembers || []} />
      </Stack>
    </DashboardWrapper>
  )
}
