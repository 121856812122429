import { RouterProvider } from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { Toaster } from 'sonner'
import { router } from '../router'
import { appTheme } from '../lib/styles/appTheme'
import { Provider } from 'react-redux'
import { persistor, store } from './store/store.config'
import { PersistGate } from 'redux-persist/integration/react'
import { Suspense } from 'react'
import { ConfirmProvider } from 'material-ui-confirm'
import { useTranslation } from 'react-i18next'

export function App() {
  const { t } = useTranslation()

  return (
    <Suspense fallback="">
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
            <ThemeProvider theme={appTheme}>
              <ConfirmProvider
                defaultOptions={{
                  cancellationText: t('cancel'),
                  dialogProps: {
                    className: 'confirmDialog',
                  },
                }}
              >
                <RouterProvider router={router} />
              </ConfirmProvider>
              <CssBaseline />
              <Toaster
                invert
                theme="light"
                richColors
                position="top-center"
                toastOptions={{
                  style: {
                    padding: '8px',
                  },
                }}
              />
            </ThemeProvider>
          </GoogleOAuthProvider>
        </PersistGate>
      </Provider>
    </Suspense>
  )
}
