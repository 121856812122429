import { Button, Stack } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useAppSelector } from '../../../app/store/store.config'
import { GOOGLE_CALENDAR_SCOPES } from '../../../constants'
import { envVars } from '../../../constants/envVars'
import { GoogleCalendarState } from '../../../lib/widgets/google-calendar-state/GoogleCalendarState'
import { OnboardingContent } from '../onboardint-styles'
import { OnboardingHeader } from '../shared/OnboardingHeader'
import { usePostHog } from 'posthog-js/react'

export function CalendarConnect() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const posthog = usePostHog()
  const user = useAppSelector((store) => store.auth.user)

  const coachAlreadyAssigned = !!user?.user.coach?.email
  const shouldRedirectToCoachSelection =
    user?.user.isHR && !coachAlreadyAssigned

  const [isGoogleCalendarConnected, setIsGoogleCalendarConnected] =
    useState(false)

  const confirmation = useConfirm()

  const onSyncGoogleCalendar = () => {
    confirmation({
      title: t('important'),
      description: t('calendarMessage'),
      hideCancelButton: true,
      dialogProps: {
        sx: {
          '& .MuiDialogActions-root': {
            display: 'grid',
            justifyContent: 'center',
            gridTemplateColumns: '120px',
          },
        },
      },
    })
      .then(() => {
        const userId = user?.user._id
        const query = '?lastAction=google-calendar&status=success'
        let redirect = '/'
        if (shouldRedirectToCoachSelection) {
          redirect = '/onboarding/coach-selection'
        }

        const params = {
          client_id: envVars.GOOGLE_CLIENT_ID,
          redirect_uri: envVars.GOOGLE_CALLBACK_URL,
          scope: GOOGLE_CALENDAR_SCOPES.join(' '),
          state: JSON.stringify({
            userId,
            redirect: `${redirect}${query}`,
            onboarding: true,
          }),
          access_type: 'offline',
          prompt: 'consent',
          // include_granted_scopes: 'true',
          response_type: 'code',
        }

        const form = document.createElement('form')
        form.setAttribute(
          'action',
          'https://accounts.google.com/o/oauth2/v2/auth',
        )
        form.setAttribute('method', 'GET')

        for (const p in params) {
          const input = document.createElement('input')
          input.setAttribute('type', 'hidden')
          input.setAttribute('name', p)
          input.setAttribute('value', params[p as keyof typeof params])
          form.appendChild(input)
        }

        document.body.appendChild(form)
        form.submit()
        setIsGoogleCalendarConnected(true)

        posthog.capture('google_calendar_synced')
      })
      .catch((error) => {
        posthog.capture('google_calendar_sync_error', {
          error: error.message,
        })
      })
  }

  const onFinish = () => {
    if (shouldRedirectToCoachSelection) {
      navigate('/onboarding/coach-selection')
    } else {
      navigate('/')
    }
  }

  return (
    <OnboardingContent>
      <Stack gap="24px" alignItems="center">
        <OnboardingHeader
          title={t('connectCalendar')}
          subTitle={t('connectCalendarSubtitle')
            .split('/n')
            .map((part) => (
              <span key={part}>{part}</span>
            ))}
        />
        <Stack
          sx={() => ({
            minWidth: '300px',
            padding: '5px 10px',
            borderRadius: '12px',
            marginBottom: '24px',
          })}
        >
          <GoogleCalendarState onClick={onSyncGoogleCalendar} />
        </Stack>
        <Button
          variant="contained"
          className="circular"
          size="large"
          sx={{ minWidth: '260px' }}
          onClick={onFinish}
          disabled={!isGoogleCalendarConnected}
        >
          {t(shouldRedirectToCoachSelection ? 'continue' : 'finish')}
        </Button>
      </Stack>
    </OnboardingContent>
  )
}
