import { envVars } from '../../constants/envVars'

type Params = {
  sessionId: string
  participantId: string
  isCoach: boolean
  referrer?: string
  skipInApp?: boolean
}

export const getUnifiedCallLink = (param: Params) => {
  const { sessionId, participantId, isCoach, referrer, skipInApp } = param
  const baseUrl = `${envVars.BASE_URL}/session/join/${sessionId}/${participantId}`
  const link = new URL(baseUrl)
  link.searchParams.append('coach', isCoach ? '1' : '0')
  if (skipInApp) {
    link.searchParams.append('skipinapp', '1')
  }
  if (referrer) {
    link.searchParams.append('ref', referrer)
  }
  return link.toString()
}
