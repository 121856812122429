import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const SelfAnswers = ({ leadershipValueScores }: any) => {
  const { t } = useTranslation()
  const selfAnswers = leadershipValueScores?.selfAnswers

  return (
    <Box my={4}>
      <Typography fontWeight={700} fontSize="18px" mb={2}>
        {t('self_answers')}
      </Typography>
      {selfAnswers && selfAnswers.length > 0 ? (
        <Box>
          {selfAnswers.map((answer: any, index: any) => (
            <Box key={index} mb={4}>
              <Typography fontSize="16px" fontWeight={600} fontStyle={'italic'}>
                {answer.question}
              </Typography>
              <Typography fontSize="14px">{answer.responses[0]}</Typography>
            </Box>
          ))}
        </Box>
      ) : (
        <Typography>{t('no_responses_yet')}</Typography>
      )}
    </Box>
  )
}
