import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { envVars } from '../../../constants/envVars'
import {
  CheckSlotsDTO,
  CheckSlotsResult,
  CreateSessionDTO,
} from '../../../features/manager/schedules-sessions/dto/scheduling.dto'
import {
  ChatListItem,
  ChatMessages,
  CoachInfo,
  PrivateChatResult,
  Session,
  SessionStats,
  SuggestedDate,
} from '../../../interfaces/main-interfaces'
import {
  GroupMember,
  GroupMemberDTO,
  GroupMembersParams,
} from '../../../lib/widgets/group-members/GroupMembers'
import { RootState } from '../store.config'
import { OnboardingCoach } from '../../../interfaces/coach.interface'
import { AUTH_TAG } from './auth.service'
import {
  UserInfo,
  UserProfile,
} from '../../../interfaces/entities/auth.entities'
import Cookies from 'js-cookie'
import { SELECTED_GROUP_COOKIE_KEY } from '../../../constants'

export interface ChatsResult {
  data: PrivateChatResult[]
  unreadMessagesCount: number
}

export const TAG = {
  SESSIONS: 'SESSIONS',
  BLOCKED_SLOTS: 'BLOCKED_SLOTS',
  GROUP_MEMBERS: 'GROUP_MEMBERS',
  CHAT: 'CHAT',
}

export const managerService = createApi({
  reducerPath: 'managerApi',
  baseQuery: fetchBaseQuery({
    baseUrl: envVars.BASE_URL,
    prepareHeaders: (headers, api) => {
      const store = api.getState() as RootState
      if (store.auth.user) {
        headers.set('Authorization', `Bearer ${store.auth.user.token}`)
      }
      const groupId = Cookies.get(SELECTED_GROUP_COOKIE_KEY)
      if (groupId) {
        headers.set('groupid', groupId)
      }
      return headers
    },
  }),

  tagTypes: [
    TAG.SESSIONS,
    TAG.BLOCKED_SLOTS,
    TAG.GROUP_MEMBERS,
    TAG.CHAT,
    AUTH_TAG.USER,
  ],
  endpoints: (builder) => ({
    checkSlots: builder.query({
      query: (params) => ({
        url: 'manager/check-slot',
        params,
      }),
      providesTags: [TAG.BLOCKED_SLOTS],
    }),

    getSessions: builder.query<
      { data: Session[]; defaultTitle: string; defaultIndividualTitle: string },
      { start: string; end?: string }
    >({
      query: (params) => ({
        url: 'user/sessions',
        params,
      }),
      providesTags: [TAG.SESSIONS],
    }),
    getSessionStats: builder.query<SessionStats, void>({
      query: () => ({
        url: 'user/session-stats',
      }),
      providesTags: [TAG.SESSIONS],
    }),

    updateTimezone: builder.mutation<{ ok: boolean }, { timezone: string }>({
      query: (body) => ({ body, url: 'user/timezone', method: 'POST' }),
      invalidatesTags: [AUTH_TAG.USER],
    }),

    updateProfile: builder.mutation<UserInfo, UserProfile>({
      query: (body) => ({ body, url: 'user/profile', method: 'POST' }),
      invalidatesTags: [AUTH_TAG.USER],
    }),

    getChats: builder.query<ChatsResult, unknown>({
      query: () => ({
        url: `user/chat`,
      }),
      providesTags: [TAG.CHAT],
    }),
    getChat: builder.query<ChatMessages, string>({
      query: (id) => ({
        url: `user/chat/${id}`,
      }),
      providesTags: [TAG.CHAT],
    }),
    createChat: builder.mutation<{ data: ChatListItem }, { memberId: string }>({
      query: (body) => ({
        url: 'user/chat',
        method: 'POST',
        body,
      }),
      invalidatesTags: [TAG.CHAT],
    }),

    createSession: builder.mutation<unknown, CreateSessionDTO>({
      query: (body) => ({
        url: 'manager/book',
        body,
        method: 'POST',
      }),
      invalidatesTags: [TAG.SESSIONS],
    }),

    updateSession: builder.mutation<
      unknown,
      {
        body: CreateSessionDTO
        id: string
      }
    >({
      query: ({ body, id }) => ({
        url: `manager/book/${id}`,
        body,
        method: 'PATCH',
      }),
      invalidatesTags: [TAG.SESSIONS],
    }),

    deleteSession: builder.mutation<{ message: string; ok: boolean }, string>({
      query: (id) => ({
        url: `manager/book/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAG.SESSIONS],
    }),

    checkAvailability: builder.query<
      {
        data: {
          free: SuggestedDate[]
          partial: SuggestedDate[]
        }
      },
      CheckSlotsDTO
    >({
      query: (params) => ({
        url: 'manager/slots',
        params,
        method: 'GET',
      }),
    }),

    getGroupMembers: builder.query<GroupMember[], GroupMembersParams>({
      query: (params) => ({
        url: 'manager/group',
        params,
      }),
      providesTags: [TAG.GROUP_MEMBERS],
    }),

    inviteGroupMember: builder.mutation<unknown, GroupMemberDTO>({
      query: (body) => ({
        url: 'manager/group',
        method: 'POST',
        body,
      }),
      invalidatesTags: [TAG.GROUP_MEMBERS],
    }),

    sendNudge: builder.mutation<void, { email: string }>({
      query: (body) => ({
        url: 'manager/group/nudge',
        method: 'POST',
        body,
      }),
    }),

    getCoachInfo: builder.query<CoachInfo, any>({
      query: (params) => ({
        url: 'user/coachinfo',
        params,
      }),
    }),

    getCoachOptions: builder.query<{ data: OnboardingCoach[] }, void>({
      query: () => ({
        url: 'manager/coach/options',
      }),
    }),

    selectCoach: builder.mutation<{ ok: boolean }, any>({
      query: (body) => ({
        url: 'manager/coach/options',
        body,
        method: 'POST',
      }),
    }),
  }),
})

export const {
  useCheckSlotsQuery,
  useCreateSessionMutation,
  useLazyCheckAvailabilityQuery,
  useGetSessionsQuery,
  useGetSessionStatsQuery,
  useGetChatQuery,
  useCreateChatMutation,
  useDeleteSessionMutation,
  useUpdateSessionMutation,
  useGetGroupMembersQuery,
  useInviteGroupMemberMutation,
  useGetChatsQuery,
  useGetCoachInfoQuery,
  useUpdateTimezoneMutation,
  useUpdateProfileMutation,
  useGetCoachOptionsQuery,
  useSelectCoachMutation,
  useSendNudgeMutation,
} = managerService
