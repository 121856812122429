import { darken, styled } from '@mui/material'

export const ChatBubbleWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  '&.mine': {
    justifyContent: 'flex-end',
  },
  [theme.breakpoints.down('md')]: {
    '.MuiAvatar-root': {
      width: '30px',
      height: '30px',
      font: '500 14px Poppins, sans-serif',
    },
  },
}))

export const ChatBubbleContent = styled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-start',
  gap: '6px',
  '.date': {
    display: 'flex',
    font: '500 12px Poppins, sans-serif',
    textAlign: 'right',
    width: '100%',
    justifyContent: 'flex-end',
  },
}))

export const ChatBubbleMessage = styled('div')(({ theme }) => ({
  display: 'grid',
  alignItems: 'flex-start',
  alignContent: 'flex-start',
  padding: '6px 12px',
  background: theme.palette.background.paper,
  maxWidth: '500px',
  minWidth: '250px',
  borderRadius: '12px 12px 12px 0',
  border: `1px solid ${theme.palette.grey[300]}`,
  '&.mine': {
    background: '#EFFDDE',
    borderRadius: '12px 12px 0 12px',
    border: `1px solid ${darken('#EFFDDE', 0.1)}`,
  },
  img: {
    maxWidth: '100%',
    borderRadius: '12px',
  },
  h4: {
    font: '600 12px Poppins, sans-serif',
    marginBottom: '3px',
  },
  '.messageText': {
    font: '400 14px Poppins, sans-serif',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    a: {
      color: theme.palette.info.main,
      font: '500 14px Poppins, sans-serif',
    },
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: '200px',
    minWidth: '170px',
    padding: '3px 6px',
    '.messageText': {
      font: '400 12px Poppins, sans-serif',
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      a: {
        color: theme.palette.info.main,
        font: '500 12px Poppins, sans-serif',
      },
    },
  },
}))
