import { Button, Container } from '@mui/material'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  useGetCoachInfoQuery,
  useGetSessionStatsQuery,
  useGetSessionsQuery,
} from '../../../app/store/services/manager.service'
import { DEFAULT_TEAM_SESSION_DURATION } from '../../../constants'
import { useAuth } from '../../../hooks/useAuth'
import { DashboardCard } from '../../../lib/shared/dashboard-card/DashboardCard'
import { DashboardCardLink } from '../../../lib/shared/dashboard-card/dashboard-card.style'
import { UpcomingEvent } from '../../../lib/shared/upcoming-event/UpcomingEvent'
import { getStartTime } from '../../../lib/utils/getStartTime'
import { CreateSession } from '../../manager/schedules-sessions/create-session/CreateSession'
import { CreateSessionDTO } from '../../manager/schedules-sessions/dto/scheduling.dto'
import { UpcomingSessionsWrapper } from './upcoming-sessions.style'

export function UpcomingSessions() {
  const { t } = useTranslation()
  const [create, setCreate] = useState<CreateSessionDTO | null>(null)
  const { user } = useAuth()
  const { data: coachInfo } = useGetCoachInfoQuery(
    {},
    { refetchOnMountOrArgChange: true },
  )
  const date = useMemo(
    () => ({
      start: moment(),
      // end: moment().add(30, "days"),
    }),
    [],
  )

  const { data: sessionData } = useGetSessionStatsQuery(void 0, {
    refetchOnMountOrArgChange: true,
  })

  const hasBookingCapability = useMemo(() => {
    return user?.user?.isHR || user?.user?.capabilities?.canBook
  }, [user])

  const canBookSession = useMemo(() => {
    if (!sessionData) return { team: true, individual: true }
    return {
      team: sessionData.remainingSessions.group > 0,
      individual: sessionData.remainingSessions.individual > 0,
    }
  }, [sessionData])

  const { data: sessions } = useGetSessionsQuery(
    {
      start: date.start.toISOString(),
      // end: date.end.toISOString(),
    },
    { refetchOnMountOrArgChange: true },
  )

  const [upcomingSessions, setUpcomingSessions] = useState<any[]>([])
  useEffect(() => {
    if (sessions?.data) {
      const upcoming = [...sessions.data].sort((a, b): any =>
        moment(a.start).isBefore(moment(b.start)) ? -1 : 1,
      )
      setUpcomingSessions(upcoming.slice(0, 5))
    }
  }, [sessions?.data])

  return (
    <DashboardCard title={t('upcomingSessions')}>
      <UpcomingSessionsWrapper>
        {upcomingSessions?.map((event) => (
          <UpcomingEvent
            color="#039be5"
            title={event.title ?? 'No title'}
            id={event._id}
            date={event.start}
            key={event._id}
            joinlink={event.joinlink}
          />
        ))}
        {sessions?.data.length === 0 && <p>{t('noUpcomingSessions')}</p>}
      </UpcomingSessionsWrapper>
      <DashboardCardLink to="/scheduled-sessions">
        {t('viewAllSessions')}
      </DashboardCardLink>
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          maxWidth: '100%',
        }}
      >
        {hasBookingCapability && coachInfo && (
          <Button
            onClick={() =>
              setCreate({
                duration: DEFAULT_TEAM_SESSION_DURATION,
                start: getStartTime().toISOString(),
              })
            }
            variant="contained"
          >
            {t('bookGroupSession')}
          </Button>
        )}
      </Container>
      <CreateSession
        initialData={create}
        open={!!create}
        onClose={() => setCreate(null)}
        defaultTitle={sessions?.defaultTitle || ''}
        defaultIndividualTitle={sessions?.defaultIndividualTitle || ''}
        canBookSession={canBookSession}
      />
    </DashboardCard>
  )
}
