import { styled } from '@mui/material'

export const SessionStatsWrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr  1fr  1fr',
  gap: '10px',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr',
  },
}))

export const SessionStatItem = styled('div')(({ theme }) => ({
  // background: theme.palette.background.paper,
  filter: 'var(--card-shadow)',
  padding: '10px',
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gap: '8px',
  [theme.breakpoints.down('md')]: {
    padding: '12px',
  },
  borderRadius: '12px',
  '.statContent': {
    display: 'grid',
    p: {
      margin: 0,
      font: "500 12px 'Poppins', sans-serif",
      lineHeight: '14px',
      marginBottom: '5px',
    },
    h2: {
      margin: 0,
      font: "600 24px 'Poppins', sans-serif",
      lineHeight: '28px',
    },
    a: {
      font: "600 12px 'Poppins', sans-serif",
      lineHeight: '14px',
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
  },
}))
