import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  AssessmentQuestion,
  AssessmentValue,
} from '../../../interfaces/main-interfaces'

export interface IUI {
  sidebarVisible: boolean
}

const initialState: IUI = {
  sidebarVisible: true,
}

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setSidebarVisible: (state, action) => {
      state.sidebarVisible = action.payload
    },
  },
})

export default uiSlice

export const { setSidebarVisible } = uiSlice.actions
