import { styled } from '@mui/material'
import { Link } from 'react-router-dom'

export const DashboardCardWrapper = styled('div')(({ theme }) => ({
  padding: '22px',
  background: theme.palette.background.paper,
  filter: 'var(--card-shadow)',
  borderRadius: '12px',
  display: 'grid',
  gap: '14.81px',
  alignContent: 'flex-start',
  alignItems: 'flex-start',
  [theme.breakpoints.down('md')]: {
    padding: '12px',
  },
}))

export const DashboardCardTitle = styled('h2')(() => ({
  font: "600 18px 'Poppins', sans-serif",
  lineHeight: '24px',
}))

export const DashboardCardLink = styled(Link)(({ theme }) => ({
  font: "600 14px 'Poppins', sans-serif",
  textDecoration: 'none',
  lineHeight: '14px',
  color: theme.palette.primary.main,
  marginTop: '6px',
  whiteSpace: 'nowrap',
}))
