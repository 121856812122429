import { Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { usePostHog } from 'posthog-js/react'
import { useEffect } from 'react'

const MeetingEnded = ({ sessionId, roomName }: any) => {
  const { t } = useTranslation()
  const posthog = usePostHog()

  useEffect(() => {
    posthog.capture('inapp_meeting_finish', {
      sessionId,
      roomName,
    })
  }, [posthog, sessionId, roomName])

  return (
    <div className="h-full w-full p-4 flex flex-col bg-black text-white overflow-hidden justify-center">
      <Text align={'center'}>{t('meeting_ended')}</Text>
    </div>
  )
}

export default MeetingEnded
