import { alpha, styled } from '@mui/material'

export const HighlightsWrapper = styled('div')(({ theme }) => ({
  '.body': {
    padding: '12px 0',
    display: 'grid',
    gap: '24px',
    '.summaryItem': {
      background: theme.palette.background.paper,
      border: `1px solid ${theme.palette.divider}`,
      overflow: 'hidden',
      borderRadius: '12px',
      '.summaryHeader': {
        borderBottom: `1px solid ${theme.palette.divider}`,
        padding: '16px',
        display: 'grid',
        background: alpha(theme.palette.primary.main, 0.1),
        h3: {
          font: '600 20px Poppins, sans-serif',
        },
        p: {
          font: '500 12px Poppins, sans-serif',
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
          svg: {
            fontSize: '18px',
          },
        },
      },
      '.summaryBody': {
        padding: '16px',
        display: 'grid',
        p: {
          '&.title': {
            font: '600 14px Poppins, sans-serif',
            '&:not(:first-of-type)': {
              marginTop: '16px',
            },
          },
          font: '400 14px Poppins, sans-serif',
          lineHeight: '24px',
        },
      },
    },
  },
}))
