import { styled } from '@mui/material'

export const UpcomingEventWrapper = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '5px',
  borderLeft: '5px solid var(--border-color)',
  padding: '6px 12px',
  position: 'relative',
  div: {
    display: 'grid',
    gap: '6px',
    h4: {
      font: '500 14px Poppins, sans-serif',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    p: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      font: '500 13px Poppins, sans-serif',
      color: theme.palette.grey[600],
    },
    a: {
      font: '500 12px Poppins, sans-serif',
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
  },
  button: {
    position: 'absolute',
    alignSelf: 'center',
    right: 12,
    padding: '3px 15px',
    border: `1px solid ${theme.palette.primary.main}`,
    background: 'transparent',
    borderRadius: '100px',
    font: '600 14px Poppins, sans-serif',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    transition: '.2s',
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      marginLeft: '12px',
      marginTop: '6px',
      marginBottom: '3px',
      borderRadius: '12px',
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
}))
