import { useEffect, useState } from 'react'
import type { ApexOptions } from 'apexcharts'
import ReactApexChart from 'react-apexcharts'
import { LeadershipValueTable } from './leadershipvalue.style'
import { useMediaQuery } from 'react-responsive'

const splitText = (text: string) => {
  const words = text.split(' ')
  const result = []
  let line: any = []
  words.forEach((word) => {
    if (line.join(' ').length + word.length > 18) {
      result.push(line.join(' '))
      line = []
    }
    line.push(word)
  })
  result.push(line.join(' '))
  return result
}

export const LeadershipValueChart = ({
  leadershipValueScores,
  showTable = false,
  chartWidth = 900,
}: any) => {
  const isMobileScreen = useMediaQuery({ query: '(max-width: 640px)' })
  const [series, setSeries] = useState<any>([])
  const [options, setOptions] = useState<any>({})

  const hasManagerData =
    leadershipValueScores.scores.managers &&
    Object.keys(leadershipValueScores.scores.managers).length > 0
  const hasReporteeData =
    leadershipValueScores.scores.reportees &&
    Object.keys(leadershipValueScores.scores.reportees).length > 0

  useEffect(() => {
    if (!leadershipValueScores?.scores) {
      return
    }

    const series = [
      {
        name: 'Self',
        data: Object.keys(leadershipValueScores.scores.self).map((key: any) =>
          leadershipValueScores.scores.self[key]
            ? leadershipValueScores.scores.self[key].toFixed(1)
            : '-',
        ),
      },
    ]
    if (hasManagerData) {
      series.push({
        name: 'Manager',
        data: Object.keys(leadershipValueScores.scores.managers).map(
          (key: any) =>
            leadershipValueScores.scores.managers[key]
              ? leadershipValueScores.scores.managers[key].toFixed(1)
              : '-',
        ),
      })
    }
    if (hasReporteeData) {
      series.push({
        name: 'Reportees (average)',
        data: Object.keys(leadershipValueScores.scores.reportees).map(
          (key: any) =>
            leadershipValueScores.scores.reportees[key]
              ? leadershipValueScores.scores.reportees[key].toFixed(1)
              : '-',
        ),
      })
    }
    setSeries(series)

    const options: ApexOptions = {
      chart: {
        height: 450,
        type: 'bar',
        // dropShadow: {
        //   enabled: true,
        //   blur: 1,
        //   left: 1,
        //   top: 1,
        // },
        toolbar: {
          show: false,
        },
      },
      colors: ['#822c42', '#1b3668'],
      stroke: {
        width: 2,
      },
      fill: {
        opacity: 1,
      },
      markers: {
        size: 0,
      },
      xaxis: {
        categories: Object.keys(leadershipValueScores.values).map((key: any) =>
          splitText(leadershipValueScores.values[key]),
        ),
      },
      yaxis: {
        labels: {
          formatter: function (value: number) {
            return value ? value.toFixed(1) : '-'
          },
        },
        max: 6,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: 'top',
          },
        },
      },
      responsive: [
        {
          breakpoint: 768, // Typical tablet breakpoint
          options: {
            chart: {
              height: 350,
            },
            stroke: {
              width: 1,
            },
            plotOptions: {
              bar: {
                horizontal: true,
              },
            },
            xaxis: {
              labels: {
                show: false,
              },
            },
            yaxis: {
              labels: {
                show: true,
              },
            },
          },
        },
        {
          breakpoint: 480, // Typical mobile breakpoint
          options: {
            chart: {
              height: 300,
            },
            stroke: {
              width: 0.5,
            },
            plotOptions: {
              bar: {
                horizontal: true,
              },
            },
            xaxis: {
              labels: {
                show: false,
              },
            },
            yaxis: {
              labels: {
                show: true,
              },
            },
          },
        },
      ],
    }
    setOptions(options)
  }, [leadershipValueScores])

  return showTable ? (
    <LeadershipValueTable>
      <thead>
        <tr>
          <th>Values</th>
          <th> Self </th>
          {hasManagerData && <th> Manager </th>}
          {hasReporteeData && <th> Reportees (average) </th>}
        </tr>
      </thead>
      <tbody>
        {leadershipValueScores &&
          Object.keys(leadershipValueScores.values).map((key) => (
            <tr key={key}>
              <td
                style={{
                  fontWeight: 600,
                  textAlign: 'left',
                }}
              >
                {leadershipValueScores.values[key]}
              </td>
              <td style={{ textAlign: 'center' }}>
                {Object.keys(leadershipValueScores.scores.self).length > 0 &&
                leadershipValueScores.scores.self[key]
                  ? parseFloat(
                      leadershipValueScores.scores.self[key].toFixed(1),
                    )
                  : '-'}
              </td>
              {hasManagerData && (
                <td style={{ textAlign: 'center' }}>
                  {Object.keys(leadershipValueScores.scores.managers).length >
                    0 && leadershipValueScores.scores.managers[key]
                    ? parseFloat(
                        leadershipValueScores.scores.managers[key].toFixed(1),
                      )
                    : '-'}
                </td>
              )}
              {hasReporteeData && (
                <td style={{ textAlign: 'center' }}>
                  {Object.keys(leadershipValueScores.scores.reportees).length >
                    0 && leadershipValueScores.scores.reportees[key]
                    ? parseFloat(
                        leadershipValueScores.scores.reportees[key].toFixed(1),
                      )
                    : '-'}
                </td>
              )}
            </tr>
          ))}
      </tbody>
    </LeadershipValueTable>
  ) : (
    <ReactApexChart
      options={options}
      series={series}
      type="bar"
      width={isMobileScreen ? 450 : chartWidth || 900}
    />
  )
}
