import { useTranslation } from 'react-i18next'
import { GroupMembersTable } from './group-members.style'
import { Cancel, CheckCircle } from '@mui/icons-material'
import { useAuth } from '../../../hooks/useAuth'
import { Button, Tooltip } from '@mui/material'
import { InviteMember } from '../../../features/group-member/InviteMember'
import { useState } from 'react'
import { Info } from 'react-feather'

export interface GroupMember {
  name: string
  email: string
  title: string
  picture: string
  timezone: string
  calendarConnected: boolean
  calendarScope?: {
    canCheckAvailability: boolean
    canInsertEvents: boolean
  }
  registered: boolean
  _id: string
}

export interface GroupMemberDTO {
  title: string
  email: string
  name: string
}

export interface GroupMemberAssessmentStatus {
  _id: string
  name: string
  email: string
  title: string
  group: string
  completed: boolean
}

export interface GroupMembersParams {
  includeInvited?: string
}

const getStatusSubClassName = (member: GroupMember) => {
  if (!member.registered) {
    return ''
  }
  if (
    member.calendarScope?.canCheckAvailability &&
    member.calendarScope?.canInsertEvents
  ) {
    return 'connected'
  }
  if (
    member.calendarScope?.canCheckAvailability ||
    member.calendarScope?.canInsertEvents
  ) {
    return 'partiallyConnected'
  }
  return 'notConnected'
}

const getStatusText = (member: GroupMember) => {
  if (!member.registered) {
    return 'notRegistered'
  }
  if (
    member.calendarScope?.canCheckAvailability &&
    member.calendarScope?.canInsertEvents
  ) {
    return 'calendarConnected'
  }
  if (
    member.calendarScope?.canCheckAvailability ||
    member.calendarScope?.canInsertEvents
  ) {
    return 'partiallyConnected'
  }

  return 'notConnected'
}

const getIcon = (member: GroupMember, t: any) => {
  if (!member.registered) {
    return <></>
  }

  if (
    member.calendarScope?.canCheckAvailability &&
    member.calendarScope?.canInsertEvents
  ) {
    return <CheckCircle />
  }
  if (
    member.calendarScope?.canCheckAvailability ||
    member.calendarScope?.canInsertEvents
  ) {
    const title = member.calendarScope?.canCheckAvailability
      ? t('canOnlyCheckAvailability')
      : t('canOnlyInsertEvents')
    return (
      <Tooltip title={title}>
        <Info />
      </Tooltip>
    )
  }

  return <Cancel />
}

export function GroupMembers({ members }: { members: GroupMember[] }) {
  const { t } = useTranslation()
  const { user } = useAuth()
  const shouldShowStatusColumn =
    user?.user.isHR || user?.user.capabilities?.canInviteMember === true

  const nonRegisteredStyle = { color: '#44444444' }
  const [inviteMember, setInviteMember] = useState<any>(null)

  return (
    <>
      <GroupMembersTable>
        <thead>
          <tr>
            <th>#</th>
            <th> {t('name')} </th>
            <th> {t('title')} </th>
            <th> {t('email')} </th>
            <th> {t('timezone')} </th>
            {shouldShowStatusColumn && <th> {t('status')} </th>}
          </tr>
        </thead>
        <tbody>
          {members.map((member, index) => (
            <tr key={`member_${index}`}>
              <td style={member.registered ? {} : nonRegisteredStyle}>
                {index + 1}
              </td>
              <td>
                <div>
                  <p style={member.registered ? {} : nonRegisteredStyle}>
                    {member.name}
                  </p>
                </div>
              </td>
              <td style={member.registered ? {} : nonRegisteredStyle}>
                {member.title}
              </td>
              <td style={member.registered ? {} : nonRegisteredStyle}>
                {member.email}
              </td>
              <td style={member.registered ? {} : nonRegisteredStyle}>
                {member.timezone}
              </td>
              {shouldShowStatusColumn && (
                <td style={member.registered ? {} : nonRegisteredStyle}>
                  <span
                    className={`calendarStatus ${getStatusSubClassName(
                      member,
                    )}`}
                  >
                    {getIcon(member, t)}
                    {t(getStatusText(member))}
                    {!member.registered && (
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => setInviteMember(member)}
                      >
                        {t('resendInvitation')}
                      </Button>
                    )}
                  </span>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </GroupMembersTable>
      {inviteMember && (
        <InviteMember
          onClose={() => setInviteMember(null)}
          member={inviteMember}
        />
      )}
    </>
  )
}
