import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import Cookies from 'js-cookie'
import { SELECTED_GROUP_COOKIE_KEY } from '../../../constants'
import { envVars } from '../../../constants/envVars'
import {
  GoogleAuthResult,
  UserInfo,
} from '../../../interfaces/entities/auth.entities'
import { RootState } from '../store.config'

export const AUTH_TAG = {
  USER: 'USER',
}

export const authService: any = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: envVars.BASE_URL,
    prepareHeaders: (headers, api) => {
      const store = api.getState() as RootState
      if (store.auth.user) {
        headers.set('Authorization', `Bearer ${store.auth.user.token}`)
      }
      const groupId = Cookies.get(SELECTED_GROUP_COOKIE_KEY)
      if (groupId) {
        headers.set('groupid', groupId)
      }
      return headers
    },
  }),
  tagTypes: [AUTH_TAG.USER],
  endpoints: (builder) => ({
    googleAuth: builder.mutation<
      GoogleAuthResult,
      { code: string; timeZone: string }
    >({
      query: (body) => ({
        url: 'user/google/auth',
        body,
        method: 'POST',
      }),
    }),
    googleSignIn: builder.mutation<
      GoogleAuthResult,
      { accessToken: string; timeZone: string }
    >({
      query: (body) => ({
        url: 'user/google/sign-in',
        body,
        method: 'POST',
      }),
    }),
    checkUser: builder.mutation<GoogleAuthResult, { email: string }>({
      query: (params) => ({
        url: 'user/check',
        params,
      }),
    }),
    getMe: builder.query<UserInfo, void>({
      query: () => ({
        url: 'user/me',
      }),
      providesTags: [AUTH_TAG.USER],
    }),
    getMeReq: builder.mutation<UserInfo, void>({
      query: () => ({
        url: 'user/me',
        method: 'GET',
      }),
    }),
    login: builder.mutation<
      GoogleAuthResult,
      { email: string; adminSecret: string }
    >({
      query: (body) => ({
        url: 'user/login',
        body,
        method: 'POST',
      }),
    }),
  }),
})

export const {
  useGoogleAuthMutation,
  useGoogleSignInMutation,
  useCheckUserMutation,
  useGetMeQuery,
  useGetMeReqMutation,
  useLoginMutation,
} = authService
