import { styled } from '@mui/material'

export const AssessmentItemWrapper = styled('div')(() => ({
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
  height: '100dvh',
  background: 'url(/connect.png)',
  // boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.5)",
}))

export const AssessmentLoader = styled('div')(() => ({
  width: '100vw',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))
