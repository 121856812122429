import {
  Box,
  createIcon,
  Icon,
  Text,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react'
import { DyteSpinner } from '@dytesdk/react-ui-kit'
import { usePostHog } from 'posthog-js/react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BsCameraVideoFill } from 'react-icons/bs'
import { FaMicrophone } from 'react-icons/fa'

const Initializing = ({
  sessionId,
  roomName,
  micAccess,
  cameraAccess,
}: any) => {
  const { t } = useTranslation()
  const posthog = usePostHog()

  useEffect(() => {
    posthog.capture('inapp_meeting_loading', {
      sessionId,
      roomName,
    })
  }, [posthog, sessionId, roomName])

  const isMobileScreen = useMediaQuery('(max-width: 640px)')[0]

  return (
    <div className="h-full w-full p-4 flex flex-col bg-black text-white overflow-hidden justify-center position-relative">
      {(!micAccess || !cameraAccess) && !isMobileScreen && (
        <>
          <div className="dotted-line"></div> {/* Dotted line */}
          <div className="big-arrow">
            <Arrow boxSize={50} color="white" viewBox="0 0 100 100" />{' '}
            {/* Big arrow */}
          </div>
        </>
      )}
      <VStack fontSize={'xl'} spacing={4}>
        <Box w="full" h="full" bg="black" borderRadius="md" p={4}>
          {(!micAccess || !cameraAccess) && (
            <>
              <Text fontSize="2xl" fontWeight="bold" ml={'150px'}>
                <Icon as={BsCameraVideoFill} /> Allow access to camera and{' '}
                <Icon as={FaMicrophone} /> microphone on your browser
              </Text>
            </>
          )}
        </Box>
        <Text>{t('initializing_meeting')}</Text>
        <DyteSpinner />
      </VStack>
    </div>
  )
}

const Arrow = createIcon({
  displayName: 'Arrow',
  viewBox: '0 0 100 100 ', // Adjusted for a larger display
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50 10 L90 90 L50 70 L10 90 Z" // Modified to point upwards
      fill="currentColor"
    />
  ),
})

export default Initializing
