import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Logo } from './Logo'
import { usePostVerifyHupoHighlightsSummaryMutation } from '../../app/store/services/coach.service'

const HupoHighlightsVerify: FC<any> = () => {
  const { t } = useTranslation()
  const { sessionId } = useParams<{ sessionId: string }>()
  const [postHupoHighlightsSummaryVerify] =
    usePostVerifyHupoHighlightsSummaryMutation()

  useEffect(() => {
    postHupoHighlightsSummaryVerify({ sessionId }).unwrap().catch(console.log)
  }, [sessionId, postHupoHighlightsSummaryVerify])

  return (
    <Box flexDirection={'column'} textAlign="center" px={6} pb={10}>
      <Flex
        px={{ base: 4, md: 4 }}
        height="20"
        borderBottomWidth="1px"
        alignItems="center"
        justifyContent="center"
      >
        <Logo md={5} />
      </Flex>

      <Flex
        alignItems="center"
        justifyContent="center"
        padding={10}
        direction="column"
      >
        <Heading
          display="inline-block"
          as="h3"
          size="lg"
          textAlign={'center'}
          mb={10}
        >
          {t('summary-verified')}
        </Heading>
        <Text textAlign={'center'} mb={10}>
          {t('summary-verified-close-tab')}
        </Text>
      </Flex>
    </Box>
  )
}

export default HupoHighlightsVerify
