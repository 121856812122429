import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { PeerAssessmentItem } from '../../../interfaces/main-interfaces'

export const coreApi = createApi({
  reducerPath: 'coreApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.hupo.co/v2',
  }),
  endpoints: (builder) => ({
    getPeerAssessment: builder.query<
      PeerAssessmentItem[],
      Record<string, string>
    >({
      query: (params) => ({
        url: `/user/assessment/questions`,
        params,
      }),
    }),
  }),
})

export const { useGetPeerAssessmentQuery } = coreApi
