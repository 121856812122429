import { Flex, Spinner } from '@chakra-ui/react'
import { FC, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Navigate,
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { toast } from 'sonner'

import { useLazyGetAssessmentQuestionsQuery } from '../../app/store/services/assessment.service'
import { setAssessmentQuestions } from '../../app/store/slices/assessmentSlice'
import { useAppSelector } from '../../app/store/store.config'
import { useAuth } from '../../hooks/useAuth'

const AssessmentIntro: FC<any> = ({ type }) => {
  const auth = useAuth()
  const isAuthenticated = !!auth?.user
  const user = useAppSelector((state) => state.assessment.user)
  const params = useParams() as any
  const { userId, language, authorEmail, index } = params
  const pathname = useMemo(() => location.pathname, [])
  const [searchParams] = useSearchParams()

  const [triggerGetAssessment, { data, isLoading, error }] =
    useLazyGetAssessmentQuestionsQuery()
  const [triggerProcessed, setTriggerProcessed] = useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isAuthenticated) return
    triggerGetAssessment({
      companyId: user?.company._id,
      language: language ?? user?.language,
      type,
      departmentId: user?.department?._id,
    })
    setTriggerProcessed(true)
  }, [
    authorEmail,
    userId,
    language,
    triggerGetAssessment,
    user,
    type,
    isAuthenticated,
  ])

  useEffect(() => {
    if (error) {
      toast.error('Error fetching assessment questions')
      console.log(error)
    } else if (triggerProcessed && !isLoading) {
      dispatch(setAssessmentQuestions(data))
      navigate(`/assessment/${index ?? 0}`)
    }
  }, [data, index, isLoading, triggerProcessed, error, dispatch, navigate])

  if (!isAuthenticated) {
    console.log('Not authenticated, redirecting to login')
    return (
      <Navigate to={`/login?nextURL=${pathname}?${searchParams.toString()}`} />
    )
  }

  if (isLoading) {
    return (
      <Flex alignItems="center" justifyContent="center">
        <Spinner size="4xl" />
      </Flex>
    )
  }

  return <Outlet />
}

export default AssessmentIntro
