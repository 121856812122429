import { useTranslation } from 'react-i18next'
import { WhatWeDoInfo } from './what-we-do.style'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { OnboardingContent } from '../onboardint-styles'
import { OnboardingHeader } from '../shared/OnboardingHeader'

export function WhatWeDo() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <OnboardingContent>
      <OnboardingHeader
        title={
          <>
            {t('whatWeDoTitle')
              .split('/n')
              .map((part) => (
                <span key={part}> {part} </span>
              ))}
          </>
        }
      />

      <WhatWeDoInfo>
        <p>
          {t('whatWeDoDescription')
            .split('/n')
            .map((part) => (
              <span key={part}> {part} </span>
            ))}
        </p>
        <p>
          {t('whatWeDoSecondDescription')
            .split('/n')
            .map((part) => (
              <span key={part}> {part} </span>
            ))}
        </p>
      </WhatWeDoInfo>
      <Button
        sx={{ minWidth: '260px' }}
        className="circular"
        variant="contained"
        size="large"
        onClick={() => navigate('getting-to-know')}
      >
        {t('continue')}
      </Button>
    </OnboardingContent>
  )
}
