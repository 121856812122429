import { StyleFunctionProps, extendTheme } from '@chakra-ui/react'

export const chakraTheme = extendTheme({
  styles: {
    global: {
      body: {
        color: '#292F36',
        bg: '#F7F8F9',
      },
    },
  },
  colors: {
    primary: {
      main: '#ff480b',
      contrastText: '#ffffff',
      dark: '#cb2800',
      light: '#fde9e6',
      '50': '#fde9e6',
      '100': '#ffcbb9',
      '200': '#ffa98d',
      '300': '#ff875e',
      '400': '#ff6b39',
      '500': '#ff4e10',
      '600': '#ff480b',
      '700': '#f14004',
      '800': '#e33800',
      '900': '#cb2800',
    },
  },
})
