import { useTranslation } from 'react-i18next'
import { GoogleCalendarStateWrapper } from './google-calendar-state.style'
import { Button } from '@mui/material'
import { useAuth } from '../../../hooks/useAuth'

interface Props {
  onClick: () => void
}

export function GoogleCalendarState(props: Props) {
  const { t } = useTranslation()
  const { user } = useAuth()

  return (
    <GoogleCalendarStateWrapper>
      <div>
        <img src="/google-calendar.png" alt="Google Calendar" />
        <h4> {t('googleCalendar')} </h4>
      </div>
      <Button
        disabled={user?.user.isCalendarSynced}
        variant="outlined"
        onClick={props.onClick}
      >
        {t(user?.user.isCalendarSynced ? 'connected' : 'connect')}
      </Button>
    </GoogleCalendarStateWrapper>
  )
}
