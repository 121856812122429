import { styled } from '@mui/material'

export const CoachSelectionItem = styled('div')(({ theme }) => ({
  padding: '12px',
  display: 'grid',
  gap: '12px',
  background: theme.palette.background.paper,
  borderRadius: '12px',
  border: `1px solid ${theme.palette.divider}`,
  '.top': {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  '.bottom': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '12px',
    button: {
      height: '35px',
    },
  },
}))
