import { styled } from '@mui/material'

export const ChatWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: '12px',
  background: theme.palette.background.paper,
  height: 'calc(100dvh - 100px)',
  maxHeight: 'calc(100dvh - 100px)',
  position: 'relative',
  overflow: 'hidden',
  [theme.breakpoints.down('xl')]: {
    gridTemplateColumns: '1fr 2fr',
  },
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr 1.5fr',
  },
  [theme.breakpoints.down('md')]: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    background: 'transparent',
    borderRadius: 0,
    border: 'none',
    height: 'calc(100dvh - 85px)',
    maxHeight: 'calc(100dvh - 85px)',
  },
}))

export const EmptyChatBox = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  pointerEvents: 'none',
  gap: '24px',
  img: {
    width: '350px',
    [theme.breakpoints.down('md')]: {
      width: '200px',
    },
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))
