import {
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { BsTrash } from 'react-icons/bs'

import { Card } from './Card'
import { DeletionAlert } from './DeletionAlert'
import { CalendarConfig } from './CalendarConfig'
import { useTranslation } from 'react-i18next'
import {
  useDeleteExternalCalendarMutation,
  useGetCalendarsQuery,
} from '../../../app/store/services/calendar.service'
import { useNavigate } from 'react-router-dom'
import GoogleCalendar from '../../../assets/icons/google-calendar.svg'

export const MyCalendar = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const { t } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()
  const [selectedCalendar, setSelectedCalendar] = useState<any>(null)

  const {
    data: calendars,
    isLoading: isCalendarsLoading,
    refetch: refetchCalendars,
  } = useGetCalendarsQuery('calendars')
  const [
    deleteCalendar,
    { isError: isDeleteCalendarFailed, isSuccess: isDeleteCalendarSucceeded },
  ] = useDeleteExternalCalendarMutation()

  useEffect(() => {
    const toastTitle = isDeleteCalendarSucceeded ? 'Success' : 'Error'
    const toastDescription = isDeleteCalendarSucceeded
      ? 'Calendar deleted'
      : 'Failed to delete calendar'
    const toastStatus = isDeleteCalendarSucceeded ? 'success' : 'error'

    if (isDeleteCalendarSucceeded || isDeleteCalendarFailed) {
      toast({
        title: toastTitle,
        description: toastDescription,
        status: toastStatus,
        duration: 2000,
        isClosable: true,
      })
    }
  }, [isDeleteCalendarSucceeded, isDeleteCalendarFailed, toast])

  const onAddCalendar = () => {
    navigate('/settings/new-calendar')
  }

  const onDeleteCalendar = (calendar: any) => {
    setSelectedCalendar(calendar)
    onOpen()
  }

  const onConfirmDelete = async () => {
    if (!selectedCalendar) {
      toast({
        title: 'Error',
        description: 'No calendar selected',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
      return
    }

    await deleteCalendar(selectedCalendar.id).unwrap()
    refetchCalendars()
    onClose()
  }

  console.log({ calendars })

  return (
    <Box pb={10}>
      <Card>
        <HStack justify="space-between">
          <Text>{t('my_calendar_account')}</Text>

          <Button onClick={onAddCalendar} colorScheme="primary">
            <Icon as={AiOutlinePlus} me="2" />
            {t('add_calendar_account')}
          </Button>
        </HStack>
        <Box mt={3} mb={6} h="1px" backgroundColor="#eee" />
        {isCalendarsLoading && <Text>{t('loading')}</Text>}
        {!isCalendarsLoading && calendars && calendars.length === 0 && (
          <Text>{t('no_calendar')}</Text>
        )}
        {calendars &&
          calendars.length > 0 &&
          calendars.map((cal: any) => (
            <Item
              key={cal.id}
              _id={cal.id}
              type={cal.integrationType}
              id={cal.externalId}
              onDelete={() => onDeleteCalendar(cal)}
            />
          ))}
      </Card>
      <Box my="6" />
      {!isCalendarsLoading && calendars && calendars.length > 0 && (
        <CalendarConfig
          calendars={calendars}
          refetchCalendars={refetchCalendars}
        />
      )}
      <DeletionAlert
        isOpen={isOpen}
        onClose={onClose}
        leastDestructiveRef={cancelRef}
        onConfirm={onConfirmDelete}
        id={selectedCalendar ? selectedCalendar.externalId : ''}
      />
    </Box>
  )
}

const Item = ({ _id, id, type, onDelete }: any) => {
  const secondaryTextColor = '#999'
  const calendarType = type.toLowerCase()

  return (
    <HStack
      borderRadius="md"
      border="1px solid #eee"
      p="4"
      justify="space-between"
      mb="3"
    >
      <HStack spacing="4">
        {calendarType === 'google' && (
          <img src={GoogleCalendar} alt="" style={{ width: 32 }} />
        )}
        <Box>
          <Text textTransform="capitalize">{calendarType}</Text>
          <Text fontSize={14} color={secondaryTextColor}>
            {id}
          </Text>
        </Box>
      </HStack>
      <IconButton
        aria-label={`Remove ${id}`}
        icon={<BsTrash />}
        onClick={onDelete}
      />
    </HStack>
  )
}
//
