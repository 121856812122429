import { alpha, darken, styled } from '@mui/material'

export const ResourceCardWrapper = styled('div')(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: '12px',
  border: `1px solid ${theme.palette.divider}`,
  position: 'relative',
  display: 'grid',
  overflow: 'hidden',
  ':hover': {
    borderColor: theme.palette.grey[400],
  },
  '.resourceBody': {
    display: 'grid',
    gridTemplateRows: '1fr auto',

    '.resourceContent': {
      display: 'grid',
      alignItem: 'flex-start',
      alignContent: 'flex-start',

      '.resourceTop': {
        padding: '12px 12px 0 12px',
        minHeight: '70px',
        h3: {
          font: '500 17px Poppins, sans-serif',
        },
        p: {
          font: '600 13px Poppins, sans-serif',
          color: theme.palette.primary.main,
        },
      },

      '.resourceBottom': {
        display: 'grid',
        gap: '4px',
        padding: '6px 12px 12px 12px',
        '.badge': {
          background: theme.palette.primary.light,
          width: 'fit-content',
          font: '600 12px Poppins, sans-serif',
          padding: '3px 12px',
          borderRadius: '3px',
          marginBottom: '8px',
          border: `1px solid ${darken(theme.palette.primary.light, 0.1)}`,
        },
        '.summary': {
          font: '400 14px Poppins, sans-serif',
          lineHeight: '20px',
        },
        '.category': {
          font: '600 13px Poppins, sans-serif',
          color: theme.palette.primary.main,
        },
      },
    },

    '.resourceFooter': {
      display: 'grid',
      padding: '12px',
      gridTemplateColumns: '1fr 100px',
      gap: '12px',
    },
  },
  '.resourceStatusPaid': {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '3px 8px',
    background: theme.palette.error.main,
    borderRadius: '0 0 0 12px',
    color: theme.palette.getContrastText(theme.palette.error.main),
    font: '700 12px Poppins, sans-serif',
    textTransform: 'uppercase',
  },
  '.resourceStatusIncluded': {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '3px 8px',
    background: theme.palette.success.main,
    borderRadius: '0 0 0 12px',
    color: theme.palette.getContrastText(theme.palette.success.main),
    font: '700 12px Poppins, sans-serif',
    textTransform: 'uppercase',
  },
  '&.internal': {
    background: `linear-gradient(to bottom, transparent ,${alpha(
      theme.palette.warning.light,
      0.2,
    )})`,
  },
  '.MuiButton-outlined': {
    background: theme.palette.background.paper,
  },
  '.pinned': {
    position: 'absolute',
    top: 0,
    right: 0,
    background: theme.palette.primary.main,
    padding: '3px',
    borderRadius: '0 0 0 12px',
    color: theme.palette.primary.contrastText,
  },
}))

export const ResourcesList = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(4,1fr)',
  gap: '24px',
  [theme.breakpoints.down('xl')]: {
    gridTemplateColumns: 'repeat(3,1fr)',
    gap: '20px',
  },
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: 'repeat(2,1fr)',
    gap: '16px',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    gap: '12px',
  },
}))

export const ResourceLoading = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  position: 'relative',
  gap: '24px',
  minHeight: '400px',
  borderRadius: '12px',
  h3: {
    font: '300 18px Poppins, sans-serif',
  },
}))

export const SearchWrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  position: 'sticky',
  top: 70,
  padding: '12px 0',
  background: theme.palette.background.default,
  zIndex: 1,
  borderRadius: '0 0 12px 12px',
  '.MuiInputBase-root': {
    background: theme.palette.background.paper,
  },
  [theme.breakpoints.down('lg')]: {
    top: 60,
  },
}))

export const SuggestionsWrapper = styled('div')(({ theme }) => ({
  '.body': {
    padding: '12px 0',
    display: 'grid',
    gap: '24px',
    '.summaryItem': {
      background: theme.palette.background.paper,
      border: `1px solid ${theme.palette.divider}`,
      overflow: 'hidden',
      borderRadius: '12px',
      '.summaryHeader': {
        borderBottom: `1px solid ${theme.palette.divider}`,
        padding: '16px',
        display: 'grid',
        background: alpha(theme.palette.primary.main, 0.1),
        h3: {
          font: '600 20px Poppins, sans-serif',
        },
        p: {
          font: '500 12px Poppins, sans-serif',
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
          svg: {
            fontSize: '18px',
          },
        },
      },
      '.summaryBody': {
        padding: '16px',
        display: 'grid',
        p: {
          '&.title': {
            font: '600 14px Poppins, sans-serif',
            '&:not(:first-of-type)': {
              marginTop: '16px',
            },
          },
          font: '400 14px Poppins, sans-serif',
          lineHeight: '24px',
        },
      },
    },
  },
}))
