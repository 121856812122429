import { createBrowserRouter, Outlet } from 'react-router-dom'

import { ChatPage } from './features/chat/ChatPage'
import { Dashboard } from './features/dashboard/Dashboard'
import { ResourcesLibrary } from './features/library/ResourcesLibrary'
import { ScheduledSessions } from './features/manager/schedules-sessions/ScheduledSessions'
import { CalendarConnect } from './features/onboarding/calendar-connect/CalendarConnect'
import { GoogleConnect } from './features/onboarding/google-connect/GoogleConnect'
import { WhatWeDo } from './features/onboarding/what-we-do/WhatWeDo'
import { GroupMembersPage } from './features/group-member/GroupMembersPage'
import { MainLayout } from './layout/main/MainLayout'
import { OnboardingLayout } from './layout/onboarding/OnboardingLayout'
import InAppMeeting from './features/dashboard/meeting/InAppMeeting'
import { ProgressPage } from './features/progress/ProgressPage'
import { LoginPage } from './features/login/LoginPage'
import { CoachSelection } from './features/onboarding/coach-selection/CoachSelection'
import { NotesPage } from './features/notes/NotesPage'
import { PeerAssessmentPage } from './features/peer-assessment/PeerAssessmentPage'
import { Settings } from './features/settings/Settings'
import HupoHighlightsEdit from './features/hupohighlights/HupoHighlightsEdit'
import HupoHighlightsEditSuccess from './features/hupohighlights/HupoHighlightsEditSuccess'
import HupoHighlightsVerify from './features/hupohighlights/HupoHighlightsVerify'
import AssessmentIntro from './features/assessment/AssessmentIntro'
import AssessmentRouter from './features/assessment/AssessmentRouter'
import { NotFound } from './not-found'
import { ErrorPage } from './error-page'

const GenerateError = () => {
  throw new Error('Random error occurred')
  return <></>
}

export const router = createBrowserRouter([
  {
    element: <Outlet />,
    errorElement: <ErrorPage />,
    children: [
      //#region Onboarding
      {
        path: '/onboarding',
        element: <OnboardingLayout />,
        children: [
          {
            index: true,
            element: <WhatWeDo />,
          },
          {
            path: 'getting-to-know',
            element: <GoogleConnect />,
          },
          {
            path: 'connect-calendar',
            element: <CalendarConnect />,
          },
          {
            path: 'coach-selection',
            element: <CoachSelection />,
          },
        ],
      },
      //#endregion
      //#region Login
      {
        path: '/login',
        element: <LoginPage />,
      },
      //#endregion
      //#region Assessment
      // {
      //   path: '/assessment',
      //   element: <AssessmentRedirect />,
      //   children: [
      //     {
      //       path: ':friendlyId',
      //       element: <AssessmentItem />,
      //     },
      //   ],
      // },
      {
        path: '/assessment',
        element: <AssessmentIntro type={'assessment'} />,
        children: [
          {
            path: ':index',
            element: <AssessmentRouter type={'assessment'} />,
          },
          {
            path: ':userId/:language/:authorEmail/:index',
            element: <AssessmentRouter type={'assessment'} />,
          },
        ],
      },
      //#endregion
      //#region Inapp Meeting
      {
        path: '/meeting/:sessionId/:participantId',
        // access participantId and sessionId from params
        element: <InAppMeeting />,
      },
      //#endregion
      //#region Peer Assessment
      {
        path: '/peer-assessment',
        element: <PeerAssessmentPage />,
      },
      {
        path: '/peer-assessment/:author/:user/:question',
        element: <PeerAssessmentPage />,
      },
      {
        path: '/peer-assessment/:author/:user/',
        element: <PeerAssessmentPage />,
      },
      {
        path: '/peer-assessment/:author/',
        element: <PeerAssessmentPage />,
      },
      {
        path: '/peer-assessment/:author/u/q/leadership-values',
        element: <PeerAssessmentPage />,
      },
      //#endregion
      //#region Session summary
      {
        path: '/coach/:id/session-summary',
        children: [
          {
            path: 'verify/:sessionId',
            element: <HupoHighlightsVerify />,
          },
          {
            path: 'edit/:sessionId',
            element: <HupoHighlightsEdit />,
          },
          {
            path: 'edit-success',
            element: <HupoHighlightsEditSuccess />,
          },
        ],
      },
      //#endregion
      //#region Dashboard
      {
        path: '/',
        element: <MainLayout />,
        children: [
          {
            index: true,
            element: <Dashboard />,
          },
          {
            path: 'group-members',
            element: <GroupMembersPage />,
          },
          {
            path: 'scheduled-sessions',
            element: <ScheduledSessions />,
          },
          {
            path: 'progress',
            element: <ProgressPage />,
          },
          {
            path: 'team-chat',
            element: <ChatPage />,
            children: [
              {
                path: ':id',
                element: <ChatPage />,
              },
            ],
          },
          {
            path: 'group-chat',
            element: <ChatPage />,
            children: [
              {
                path: ':id',
                element: <ChatPage />,
              },
            ],
          },
          {
            path: 'resources',
            element: <ResourcesLibrary />,
          },
          {
            path: 'settings/:tabId',
            element: <Settings />,
          },
          {
            path: 'notes',
            element: <NotesPage />,
          },
        ],
      },
      //#endregion

      {
        path: '/random-error-occurrs-when-you-visit-this-path',
        element: <GenerateError />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
])
