import { Cancel, CheckCircle } from '@mui/icons-material'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import { useSendNudgeMutation } from '../../app/store/services/manager.service'
import { useGetTeamAssessmentStatusQuery } from '../../app/store/services/assessment.service'
import { DashboardCardTitle } from '../../lib/shared/dashboard-card/dashboard-card.style'
import { GroupMemberAssessmentStatus } from '../../lib/widgets/group-members/GroupMembers'
import { TeamMembersTable } from './team-progress-status.style'

interface TeamProgressResultsProps {
  onViewResults: (member: GroupMemberAssessmentStatus) => void
}

export function TeamProgressResults({
  onViewResults,
}: TeamProgressResultsProps) {
  const { t } = useTranslation()
  const nonRegisteredStyle = {
    color: '#44444444',
    paddingTop: 0,
    marginTop: 0,
  }
  const { data: teamMembersAssessmentStatus } =
    useGetTeamAssessmentStatusQuery()

  const [sendNudge] = useSendNudgeMutation()

  const sendNudgeToTeamMember = async (member: GroupMemberAssessmentStatus) => {
    try {
      await sendNudge({ email: member.email }).unwrap()
      toast.success(t('nudgeSent').replace('{{name}}', member.name))
    } catch (error) {
      console.log(error)
      toast.error(t('nudgeFailed'))
    }
  }

  return (
    <>
      <DashboardCardTitle style={{ marginBottom: 16 }}>
        {t('teamDiagnosticsStatus')}
      </DashboardCardTitle>
      <TeamMembersTable>
        <thead>
          <tr>
            <th>#</th>
            <th>{t('name')}</th>
            <th>{t('group_name')}</th>
            <th>{t('status')}</th>
          </tr>
        </thead>
        <tbody>
          {teamMembersAssessmentStatus?.map((member, index) => (
            <tr key={`member_${index}`}>
              <td>{index + 1}</td>
              <td>
                <div
                  style={{
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 1,
                  }}
                >
                  <span style={{ paddingBottom: 0, marginBottom: 0 }}>
                    {member.name}
                  </span>
                  <span style={nonRegisteredStyle}>{member.title}</span>
                </div>
              </td>
              <td>{member.group}</td>
              <td style={member.completed ? {} : nonRegisteredStyle}>
                <span
                  className={`calendarStatus ${
                    member.completed ? 'connected' : 'notConnected'
                  }`}
                >
                  <div style={{ paddingRight: 10 }}>
                    {member.completed ? <CheckCircle /> : <Cancel />}
                    {t(member.completed ? 'completed' : 'notStarted')}
                  </div>
                  {member.completed ? (
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => onViewResults(member)}
                    >
                      {t('view_results')}
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => sendNudgeToTeamMember(member)}
                    >
                      {t('nudge')}
                    </Button>
                  )}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </TeamMembersTable>
    </>
  )
}
